import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 901 1048">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  >


<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M471.000000,1049.000000 
	C314.000000,1049.000000 157.500000,1049.000000 1.000000,1049.000000 
	C1.000000,699.666687 1.000000,350.333344 1.000000,1.000000 
	C301.333344,1.000000 601.666687,1.000000 902.000000,1.000000 
	C902.000000,350.333344 902.000000,699.666687 902.000000,1049.000000 
	C758.500000,1049.000000 615.000000,1049.000000 471.000000,1049.000000 
M576.695312,605.523071 
	C576.454895,605.228088 576.166321,605.169800 575.374451,605.156921 
	C575.374451,605.156921 575.046814,604.787537 574.942810,604.180176 
	C574.258545,603.884338 573.574219,603.588440 572.325073,603.052185 
	C571.834106,602.688354 571.343140,602.324524 570.795410,601.314148 
	C570.083008,601.081177 569.370544,600.848206 568.025757,600.411499 
	C567.659851,600.167480 567.293945,599.923523 566.860901,599.094910 
	C566.196838,598.856873 565.532837,598.618896 564.318359,598.175720 
	C564.167236,598.051208 564.016113,597.926697 563.829956,597.171265 
	C562.906006,596.825745 561.982117,596.480286 560.472351,595.925781 
	C560.298523,595.812805 560.124695,595.699768 559.882446,595.002380 
	C559.210938,594.745972 558.539368,594.489563 557.352844,594.115540 
	C557.208557,594.014648 557.064270,593.913696 556.833496,593.167725 
	C549.413879,589.651367 541.994202,586.134949 533.985657,582.475952 
	C533.630127,582.287476 533.274536,582.099060 532.665649,581.302307 
	C531.467468,580.915100 530.269226,580.527893 528.470825,579.975464 
	C528.285706,579.881714 528.100525,579.787964 527.804565,579.070740 
	C527.063721,578.882812 526.322937,578.694824 524.985046,578.400513 
	C524.622009,578.227173 524.258972,578.053894 523.642517,577.263367 
	C519.253479,575.332764 515.015503,572.802063 510.430969,571.648376 
	C506.248901,570.595947 505.872772,568.408325 505.878113,564.873901 
	C505.951691,516.224854 505.691833,467.573975 506.055206,418.927582 
	C506.156525,405.362946 507.899689,391.799805 509.195892,378.265320 
	C509.415527,375.971893 511.192535,373.827637 512.328064,371.642029 
	C512.328064,371.642029 512.375244,371.709412 512.453430,372.277008 
	C512.956116,372.437134 513.458801,372.597290 514.568726,373.087250 
	C550.851013,394.457062 565.811279,427.703674 566.990601,468.243408 
	C592.525208,468.243408 617.451233,468.243408 642.542908,468.243408 
	C642.683777,466.965363 642.905579,465.997284 642.879700,465.035889 
	C642.258301,441.939209 637.317261,419.879395 627.183716,399.028076 
	C616.738770,377.535889 602.539307,358.896423 584.742615,342.020630 
	C584.440613,338.423004 584.036072,334.830322 583.861694,331.226532 
	C583.627075,326.377991 581.111145,323.513184 576.342163,323.168304 
	C574.363159,323.025146 572.210144,322.721558 570.398926,323.305573 
	C564.840210,325.097809 559.984070,323.555695 554.772583,320.452637 
	C554.188110,320.252350 553.603577,320.052063 552.614380,319.273132 
	C532.485474,308.852081 511.202423,301.923889 488.867126,298.153595 
	C461.398926,293.516907 433.830627,293.404663 406.206146,296.359650 
	C364.475098,300.823608 326.492493,314.457947 294.540680,342.536285 
	C271.534363,362.753601 255.497284,387.268860 248.614578,417.443329 
	C243.985748,437.736633 243.884399,458.098907 247.454559,478.473511 
	C251.414413,501.072174 260.819489,521.143250 276.084259,538.461426 
	C287.103180,550.962585 300.193573,560.860962 314.203827,570.505005 
	C314.548035,570.614136 314.892242,570.723206 315.604797,570.971924 
	C315.604797,570.971924 315.877106,571.256531 315.989746,571.931458 
	C317.765289,572.881531 319.540802,573.831604 321.662292,574.887329 
	C321.662292,574.887329 321.919464,575.141663 322.024872,575.837830 
	C323.473083,576.449524 324.921265,577.061218 326.720123,577.769592 
	C326.720123,577.769592 326.983093,578.020935 327.083862,578.728638 
	C327.879242,579.008972 328.674591,579.289246 329.992981,579.670410 
	C330.296295,579.848816 330.599609,580.027222 331.023193,580.889832 
	C332.454559,581.508179 333.885895,582.126587 335.682007,582.828491 
	C335.682007,582.828491 335.965912,583.072205 336.180725,583.600403 
	C336.533508,583.675476 336.886261,583.750549 337.617645,583.898743 
	C337.617645,583.898743 337.937469,584.114197 338.058899,584.813232 
	C339.103668,585.161804 340.148468,585.510437 341.588379,585.932800 
	C341.588379,585.932800 341.914978,586.167175 342.061249,586.860657 
	C343.104034,587.187744 344.146851,587.514893 345.593872,587.911011 
	C345.593872,587.911011 345.927307,588.149719 346.045349,588.842590 
	C347.762421,589.509583 349.479492,590.176575 351.602448,590.937134 
	C351.602448,590.937134 351.930084,591.194336 352.136383,591.700562 
	C352.478394,591.761353 352.820404,591.822205 353.576721,591.952820 
	C353.576721,591.952820 353.946960,592.151489 354.213806,592.652893 
	C354.568756,592.686523 354.923706,592.720154 355.829498,592.862366 
	C356.161194,593.031799 356.492889,593.201233 357.040405,594.026672 
	C358.502502,594.555054 359.964630,595.083435 361.959534,595.682129 
	C362.283142,595.830261 362.606781,595.978333 363.080475,596.832275 
	C364.150848,597.143127 365.221191,597.453979 366.657745,597.799683 
	C366.657745,597.799683 366.967224,597.998474 367.264099,598.641479 
	C369.994263,599.626282 372.724426,600.611084 375.982910,601.637146 
	C376.303223,601.777710 376.623535,601.918335 377.234619,602.704407 
	C380.221680,603.737854 383.208740,604.771240 386.785828,605.865723 
	C387.143341,606.029541 387.500885,606.193420 388.038086,607.028320 
	C392.818451,608.560486 397.598785,610.092651 402.925110,611.667542 
	C403.264099,611.798950 403.603088,611.930359 404.272583,612.709900 
	C405.357880,612.960083 406.443176,613.210266 407.974060,613.464355 
	C408.278381,613.496399 408.550934,613.606873 409.253632,614.162231 
	C409.449799,614.149353 409.645935,614.136536 409.903412,614.060913 
	C409.903412,614.060913 409.929047,614.144836 410.251984,614.776733 
	C414.479889,616.142944 418.707825,617.509216 422.938477,619.828308 
	C422.931793,681.601624 422.925110,743.374939 422.218719,805.248352 
	C421.515991,807.296997 420.534088,809.297119 420.168365,811.404236 
	C419.394745,815.861633 419.717438,820.968689 412.216278,820.729858 
	C411.491394,820.584167 410.766510,820.438538 409.728271,819.973328 
	C409.420471,819.834961 409.139069,819.876343 408.121735,820.075623 
	C407.121796,819.903137 406.121857,819.730652 404.917816,818.932373 
	C402.942291,818.635376 400.966797,818.338379 398.355774,818.014099 
	C397.942871,817.921082 397.529968,817.828064 396.880524,817.051208 
	C392.597473,816.022339 388.314392,814.993530 384.005463,814.001648 
	C384.005463,814.001648 384.021973,813.962708 383.667786,813.310669 
	C382.292328,813.020020 380.916870,812.729370 378.802063,812.281189 
	C375.568542,811.174011 372.335022,810.066772 368.903625,808.299011 
	C367.314636,807.497070 365.725616,806.695190 364.038391,805.171509 
	C360.009369,799.082336 355.980377,792.993225 351.947662,786.196472 
	C350.335876,784.827209 348.724091,783.457947 346.610779,781.666992 
	C346.275970,781.095825 345.941162,780.524658 345.669586,779.269836 
	C342.315948,777.233093 339.188141,774.268433 335.554047,773.384033 
	C330.506622,772.155579 325.079407,772.487793 319.971527,772.037659 
	C319.971527,772.037659 319.819214,772.145752 319.729370,771.644470 
	C319.489349,771.405884 319.249359,771.167297 318.714081,770.305542 
	C306.709595,752.421143 303.067352,732.196960 302.613434,710.941650 
	C277.185242,710.941650 252.195175,710.941650 226.910080,710.941650 
	C226.977814,742.567017 233.785431,771.883484 252.211472,797.439209 
	C259.787903,807.947266 269.427948,816.967407 278.181793,827.413269 
	C280.090607,832.084839 281.506012,837.038940 283.992340,841.379761 
	C295.740662,861.891052 312.981628,875.223022 336.340027,880.099548 
	C338.461823,880.542480 340.760651,880.137268 343.624847,880.128296 
	C344.054413,880.163818 344.483948,880.199402 345.164795,880.925781 
	C355.425629,881.407593 365.683319,882.039429 375.949738,882.257141 
	C378.281555,882.306580 380.643799,880.920593 383.678650,880.116455 
	C384.139954,880.095886 384.601288,880.075317 385.645294,880.592468 
	C389.221741,881.063110 392.792297,881.585449 396.375977,881.992004 
	C401.375122,882.559265 406.384125,883.039917 412.033478,883.523804 
	C412.669342,883.624939 413.305206,883.726135 414.509033,884.401184 
	C421.016357,884.687134 427.523712,884.973145 434.951691,885.137329 
	C439.638947,885.159058 444.326172,885.180725 449.641998,885.737122 
	C455.230011,885.770020 460.819427,885.873291 466.404694,885.765137 
	C467.316254,885.747498 468.209717,884.796509 469.726959,884.110352 
	C470.150574,884.111267 470.574219,884.112122 471.487396,884.620605 
	C473.005737,884.359253 474.524048,884.097961 476.693909,883.686279 
	C477.360260,883.673523 478.026642,883.660767 479.132019,883.943542 
	C479.435822,883.811646 479.739594,883.679749 480.675812,883.298096 
	C481.123230,883.255859 481.570618,883.213562 482.537231,883.695801 
	C485.372620,883.099121 488.207977,882.502380 491.742065,881.714844 
	C493.171692,881.520081 494.601318,881.325317 496.493958,881.593079 
	C498.675842,881.027405 500.857758,880.461670 503.691071,879.720520 
	C504.795044,879.541199 505.898987,879.361877 507.510712,879.671631 
	C510.737030,879.081848 514.057312,878.796753 517.169739,877.837524 
	C523.240662,875.966492 529.195312,873.718201 535.801453,871.262146 
	C536.702759,871.005615 537.604004,870.749146 539.230713,870.749817 
	C541.172607,869.870789 543.114502,868.991699 545.493958,867.806335 
	C545.840698,867.734009 546.187500,867.661682 547.189148,867.838684 
	C547.829651,867.313477 548.470093,866.788269 549.607727,865.999268 
	C549.971313,865.910889 550.334961,865.822510 551.256348,865.862061 
	C551.517578,865.611084 551.778809,865.360168 552.398071,864.908264 
	C552.398071,864.908264 552.803955,864.846008 553.346497,864.943909 
	C553.586853,864.693665 553.827148,864.443359 554.538574,863.915039 
	C554.889343,863.820496 555.240051,863.725891 556.294067,863.872009 
	C557.868713,862.925293 559.443359,861.978577 561.252686,860.756592 
	C561.252686,860.756592 561.595825,860.642029 562.322754,860.855774 
	C564.242126,859.610168 566.161438,858.364563 568.358215,856.895508 
	C568.358215,856.895508 568.705383,856.815918 569.439087,856.898010 
	C571.329102,855.671570 573.219116,854.445129 575.398438,852.946533 
	C575.398438,852.946533 575.781128,852.839722 576.363770,852.885498 
	C576.575317,852.582703 576.786865,852.279846 577.460754,851.480957 
	C587.979187,842.851624 599.710815,835.332886 608.804321,825.399109 
	C646.515198,784.203857 651.300842,735.572083 637.471558,683.904114 
	C630.300598,657.112244 614.175171,635.606140 592.865662,617.325256 
	C591.671265,616.555298 590.476807,615.785339 588.756226,614.728394 
	C588.483765,614.438477 588.211304,614.148560 587.813416,613.419617 
	C587.600830,613.146179 587.327271,613.054382 586.456726,612.842102 
	C586.324219,612.685608 586.191772,612.529114 585.976196,612.056702 
	C585.810242,611.861816 585.611450,611.818726 584.804932,611.663696 
	C584.492554,611.382385 584.180237,611.101135 583.795532,610.408142 
	C583.592957,610.149109 583.341919,610.087891 582.397400,609.941467 
	C582.224609,609.782471 582.051758,609.623535 581.876953,609.162292 
	C581.733215,608.965881 581.552551,608.922546 580.683289,608.711426 
	C580.505432,608.547180 580.327515,608.382874 579.990479,607.753357 
	C579.562561,607.604248 579.134705,607.455078 578.266663,607.118713 
	C578.266663,607.118713 577.930969,606.777954 577.823120,606.366821 
	C577.612000,606.121216 577.353699,606.052551 576.881226,606.140991 
	C576.881226,606.140991 576.820190,605.984192 576.695312,605.523071 
M616.670105,187.499451 
	C616.670105,208.940063 616.670105,230.380692 616.670105,252.028961 
	C623.867432,252.028961 630.635559,252.028961 637.323975,252.028961 
	C638.024719,250.407928 638.631531,249.004150 639.346741,247.349609 
	C640.349121,248.175522 640.993835,248.663589 641.590149,249.204956 
	C646.089478,253.289368 651.241882,254.311218 656.863342,252.128204 
	C662.430542,249.966248 665.003418,245.572144 665.327454,239.733551 
	C665.474854,237.077606 665.753052,234.422043 665.755188,231.766113 
	C665.765869,218.786102 665.985535,205.797241 665.580383,192.828705 
	C665.237915,181.867966 655.530457,176.066010 645.504333,180.457947 
	C643.310730,181.418884 641.389160,183.000885 638.765259,184.662262 
	C638.765259,178.025452 638.765259,171.808304 638.765259,165.348846 
	C631.186462,165.348846 624.139038,165.348846 616.671143,165.348846 
	C616.671143,172.569138 616.671143,179.534286 616.670105,187.499451 
M560.667358,223.487000 
	C560.751221,228.982544 560.595642,234.494492 560.971252,239.970032 
	C561.649475,249.858261 569.217041,255.333389 578.697449,252.702652 
	C581.586060,251.901062 584.155151,249.947830 586.440308,248.747955 
	C587.487976,250.010284 588.378052,252.059021 589.353027,252.100250 
	C596.074585,252.384567 602.813843,252.249847 609.460571,252.249847 
	C609.460571,228.145126 609.460571,204.882660 609.460571,181.384949 
	C602.131226,181.384949 595.095642,181.384949 587.640381,181.384949 
	C587.640381,183.657257 587.640625,185.636536 587.640320,187.615799 
	C587.638306,202.607880 587.647522,217.599976 587.619507,232.592010 
	C587.616089,234.417755 587.648010,236.303909 587.198608,238.041885 
	C586.988159,238.855972 585.738098,239.720337 584.826294,239.904694 
	C584.341614,240.002686 583.234619,238.793564 583.010620,238.003723 
	C582.657166,236.757217 582.759033,235.367035 582.757141,234.037537 
	C582.734253,218.212662 582.727661,202.387787 582.717224,186.562897 
	C582.716064,184.820786 582.716980,183.078674 582.716980,181.334946 
	C574.952454,181.334946 568.020874,181.334946 560.673401,181.334946 
	C560.673401,195.222702 560.673401,208.857010 560.667358,223.487000 
M285.591156,245.870132 
	C293.889435,253.741348 309.314148,255.743408 319.101440,250.219650 
	C327.447937,245.509033 331.302094,234.518829 328.788391,222.322540 
	C322.552521,222.322540 316.281464,222.322540 309.878632,222.322540 
	C309.700867,223.117004 309.459015,223.742676 309.435608,224.376450 
	C309.281952,228.532639 309.447144,232.727539 308.889069,236.825500 
	C308.720459,238.063782 306.589569,239.034836 305.357513,240.128296 
	C304.434509,238.938385 302.766602,237.780594 302.713654,236.553162 
	C302.442841,230.273270 302.588440,223.975449 302.588440,217.178879 
	C307.236816,217.178879 311.531708,217.178879 315.826599,217.178879 
	C320.250824,217.178879 324.675049,217.178879 329.205750,217.178879 
	C329.205750,211.716644 329.339172,206.902817 329.150604,202.101624 
	C329.053436,199.628174 328.575226,197.126358 327.938721,194.724945 
	C325.209961,184.429169 315.615448,178.482498 303.023193,179.149292 
	C290.312836,179.822311 282.067169,185.740204 281.284668,196.898682 
	C280.368225,209.967072 280.871216,223.160355 281.320251,236.280243 
	C281.424744,239.333054 283.799530,242.308182 285.591156,245.870132 
M480.829987,217.625015 
	C482.299225,205.623215 483.768463,193.621414 485.274475,181.319443 
	C478.557831,181.319443 472.457703,181.319443 465.885773,181.319443 
	C464.751312,195.564972 463.622742,209.736145 462.494202,223.907303 
	C462.025726,223.884262 461.557251,223.861221 461.088806,223.838181 
	C458.844116,209.751770 456.599457,195.665359 454.337677,181.471619 
	C447.998322,181.471619 441.993347,181.471619 435.738922,181.471619 
	C436.141998,183.687607 436.442200,185.480820 436.796356,187.263321 
	C440.623657,206.527786 444.394989,225.803787 448.342529,245.043640 
	C449.224915,249.344315 448.165894,251.494080 443.520355,251.193634 
	C442.728851,251.142441 441.921112,251.342758 440.843536,251.456039 
	C440.843536,255.024185 440.843536,258.426697 440.843536,261.934662 
	C447.900177,261.934662 454.713196,262.340729 461.458191,261.836761 
	C470.895447,261.131592 475.223907,257.045258 476.762024,247.690521 
	C478.353943,238.008362 479.467712,228.247574 480.829987,217.625015 
M404.019348,227.191940 
	C398.598969,227.191940 393.178589,227.191940 387.856445,227.191940 
	C386.088470,244.367828 392.545288,252.105179 408.897888,253.114441 
	C411.544189,253.277756 414.242706,253.245804 416.871429,252.927414 
	C426.394745,251.773895 432.049286,247.304703 433.745819,239.831131 
	C436.550751,227.474731 433.983551,219.333405 425.269165,213.985199 
	C420.739594,211.205338 415.977692,208.798843 411.281921,206.296555 
	C407.111053,204.073944 405.930511,200.376968 406.799408,196.174057 
	C407.104980,194.695892 409.126556,193.572495 410.364624,192.287109 
	C411.368286,193.568985 412.917480,194.725525 413.247559,196.162170 
	C413.740723,198.308716 413.375641,200.652451 413.375641,202.997437 
	C420.419769,202.997437 426.715424,202.997437 432.971130,202.997437 
	C435.517426,191.052536 430.675049,182.499176 419.482452,180.324738 
	C414.364685,179.330490 408.887268,179.376068 403.669373,179.903564 
	C396.326111,180.645905 389.884888,183.617249 388.361359,191.837936 
	C386.645142,201.098282 386.157410,210.348633 395.351074,216.561111 
	C399.895172,219.631714 404.601105,222.499161 409.420502,225.114624 
	C413.316742,227.229126 414.804260,230.214478 414.444061,234.500687 
	C414.211853,237.263855 414.284332,240.115143 410.447845,240.129135 
	C406.823212,240.142349 407.034821,237.324738 406.643921,234.833176 
	C406.242126,232.272034 405.503418,229.763733 404.019348,227.191940 
M370.751587,211.840942 
	C366.947449,209.784134 363.071747,207.847595 359.358276,205.638382 
	C355.233276,203.184326 354.990387,199.120819 355.980408,195.080307 
	C356.260590,193.936920 358.209503,192.685913 359.530518,192.508728 
	C360.316589,192.403275 361.940704,194.171860 362.185272,195.312866 
	C362.695190,197.691925 362.539459,200.213669 362.670044,202.944122 
	C369.324432,202.944122 375.718506,202.944122 382.080444,202.944122 
	C383.831451,189.922470 379.831696,182.699738 368.783020,180.432770 
	C363.518097,179.352509 357.850311,179.320999 352.480194,179.894104 
	C345.382690,180.651505 339.000305,183.552109 337.454590,191.543350 
	C335.670624,200.766266 334.999207,210.041565 344.081848,216.380569 
	C348.440948,219.422913 352.961670,222.342438 357.727753,224.663391 
	C362.871338,227.168152 364.240082,231.161392 363.189453,236.119278 
	C362.862183,237.663620 360.660034,238.810654 359.316162,240.139587 
	C358.125519,238.823151 356.263031,237.671951 355.889832,236.154480 
	C355.200409,233.350983 355.383911,230.332825 355.209473,227.496597 
	C348.783051,227.496597 342.862762,227.496597 336.996857,227.496597 
	C334.721771,241.771133 339.895599,250.344330 352.758820,252.369324 
	C358.381927,253.254532 364.386597,252.848709 370.057281,251.979996 
	C375.719788,251.112518 380.538818,247.771484 382.008545,241.873550 
	C385.433899,228.128281 384.110626,218.140366 370.751587,211.840942 
M526.652954,232.541626 
	C526.568176,230.921829 526.483459,229.302017 526.373596,227.202042 
	C523.080017,227.202042 520.123840,227.202057 517.167664,227.202057 
	C514.077332,227.202057 510.987061,227.202042 508.003510,227.202042 
	C506.180389,244.553299 512.788330,252.333817 529.446960,253.131363 
	C531.933533,253.250397 534.458252,253.226410 536.927368,252.940384 
	C546.467834,251.835129 552.097351,247.415421 553.817383,239.878998 
	C556.641052,227.506851 554.074219,219.313690 545.400208,214.000031 
	C540.721802,211.134048 535.836365,208.599854 530.995178,206.007721 
	C526.707031,203.711700 526.064331,199.850296 526.897766,195.739136 
	C527.167175,194.409943 529.158875,193.429901 530.362732,192.290115 
	C531.424194,193.534531 533.023315,194.629318 533.421082,196.058456 
	C534.017090,198.199860 533.769531,200.576080 533.886292,202.965134 
	C540.538208,202.965134 546.818848,202.965134 554.670532,202.965134 
	C553.428711,197.450943 553.483643,191.704391 550.936218,187.555771 
	C544.429382,176.958817 519.490723,176.132431 511.312683,185.614380 
	C510.771515,186.241852 510.275452,186.927139 509.866638,187.646957 
	C504.496643,197.102249 507.663849,211.380005 516.695374,217.473328 
	C521.103760,220.447525 525.865173,222.893173 530.385010,225.709183 
	C534.336304,228.170944 536.126160,233.891312 534.049438,237.883163 
	C533.494080,238.950806 531.808777,240.024399 530.648804,240.017288 
	C529.481750,240.010132 527.884888,238.907974 527.277466,237.832993 
	C526.600098,236.634125 526.825378,234.925262 526.652954,232.541626 
M250.341858,174.562866 
	C250.340637,192.211929 250.352707,209.861008 250.332443,227.510056 
	C250.322784,235.922089 250.294937,235.922058 242.549896,237.983841 
	C242.549896,242.538239 242.549896,247.134079 242.549896,252.204575 
	C247.069672,252.204575 251.361481,252.299713 255.647827,252.186951 
	C269.105682,251.832901 273.251007,247.738342 273.325714,234.363876 
	C273.446594,212.719482 273.407684,191.074203 273.416443,169.429291 
	C273.416962,168.123459 273.243652,166.817566 273.142944,165.394562 
	C265.541504,165.394562 258.158844,165.394562 250.341919,165.394562 
	C250.341919,168.326324 250.341919,170.956207 250.341858,174.562866 
z"/>
<path fill="#FA0404" opacity="1.000000" stroke="none" 
	d="
M356.824585,593.370605 
	C356.492889,593.201233 356.161194,593.031799 355.428314,592.549194 
	C354.667084,592.207825 354.307007,592.179626 353.946960,592.151489 
	C353.946960,592.151489 353.576721,591.952820 353.260681,591.659912 
	C352.606445,591.309509 352.268280,591.251892 351.930084,591.194336 
	C351.930084,591.194336 351.602448,590.937134 351.250122,590.581787 
	C349.240967,589.534241 347.584137,588.841980 345.927307,588.149780 
	C345.927307,588.149719 345.593872,587.911011 345.242920,587.581787 
	C343.899628,586.890747 342.907288,586.528931 341.914978,586.167175 
	C341.914978,586.167175 341.588379,585.932800 341.243439,585.597290 
	C339.911469,584.879333 338.924469,584.496765 337.937469,584.114197 
	C337.937469,584.114197 337.617645,583.898743 337.321991,583.592407 
	C336.672852,583.214783 336.319366,583.143494 335.965912,583.072205 
	C335.965912,583.072205 335.682007,582.828491 335.385529,582.471558 
	C333.693665,581.478271 332.298279,580.841919 330.902924,580.205566 
	C330.599609,580.027222 330.296295,579.848816 329.627960,579.307922 
	C328.502991,578.637268 327.743042,578.329102 326.983093,578.020935 
	C326.983093,578.020935 326.720123,577.769592 326.408112,577.415283 
	C324.703888,576.421204 323.311676,575.781433 321.919464,575.141663 
	C321.919464,575.141663 321.662292,574.887329 321.390747,574.509888 
	C319.371857,573.173828 317.624481,572.215149 315.877106,571.256531 
	C315.877106,571.256531 315.604797,570.971924 315.336182,570.616699 
	C314.705170,570.166260 314.342804,570.071045 313.980438,569.975891 
	C300.193573,560.860962 287.103180,550.962585 276.084259,538.461426 
	C260.819489,521.143250 251.414413,501.072174 247.454559,478.473511 
	C243.884399,458.098907 243.985748,437.736633 248.614578,417.443329 
	C255.497284,387.268860 271.534363,362.753601 294.540680,342.536285 
	C326.492493,314.457947 364.475098,300.823608 406.206146,296.359650 
	C433.830627,293.404663 461.398926,293.516907 488.867126,298.153595 
	C511.202423,301.923889 532.485474,308.852081 552.747681,319.888184 
	C552.820801,321.161316 552.760620,321.819427 552.254700,322.458069 
	C503.581665,322.414062 455.354431,322.348389 407.127258,322.385071 
	C387.679016,322.399841 368.231018,322.638672 348.453949,322.775391 
	C347.662537,323.426666 347.200073,324.078064 346.339600,324.798767 
	C345.246033,329.109222 344.070038,333.340454 343.981812,337.594238 
	C343.881409,342.435516 347.225464,345.251495 352.186768,345.418671 
	C358.787720,345.641083 365.391907,345.766205 372.257263,346.202637 
	C374.038422,346.594177 375.557037,346.716217 377.501282,346.938232 
	C388.823425,347.458374 398.068420,351.901337 407.235626,358.435425 
	C403.572174,359.552582 400.799561,360.398102 397.706940,361.251251 
	C396.985962,361.396515 396.584991,361.534180 395.829285,361.541046 
	C392.899200,362.196991 390.323883,362.983704 387.454102,363.796692 
	C386.795837,363.970856 386.432037,364.118744 385.734985,364.160004 
	C384.194611,364.643707 382.987518,365.234039 381.577881,365.830139 
	C381.375336,365.835938 381.021149,366.032837 380.664917,365.935791 
	C378.818634,366.525238 377.328583,367.211761 375.630188,367.921204 
	C375.421814,367.944061 375.086334,368.195496 374.780548,368.133087 
	C373.820557,368.538177 373.166351,369.005676 372.195740,369.552856 
	C370.931854,370.113953 369.984375,370.595276 368.756958,371.043152 
	C368.229950,371.265991 367.982849,371.522247 367.540833,371.818481 
	C367.345978,371.858490 367.044769,372.118469 366.766174,372.080414 
	C366.264191,372.317627 366.040833,372.592926 365.492432,372.978394 
	C350.960571,380.949829 339.843506,392.032898 332.198212,406.263153 
	C314.096100,439.956726 315.779724,483.682007 353.550446,510.780548 
	C372.377167,524.287720 393.240631,533.708618 415.109772,541.322632 
	C415.133667,541.529541 415.510590,541.700317 415.510590,541.700317 
	C415.510590,541.700317 415.872223,541.499146 416.218933,541.657471 
	C417.302490,542.015259 418.039398,542.214783 418.930847,542.741089 
	C420.106018,544.924805 421.995209,546.772644 422.014374,548.639709 
	C422.232086,569.860779 422.182281,591.084839 422.136475,612.307922 
	C422.132965,613.928711 421.616760,615.548401 420.952698,617.045776 
	C417.020691,615.996887 413.474884,615.070862 409.929047,614.144836 
	C409.929047,614.144836 409.903412,614.060913 409.863464,613.888794 
	C409.479614,613.743042 409.135651,613.769348 408.791718,613.795654 
	C408.550934,613.606873 408.278381,613.496399 407.583923,613.158203 
	C406.109894,612.588623 405.025970,612.325195 403.942078,612.061768 
	C403.603088,611.930359 403.264099,611.798950 402.432037,611.367188 
	C397.245453,609.497009 392.551910,607.927124 387.858398,606.357239 
	C387.500885,606.193420 387.143341,606.029541 386.292816,605.549805 
	C382.847809,604.175598 379.895813,603.117249 376.943848,602.058960 
	C376.623535,601.918335 376.303223,601.777710 375.553894,601.306519 
	C372.405640,599.983398 369.686432,598.990906 366.967224,597.998474 
	C366.967224,597.998474 366.657745,597.799683 366.318970,597.482544 
	C364.963623,596.819092 363.946991,596.472778 362.930389,596.126465 
	C362.606781,595.978333 362.283142,595.830261 361.564392,595.332397 
	C359.721008,594.445312 358.272797,593.907959 356.824585,593.370605 
z"/>
<path fill="#FB0303" opacity="1.000000" stroke="none" 
	d="
M592.870728,617.984314 
	C614.175171,635.606140 630.300598,657.112244 637.471558,683.904114 
	C651.300842,735.572083 646.515198,784.203857 608.804321,825.399109 
	C599.710815,835.332886 587.979187,842.851624 576.945740,851.734131 
	C576.214233,852.271484 575.997681,852.555603 575.781128,852.839722 
	C575.781128,852.839722 575.398438,852.946533 574.901489,853.044678 
	C572.504761,854.367126 570.605103,855.591553 568.705383,856.815918 
	C568.705383,856.815918 568.358215,856.895508 567.866516,856.958496 
	C565.448486,858.228394 563.522156,859.435181 561.595825,860.642029 
	C561.595825,860.642029 561.252686,860.756592 560.780762,860.871460 
	C558.736145,861.867920 557.163513,862.749634 555.590820,863.631348 
	C555.240051,863.725891 554.889343,863.820496 554.031128,863.992371 
	C553.283752,864.328491 553.043884,864.587280 552.803955,864.846008 
	C552.803955,864.846008 552.398071,864.908264 551.938232,864.936584 
	C551.218506,865.221313 550.958557,865.477722 550.698547,865.734131 
	C550.334961,865.822510 549.971313,865.910889 549.045410,866.056519 
	C547.833435,866.605652 547.183838,867.097473 546.534241,867.589355 
	C546.187500,867.661682 545.840698,867.734009 544.905884,867.915405 
	C542.380371,868.847229 540.442810,869.669983 538.505310,870.492676 
	C537.604004,870.749146 536.702759,871.005615 535.133057,871.342163 
	C525.310791,874.009033 516.156860,876.595764 507.002960,879.182495 
	C505.898987,879.361877 504.795044,879.541199 503.086731,879.649902 
	C500.331879,880.096313 498.181427,880.613403 496.030945,881.130615 
	C494.601318,881.325317 493.171692,881.520081 491.095245,881.652527 
	C487.638306,882.117249 484.828156,882.644287 482.018036,883.171326 
	C481.570618,883.213562 481.123230,883.255859 480.154114,883.226318 
	C479.319244,883.319031 479.006134,883.483521 478.692993,883.648071 
	C478.026642,883.660767 477.360260,883.673523 476.091003,883.577271 
	C473.991364,883.683105 472.494598,883.898071 470.997833,884.113037 
	C470.574219,884.112122 470.150574,884.111267 468.961121,884.054565 
	C461.801331,884.400024 455.407379,884.801208 449.013428,885.202393 
	C444.326172,885.180725 439.638947,885.159058 434.141113,884.968445 
	C426.867371,884.475464 420.404236,884.151367 413.941071,883.827271 
	C413.305206,883.726135 412.669342,883.624939 411.421661,883.292603 
	C407.156403,882.353943 403.502991,881.646423 399.849579,880.938904 
	C400.700470,879.193237 401.673126,878.455261 402.768219,878.040771 
	C407.249542,876.344727 411.880219,875.000244 416.251740,873.065063 
	C428.228210,867.763428 439.136353,860.820801 448.449493,851.145386 
	C448.882660,850.786377 449.069275,850.506470 449.606384,850.204102 
	C455.085907,845.078918 460.639648,840.328674 465.238831,834.786255 
	C471.303040,827.478577 475.930969,818.595642 487.505127,819.346619 
	C488.405457,819.405090 489.355347,818.699280 490.660889,818.349243 
	C492.654572,817.865234 494.269562,817.380676 495.907013,816.937012 
	C495.929443,816.977905 495.944916,816.885864 496.220215,816.978271 
	C496.809601,816.870239 497.123688,816.669861 497.693420,816.456299 
	C498.265289,816.320251 498.581421,816.197205 498.996094,816.217285 
	C499.330750,816.193481 499.566925,816.026489 499.843262,815.889526 
	C499.883423,815.919617 499.928284,815.829834 500.238098,815.914917 
	C501.060516,815.692200 501.573090,815.384460 502.466675,815.014954 
	C503.565979,814.684692 504.284302,814.416138 505.161743,814.263184 
	C505.642883,814.378296 505.821106,814.217896 506.137970,813.884399 
	C506.590820,813.790955 506.761292,813.710510 507.181824,813.709778 
	C507.644043,813.547119 507.856201,813.304810 508.309326,813.008789 
	C508.550293,812.955139 508.942108,812.654724 509.192688,812.741333 
	C509.693085,812.600647 509.942871,812.373291 510.558350,812.104431 
	C544.262329,798.264771 564.838196,774.895874 567.551392,737.648132 
	C570.146423,702.022522 552.473694,670.357666 521.176331,655.438049 
	C520.796204,655.124207 520.494080,655.049683 520.150513,654.902832 
	C520.109131,654.830444 519.943909,654.852844 519.900085,654.647766 
	C519.620972,654.187256 519.369934,654.170776 518.856323,654.191284 
	C518.406372,653.926208 518.203308,653.863159 517.949951,653.584106 
	C517.649353,653.104797 517.381531,653.085632 516.861572,653.133789 
	C516.438049,652.905518 516.249146,652.854004 516.005005,652.576599 
	C515.696594,652.073975 515.415100,652.041382 514.861450,652.074097 
	C514.421265,651.850037 514.224854,651.804749 513.969116,651.550903 
	C513.661072,651.111084 513.392944,651.085876 512.904480,651.055237 
	C512.522034,650.773804 512.340393,650.703979 512.061523,650.414062 
	C511.668427,649.969482 511.365570,649.959656 510.821167,650.018188 
	C510.405426,649.838928 510.224091,649.806091 509.977173,649.571838 
	C509.660675,649.132019 509.405060,649.127014 508.893372,649.158936 
	C508.442444,648.888733 508.242920,648.814880 507.936523,648.347473 
	C507.511658,646.364380 506.921997,644.775696 506.916718,643.185120 
	C506.844238,621.271667 506.841461,599.357910 506.863403,577.444275 
	C506.864960,575.895996 507.193573,574.347900 507.442810,572.162170 
	C513.390991,574.229492 518.643494,576.054993 523.895935,577.880554 
	C524.258972,578.053894 524.622009,578.227173 525.393677,578.732727 
	C526.506653,579.274719 527.211060,579.484497 527.915405,579.694275 
	C528.100525,579.787964 528.285706,579.881714 528.894165,580.352600 
	C530.518066,581.123352 531.718506,581.516968 532.919006,581.910583 
	C533.274536,582.099060 533.630127,582.287476 534.444824,582.829224 
	C542.242676,586.725891 549.581360,590.269348 556.919983,593.812805 
	C557.064270,593.913696 557.208557,594.014648 557.676758,594.457458 
	C558.650696,595.061829 559.300720,595.324280 559.950806,595.586792 
	C560.124695,595.699768 560.298523,595.812805 560.856812,596.311768 
	C562.115906,597.065857 562.990417,597.433960 563.864990,597.802124 
	C564.016113,597.926697 564.167236,598.051208 564.654663,598.547363 
	C565.636719,599.172485 566.282349,599.425964 566.928040,599.679504 
	C567.293945,599.923523 567.659851,600.167480 568.447693,600.773193 
	C569.530457,601.410156 570.191345,601.685425 570.852173,601.960693 
	C571.343140,602.324524 571.834106,602.688354 572.645508,603.467896 
	C573.659546,604.184937 574.353210,604.486267 575.046814,604.787537 
	C575.046814,604.787537 575.374451,605.156921 575.659424,605.480591 
	C575.944458,605.804260 576.351196,606.045532 576.351196,606.045532 
	C576.351196,606.045532 576.820190,605.984192 576.820190,605.984192 
	C576.820190,605.984192 576.881226,606.140991 576.996765,606.380371 
	C577.112305,606.619690 577.481750,606.904236 577.481750,606.904236 
	C577.481750,606.904236 577.930969,606.777954 577.930969,606.777954 
	C577.930969,606.777954 578.266663,607.118713 578.526001,607.474121 
	C579.240051,607.959167 579.694824,608.088928 580.149536,608.218628 
	C580.327515,608.382874 580.505432,608.547180 580.975464,609.049744 
	C581.267578,609.388062 581.549622,609.614563 581.549622,609.614563 
	C581.549622,609.614563 581.878967,609.464539 581.878967,609.464539 
	C582.051758,609.623535 582.224609,609.782471 582.740723,610.302246 
	C583.083984,610.662964 583.438110,610.929688 583.438110,610.929688 
	C583.438110,610.929688 583.867859,610.819824 583.867859,610.819824 
	C584.180237,611.101135 584.492554,611.382385 585.077881,612.003174 
	C585.350769,612.342590 585.695557,612.575195 585.695557,612.575195 
	C585.695557,612.575195 586.059265,612.372681 586.059265,612.372681 
	C586.191772,612.529114 586.324219,612.685608 586.755066,613.236694 
	C587.053406,613.631348 587.450256,613.923279 587.450256,613.923279 
	C587.450256,613.923279 587.938782,613.858704 587.938782,613.858704 
	C588.211304,614.148560 588.483765,614.438477 589.064697,615.172119 
	C590.539062,616.405334 591.704895,617.194824 592.870728,617.984314 
z"/>
<path fill="#F90405" opacity="1.000000" stroke="none" 
	d="
M278.129303,826.664368 
	C269.427948,816.967407 259.787903,807.947266 252.211472,797.439209 
	C233.785431,771.883484 226.977814,742.567017 226.910080,710.941650 
	C252.195175,710.941650 277.185242,710.941650 302.613434,710.941650 
	C303.067352,732.196960 306.709595,752.421143 318.784302,770.916748 
	C319.176117,771.733887 319.497681,771.939819 319.819214,772.145752 
	C319.819214,772.145752 319.971527,772.037659 319.688721,772.402100 
	C315.587982,773.533875 311.532227,773.719666 307.992645,775.167480 
	C291.345276,781.976807 279.882477,791.178284 278.791260,814.887085 
	C278.610474,818.814636 278.351929,822.738708 278.129303,826.664368 
z"/>
<path fill="#3A3A3A" opacity="1.000000" stroke="none" 
	d="
M616.670654,186.999435 
	C616.671143,179.534286 616.671143,172.569138 616.671143,165.348846 
	C624.139038,165.348846 631.186462,165.348846 638.765259,165.348846 
	C638.765259,171.808304 638.765259,178.025452 638.765259,184.662262 
	C641.389160,183.000885 643.310730,181.418884 645.504333,180.457947 
	C655.530457,176.066010 665.237915,181.867966 665.580383,192.828705 
	C665.985535,205.797241 665.765869,218.786102 665.755188,231.766113 
	C665.753052,234.422043 665.474854,237.077606 665.327454,239.733551 
	C665.003418,245.572144 662.430542,249.966248 656.863342,252.128204 
	C651.241882,254.311218 646.089478,253.289368 641.590149,249.204956 
	C640.993835,248.663589 640.349121,248.175522 639.346741,247.349609 
	C638.631531,249.004150 638.024719,250.407928 637.323975,252.028961 
	C630.635559,252.028961 623.867432,252.028961 616.670105,252.028961 
	C616.670105,230.380692 616.670105,208.940063 616.670654,186.999435 
M643.787415,208.509827 
	C643.737061,204.518112 643.911926,200.504028 643.519287,196.546280 
	C643.373535,195.077164 641.896973,193.740082 641.027344,192.342789 
	C640.289062,193.801025 638.939941,195.247208 638.910950,196.719391 
	C638.710938,206.864868 638.766541,217.015823 638.784607,227.164948 
	C638.790833,230.655960 638.797485,234.157471 639.090637,237.629974 
	C639.162354,238.479538 640.362488,239.233826 641.043884,240.031906 
	C641.829224,239.330475 643.012695,238.764755 643.308594,237.896484 
	C643.775635,236.526306 643.745789,234.953491 643.753418,233.465561 
	C643.794250,225.479324 643.781555,217.492828 643.787415,208.509827 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M560.670410,222.989166 
	C560.673401,208.857010 560.673401,195.222702 560.673401,181.334946 
	C568.020874,181.334946 574.952454,181.334946 582.716980,181.334946 
	C582.716980,183.078674 582.716064,184.820786 582.717224,186.562897 
	C582.727661,202.387787 582.734253,218.212662 582.757141,234.037537 
	C582.759033,235.367035 582.657166,236.757217 583.010620,238.003723 
	C583.234619,238.793564 584.341614,240.002686 584.826294,239.904694 
	C585.738098,239.720337 586.988159,238.855972 587.198608,238.041885 
	C587.648010,236.303909 587.616089,234.417755 587.619507,232.592010 
	C587.647522,217.599976 587.638306,202.607880 587.640320,187.615799 
	C587.640625,185.636536 587.640381,183.657257 587.640381,181.384949 
	C595.095642,181.384949 602.131226,181.384949 609.460571,181.384949 
	C609.460571,204.882660 609.460571,228.145126 609.460571,252.249847 
	C602.813843,252.249847 596.074585,252.384567 589.353027,252.100250 
	C588.378052,252.059021 587.487976,250.010284 586.440308,248.747955 
	C584.155151,249.947830 581.586060,251.901062 578.697449,252.702652 
	C569.217041,255.333389 561.649475,249.858261 560.971252,239.970032 
	C560.595642,234.494492 560.751221,228.982544 560.670410,222.989166 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M285.360413,245.594589 
	C283.799530,242.308182 281.424744,239.333054 281.320251,236.280243 
	C280.871216,223.160355 280.368225,209.967072 281.284668,196.898682 
	C282.067169,185.740204 290.312836,179.822311 303.023193,179.149292 
	C315.615448,178.482498 325.209961,184.429169 327.938721,194.724945 
	C328.575226,197.126358 329.053436,199.628174 329.150604,202.101624 
	C329.339172,206.902817 329.205750,211.716644 329.205750,217.178879 
	C324.675049,217.178879 320.250824,217.178879 315.826599,217.178879 
	C311.531708,217.178879 307.236816,217.178879 302.588440,217.178879 
	C302.588440,223.975449 302.442841,230.273270 302.713654,236.553162 
	C302.766602,237.780594 304.434509,238.938385 305.357513,240.128296 
	C306.589569,239.034836 308.720459,238.063782 308.889069,236.825500 
	C309.447144,232.727539 309.281952,228.532639 309.435608,224.376450 
	C309.459015,223.742676 309.700867,223.117004 309.878632,222.322540 
	C316.281464,222.322540 322.552521,222.322540 328.788391,222.322540 
	C331.302094,234.518829 327.447937,245.509033 319.101440,250.219650 
	C309.314148,255.743408 293.889435,253.741348 285.360413,245.594589 
M302.821075,193.697647 
	C302.821075,197.742615 302.821075,201.787567 302.821075,206.144913 
	C304.423523,206.043716 305.805817,205.956421 307.218384,205.867218 
	C307.218384,202.027878 307.387421,198.564529 307.160095,195.127426 
	C307.003479,192.759842 305.856232,191.270325 302.821075,193.697647 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M480.810303,218.073090 
	C479.467712,228.247574 478.353943,238.008362 476.762024,247.690521 
	C475.223907,257.045258 470.895447,261.131592 461.458191,261.836761 
	C454.713196,262.340729 447.900177,261.934662 440.843536,261.934662 
	C440.843536,258.426697 440.843536,255.024185 440.843536,251.456039 
	C441.921112,251.342758 442.728851,251.142441 443.520355,251.193634 
	C448.165894,251.494080 449.224915,249.344315 448.342529,245.043640 
	C444.394989,225.803787 440.623657,206.527786 436.796356,187.263321 
	C436.442200,185.480820 436.141998,183.687607 435.738922,181.471619 
	C441.993347,181.471619 447.998322,181.471619 454.337677,181.471619 
	C456.599457,195.665359 458.844116,209.751770 461.088806,223.838181 
	C461.557251,223.861221 462.025726,223.884262 462.494202,223.907303 
	C463.622742,209.736145 464.751312,195.564972 465.885773,181.319443 
	C472.457703,181.319443 478.557831,181.319443 485.274475,181.319443 
	C483.768463,193.621414 482.299225,205.623215 480.810303,218.073090 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M404.465759,227.212128 
	C405.503418,229.763733 406.242126,232.272034 406.643921,234.833176 
	C407.034821,237.324738 406.823212,240.142349 410.447845,240.129135 
	C414.284332,240.115143 414.211853,237.263855 414.444061,234.500687 
	C414.804260,230.214478 413.316742,227.229126 409.420502,225.114624 
	C404.601105,222.499161 399.895172,219.631714 395.351074,216.561111 
	C386.157410,210.348633 386.645142,201.098282 388.361359,191.837936 
	C389.884888,183.617249 396.326111,180.645905 403.669373,179.903564 
	C408.887268,179.376068 414.364685,179.330490 419.482452,180.324738 
	C430.675049,182.499176 435.517426,191.052536 432.971130,202.997437 
	C426.715424,202.997437 420.419769,202.997437 413.375641,202.997437 
	C413.375641,200.652451 413.740723,198.308716 413.247559,196.162170 
	C412.917480,194.725525 411.368286,193.568985 410.364624,192.287109 
	C409.126556,193.572495 407.104980,194.695892 406.799408,196.174057 
	C405.930511,200.376968 407.111053,204.073944 411.281921,206.296555 
	C415.977692,208.798843 420.739594,211.205338 425.269165,213.985199 
	C433.983551,219.333405 436.550751,227.474731 433.745819,239.831131 
	C432.049286,247.304703 426.394745,251.773895 416.871429,252.927414 
	C414.242706,253.245804 411.544189,253.277756 408.897888,253.114441 
	C392.545288,252.105179 386.088470,244.367828 387.856445,227.191940 
	C393.178589,227.191940 398.598969,227.191940 404.465759,227.212128 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M371.076233,212.008682 
	C384.110626,218.140366 385.433899,228.128281 382.008545,241.873550 
	C380.538818,247.771484 375.719788,251.112518 370.057281,251.979996 
	C364.386597,252.848709 358.381927,253.254532 352.758820,252.369324 
	C339.895599,250.344330 334.721771,241.771133 336.996857,227.496597 
	C342.862762,227.496597 348.783051,227.496597 355.209473,227.496597 
	C355.383911,230.332825 355.200409,233.350983 355.889832,236.154480 
	C356.263031,237.671951 358.125519,238.823151 359.316162,240.139587 
	C360.660034,238.810654 362.862183,237.663620 363.189453,236.119278 
	C364.240082,231.161392 362.871338,227.168152 357.727753,224.663391 
	C352.961670,222.342438 348.440948,219.422913 344.081848,216.380569 
	C334.999207,210.041565 335.670624,200.766266 337.454590,191.543350 
	C339.000305,183.552109 345.382690,180.651505 352.480194,179.894104 
	C357.850311,179.320999 363.518097,179.352509 368.783020,180.432770 
	C379.831696,182.699738 383.831451,189.922470 382.080444,202.944122 
	C375.718506,202.944122 369.324432,202.944122 362.670044,202.944122 
	C362.539459,200.213669 362.695190,197.691925 362.185272,195.312866 
	C361.940704,194.171860 360.316589,192.403275 359.530518,192.508728 
	C358.209503,192.685913 356.260590,193.936920 355.980408,195.080307 
	C354.990387,199.120819 355.233276,203.184326 359.358276,205.638382 
	C363.071747,207.847595 366.947449,209.784134 371.076233,212.008682 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M526.655396,232.990005 
	C526.825378,234.925262 526.600098,236.634125 527.277466,237.832993 
	C527.884888,238.907974 529.481750,240.010132 530.648804,240.017288 
	C531.808777,240.024399 533.494080,238.950806 534.049438,237.883163 
	C536.126160,233.891312 534.336304,228.170944 530.385010,225.709183 
	C525.865173,222.893173 521.103760,220.447525 516.695374,217.473328 
	C507.663849,211.380005 504.496643,197.102249 509.866638,187.646957 
	C510.275452,186.927139 510.771515,186.241852 511.312683,185.614380 
	C519.490723,176.132431 544.429382,176.958817 550.936218,187.555771 
	C553.483643,191.704391 553.428711,197.450943 554.670532,202.965134 
	C546.818848,202.965134 540.538208,202.965134 533.886292,202.965134 
	C533.769531,200.576080 534.017090,198.199860 533.421082,196.058456 
	C533.023315,194.629318 531.424194,193.534531 530.362732,192.290131 
	C529.158875,193.429901 527.167175,194.409943 526.897766,195.739136 
	C526.064331,199.850296 526.707031,203.711700 530.995178,206.007721 
	C535.836365,208.599854 540.721802,211.134048 545.400208,214.000031 
	C554.074219,219.313690 556.641052,227.506851 553.817383,239.878998 
	C552.097351,247.415421 546.467834,251.835129 536.927368,252.940384 
	C534.458252,253.226410 531.933533,253.250397 529.446960,253.131363 
	C512.788330,252.333817 506.180389,244.553299 508.003510,227.202042 
	C510.987061,227.202042 514.077332,227.202057 517.167664,227.202057 
	C520.123840,227.202057 523.080017,227.202042 526.373596,227.202042 
	C526.483459,229.302017 526.568176,230.921829 526.655396,232.990005 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M523.769226,577.571960 
	C518.643494,576.054993 513.390991,574.229492 507.442810,572.162170 
	C507.193573,574.347900 506.864960,575.895996 506.863403,577.444275 
	C506.841461,599.357910 506.844238,621.271667 506.916718,643.185120 
	C506.921997,644.775696 507.511658,646.364380 507.551331,648.491333 
	C506.867126,651.686279 506.461212,654.343933 506.007385,657.468201 
	C505.901703,675.593628 505.843964,693.252441 505.553101,711.216492 
	C505.265533,713.347229 505.210999,715.172607 505.096680,717.395386 
	C505.049042,718.862244 505.061218,719.931885 505.014099,721.372742 
	C504.968658,722.494385 504.982513,723.244751 504.952881,724.434326 
	C503.211029,736.888550 501.512634,748.903564 499.554504,761.161499 
	C498.875427,763.270020 498.456116,765.135620 497.983948,767.323608 
	C497.914917,768.080872 497.898743,768.515869 497.618530,769.215027 
	C497.206512,770.981812 497.058472,772.484436 496.861572,774.310059 
	C496.811493,775.285217 496.810303,775.937378 496.480469,776.733948 
	C495.451996,778.584595 494.752197,780.290833 493.983765,782.380249 
	C493.824005,783.796448 493.732880,784.829529 493.380066,786.060913 
	C491.749084,789.171692 490.379791,792.084106 488.913574,795.358154 
	C487.492401,798.792297 486.168121,801.864746 484.616638,805.116577 
	C484.201538,805.836548 484.013672,806.377075 483.586243,807.088745 
	C483.150513,807.804688 482.954346,808.349609 482.470581,809.068237 
	C480.024384,812.814209 477.865814,816.386475 475.386353,820.003235 
	C474.053192,820.391479 473.040894,820.735352 472.038208,820.718018 
	C486.591278,799.050720 494.320099,775.139343 498.728516,750.006592 
	C499.430267,746.005920 499.227325,741.846497 499.537537,737.368774 
	C499.660797,735.627380 499.689087,734.277771 500.075317,732.719116 
	C501.277771,724.112000 502.600067,715.728577 502.859589,707.312500 
	C503.295624,693.171753 503.050385,679.010071 503.100952,664.857239 
	C503.423950,574.468140 503.641449,484.078400 504.209229,393.690826 
	C504.281158,382.237091 506.558167,370.892273 513.039062,360.931213 
	C518.364136,352.746490 527.368774,349.452362 535.420898,344.331970 
	C537.207214,343.858093 538.627075,343.458496 540.385864,343.102081 
	C542.840576,342.584778 544.956299,342.024323 547.417664,341.511353 
	C548.735107,341.220978 549.706848,340.883118 551.037964,340.728760 
	C551.925110,340.928284 552.452942,340.944366 553.341675,341.175720 
	C560.427002,341.487823 567.151428,341.584625 574.214233,341.781921 
	C575.420410,341.390594 576.288147,340.898743 577.392578,340.304382 
	C577.792480,340.147949 577.955811,340.093994 578.432861,340.073853 
	C579.252441,339.409729 579.758301,338.711823 580.265747,337.574402 
	C580.294189,335.086639 580.321045,333.038391 580.518311,330.786011 
	C580.203796,329.993958 579.718811,329.406067 579.032959,328.589966 
	C578.280640,328.110260 577.729126,327.858765 577.020203,327.240906 
	C575.128601,326.571625 573.395691,326.015442 571.659912,326.006287 
	C558.690430,325.937988 545.720398,325.963135 532.750549,325.987152 
	C510.140076,326.029022 487.529694,326.091095 464.458740,326.114990 
	C457.727936,326.068420 451.455841,325.966980 445.188751,326.099365 
	C443.772186,326.129303 442.372772,326.972809 440.500916,327.483917 
	C434.137909,327.360291 428.239502,327.194519 422.289429,326.774689 
	C422.043762,325.833374 421.849762,325.146088 422.132507,324.340424 
	C424.772125,324.163361 426.934998,324.053894 429.097839,324.053558 
	C469.580811,324.047394 510.063782,324.065643 550.546692,324.012787 
	C552.022156,324.010864 553.496643,323.338287 555.058228,322.635132 
	C555.173035,321.821777 555.201294,321.351624 555.229492,320.881439 
	C559.984070,323.555695 564.840210,325.097809 570.398926,323.305573 
	C572.210144,322.721558 574.363159,323.025146 576.342163,323.168304 
	C581.111145,323.513184 583.627075,326.377991 583.861694,331.226532 
	C584.036072,334.830322 584.440613,338.423004 584.512756,342.427307 
	C580.382324,343.590240 576.510010,344.738953 572.575439,345.021790 
	C564.147217,345.627625 555.662903,345.504059 547.247009,346.215912 
	C540.353638,346.798950 533.532776,348.177826 528.036255,353.015625 
	C528.011475,352.993622 528.030457,353.055786 527.677490,353.054779 
	C519.759033,357.177277 513.627991,362.465729 512.250000,371.614441 
	C511.192535,373.827637 509.415527,375.971893 509.195892,378.265320 
	C507.899689,391.799805 506.156525,405.362946 506.055206,418.927582 
	C505.691833,467.573975 505.951691,516.224854 505.878113,564.873901 
	C505.872772,568.408325 506.248901,570.595947 510.430969,571.648376 
	C515.015503,572.802063 519.253479,575.332764 523.769226,577.571960 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M250.341888,174.074478 
	C250.341919,170.956207 250.341919,168.326324 250.341919,165.394562 
	C258.158844,165.394562 265.541504,165.394562 273.142944,165.394562 
	C273.243652,166.817566 273.416962,168.123459 273.416443,169.429291 
	C273.407684,191.074203 273.446594,212.719482 273.325714,234.363876 
	C273.251007,247.738342 269.105682,251.832901 255.647827,252.186951 
	C251.361481,252.299713 247.069672,252.204575 242.549896,252.204575 
	C242.549896,247.134079 242.549896,242.538239 242.549896,237.983841 
	C250.294937,235.922058 250.322784,235.922089 250.332443,227.510056 
	C250.352707,209.861008 250.340637,192.211929 250.341888,174.074478 
z"/>
<path fill="#BFB8BC" opacity="1.000000" stroke="none" 
	d="
M421.338867,617.168640 
	C421.616760,615.548401 422.132965,613.928711 422.136475,612.307922 
	C422.182281,591.084839 422.232086,569.860779 422.014374,548.639709 
	C421.995209,546.772644 420.106018,544.924805 419.269867,542.621826 
	C420.400330,542.335754 421.346313,542.495911 422.969025,542.770569 
	C422.969025,531.322754 422.968506,520.155945 422.969147,508.989105 
	C422.969940,495.395966 423.023254,481.802582 422.962463,468.209747 
	C422.831024,438.823730 424.111542,409.382568 419.986145,380.139557 
	C418.877441,372.280670 417.461334,364.411163 410.018433,359.228455 
	C409.989044,358.070282 409.966156,357.221497 410.264526,356.408386 
	C418.887390,362.662506 421.473846,371.689697 422.975494,381.324890 
	C423.845734,386.908508 425.043335,392.441071 426.090027,398.365356 
	C426.220917,399.450012 426.355377,400.165924 426.257812,401.313232 
	C425.995178,409.397949 425.962006,417.051300 425.934387,424.704620 
	C425.801788,461.462799 425.653625,498.220886 425.545685,534.979126 
	C425.484711,555.747192 425.397278,576.515991 425.519257,597.283264 
	C425.559967,604.217102 426.213562,611.147400 426.426361,618.473999 
	C426.380157,620.540894 426.494629,622.213135 426.376892,624.280151 
	C425.927765,629.651306 425.512177,634.628052 425.526672,639.603638 
	C425.609375,668.036194 425.845612,696.468201 425.929230,724.900696 
	C426.001556,749.495483 425.928925,774.090637 425.960754,798.685669 
	C425.963074,800.481140 426.293732,802.276245 426.317261,804.414490 
	C426.118195,805.505432 426.073578,806.253418 425.710999,807.220581 
	C424.172394,812.311462 422.951782,817.183167 421.496521,821.728333 
	C421.814087,815.983887 422.366241,810.566101 422.918396,805.148254 
	C422.925110,743.374939 422.931793,681.601624 422.846436,619.129639 
	C422.282532,618.010132 421.810699,617.589417 421.338867,617.168640 
z"/>
<path fill="#DB3537" opacity="1.000000" stroke="none" 
	d="
M528.060974,353.037628 
	C533.532776,348.177826 540.353638,346.798950 547.247009,346.215912 
	C555.662903,345.504059 564.147217,345.627625 572.575439,345.021790 
	C576.510010,344.738953 580.382324,343.590240 584.600586,342.824341 
	C602.539307,358.896423 616.738770,377.535889 627.183716,399.028076 
	C637.317261,419.879395 642.258301,441.939209 642.879700,465.035889 
	C642.905579,465.997284 642.683777,466.965363 642.542908,468.243408 
	C617.451233,468.243408 592.525208,468.243408 566.990601,468.243408 
	C565.811279,427.703674 550.851013,394.457062 514.149353,372.702484 
	C513.278381,372.114960 512.826843,371.912201 512.375244,371.709412 
	C512.375244,371.709412 512.328064,371.642029 512.289062,371.628235 
	C513.627991,362.465729 519.759033,357.177277 527.646729,353.407104 
	C527.045593,355.102448 526.421143,357.065430 525.146973,357.678833 
	C519.678345,360.311676 516.718628,364.909393 514.690369,369.082458 
	C523.584351,376.448700 532.944641,382.764801 540.441162,390.816223 
	C547.856384,398.780334 554.124573,407.807465 558.392639,418.351288 
	C563.943420,432.063873 568.247742,445.780334 567.847412,460.640961 
	C567.723145,465.252106 569.719360,466.225281 573.833740,466.192932 
	C592.661316,466.044800 611.490662,466.128601 630.319397,466.128937 
	C640.365173,466.129120 640.211548,466.129852 640.153687,456.105103 
	C640.043213,436.960999 634.076660,419.265594 626.431824,402.136993 
	C617.236023,381.533447 603.782776,363.779205 587.575073,348.153198 
	C586.198730,346.826233 583.484741,346.303864 581.429382,346.382477 
	C576.825928,346.558563 572.258972,347.561310 567.653625,347.843414 
	C559.206238,348.360901 550.699219,348.205322 542.300964,349.109192 
	C537.469299,349.629211 532.802185,351.677368 528.060974,353.037628 
z"/>
<path fill="#F20B0C" opacity="1.000000" stroke="none" 
	d="
M397.117035,817.735107 
	C397.529968,817.828064 397.942871,817.921082 398.906708,818.299683 
	C401.345734,818.909546 403.233826,819.233887 405.121918,819.558167 
	C406.121857,819.730652 407.121796,819.903137 408.647766,820.287781 
	C409.173798,820.499939 409.657562,820.605225 409.657562,820.605225 
	C409.657562,820.605225 410.041626,820.292847 410.041626,820.292847 
	C410.766510,820.438538 411.491394,820.584167 412.885437,820.974854 
	C415.272614,821.663452 416.990692,822.107056 418.906128,822.601624 
	C416.711395,830.981567 412.986877,838.244995 406.247009,843.208374 
	C402.185577,846.199341 397.316132,850.392761 391.838989,846.937195 
	C387.504303,844.202393 383.096619,840.607605 380.491425,836.319336 
	C375.728302,828.479248 372.239349,819.860107 368.346802,811.506348 
	C368.066650,810.905151 368.825623,809.819824 369.101471,808.959595 
	C372.335022,810.066772 375.568542,811.174011 379.354462,812.667114 
	C381.278564,813.356201 382.650269,813.659485 384.021973,813.962708 
	C384.021973,813.962708 384.005463,814.001648 384.273346,814.234497 
	C388.733185,815.556641 392.925110,816.645874 397.117035,817.735107 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M278.155548,827.038818 
	C278.351929,822.738708 278.610474,818.814636 278.791260,814.887085 
	C279.882477,791.178284 291.345276,781.976807 307.992645,775.167480 
	C311.532227,773.719666 315.587982,773.533875 319.610352,772.459229 
	C325.079407,772.487793 330.506622,772.155579 335.554047,773.384033 
	C339.188141,774.268433 342.315948,777.233093 345.310852,779.566895 
	C341.933960,778.607605 338.984283,777.119812 335.867218,776.198059 
	C334.429230,775.772827 332.664124,776.453674 330.588867,776.721802 
	C326.072357,776.749695 322.014282,776.688782 317.686401,776.405029 
	C315.661438,776.473267 313.751129,776.417542 312.180206,777.120361 
	C307.377197,779.269043 302.704651,781.709351 297.679199,784.171997 
	C294.573883,787.517273 291.770721,790.726807 288.617798,794.005554 
	C286.838440,797.048828 285.408844,800.023010 283.663330,803.187866 
	C278.705048,812.593933 280.307556,822.038574 282.443359,831.460205 
	C282.593872,832.124268 283.919556,832.521851 284.785370,833.357605 
	C284.948090,834.099243 285.024231,834.526611 284.817627,835.121460 
	C284.562347,835.918152 284.394287,836.641602 284.645508,837.162903 
	C293.975159,856.521606 308.735077,869.660583 329.355377,876.037354 
	C333.884338,877.437988 338.436035,878.765015 342.977264,880.125916 
	C340.760651,880.137268 338.461823,880.542480 336.340027,880.099548 
	C312.981628,875.223022 295.740662,861.891052 283.992340,841.379761 
	C281.506012,837.038940 280.090607,832.084839 278.155548,827.038818 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M369.002533,808.629272 
	C368.825623,809.819824 368.066650,810.905151 368.346802,811.506348 
	C372.239349,819.860107 375.728302,828.479248 380.491425,836.319336 
	C383.096619,840.607605 387.504303,844.202393 391.838989,846.937195 
	C397.316132,850.392761 402.185577,846.199341 406.247009,843.208374 
	C412.986877,838.244995 416.711395,830.981567 418.906128,822.601624 
	C416.990692,822.107056 415.272614,821.663452 413.256104,820.972046 
	C419.717438,820.968689 419.394745,815.861633 420.168365,811.404236 
	C420.534088,809.297119 421.515991,807.296997 422.568542,805.198303 
	C422.366241,810.566101 421.814087,815.983887 421.527649,822.031982 
	C421.711243,823.059937 421.629120,823.457642 421.303589,823.942261 
	C420.999359,824.702332 420.938477,825.375305 420.905243,826.418762 
	C418.953949,834.049805 414.696075,839.890442 409.011292,844.558044 
	C398.157990,853.469299 388.879272,852.580750 380.842865,841.479248 
	C375.833862,834.559814 372.539795,826.382812 368.624847,818.694458 
	C366.774963,815.061523 365.274261,811.250854 363.752319,807.115967 
	C363.971771,806.438660 364.054199,806.165955 364.136597,805.893311 
	C365.725616,806.695190 367.314636,807.497070 369.002533,808.629272 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M448.202942,851.224548 
	C439.136353,860.820801 428.228210,867.763428 416.251740,873.065063 
	C411.880219,875.000244 407.249542,876.344727 402.768219,878.040771 
	C401.673126,878.455261 400.700470,879.193237 399.849579,880.938904 
	C403.502991,881.646423 407.156403,882.353943 411.099365,883.309570 
	C406.384125,883.039917 401.375122,882.559265 396.375977,881.992004 
	C392.792297,881.585449 389.221741,881.063110 385.600769,880.032593 
	C395.930206,876.326660 406.479248,873.646667 416.605011,869.840515 
	C421.759125,867.903259 426.152100,863.941040 430.896667,860.912354 
	C430.904236,860.930603 430.902924,860.911743 431.235352,860.940552 
	C432.386322,860.316406 433.204895,859.663391 434.371704,858.940125 
	C436.155365,857.490967 437.590820,856.112000 439.280273,854.639832 
	C440.124390,853.878540 440.714539,853.210510 441.642151,852.509277 
	C442.418091,852.367249 442.856537,852.258423 443.566376,852.102783 
	C444.245514,851.713257 444.653290,851.370544 445.342804,850.903198 
	C446.200562,850.587158 446.776581,850.395813 447.564331,850.460144 
	C447.918365,850.885437 448.060638,851.054993 448.202942,851.224548 
z"/>
<path fill="#828C93" opacity="1.000000" stroke="none" 
	d="
M343.301056,880.127075 
	C338.436035,878.765015 333.884338,877.437988 329.355377,876.037354 
	C308.735077,869.660583 293.975159,856.521606 284.645508,837.162903 
	C284.394287,836.641602 284.562347,835.918152 285.103668,835.308960 
	C296.384430,856.811829 313.024323,870.858948 336.747131,875.985840 
	C342.174316,877.158752 347.626831,878.214355 352.743896,879.564026 
	C349.917877,879.947693 347.415710,880.091370 344.913513,880.234985 
	C344.483948,880.199402 344.054413,880.163818 343.301056,880.127075 
z"/>
<path fill="#828C93" opacity="1.000000" stroke="none" 
	d="
M430.889130,860.894165 
	C426.152100,863.941040 421.759125,867.903259 416.605011,869.840515 
	C406.479248,873.646667 395.930206,876.326660 385.309448,879.763733 
	C384.601288,880.075317 384.139954,880.095886 382.912659,880.060791 
	C379.398956,879.779236 376.651215,879.553406 374.172943,878.995544 
	C390.333221,876.912048 405.747803,873.409241 420.009033,865.900879 
	C422.275787,864.707581 424.194153,862.852478 426.476807,861.264893 
	C426.680023,861.226440 427.071625,861.088623 427.455597,861.101318 
	C428.856079,861.040710 429.872620,860.967468 430.889130,860.894165 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M373.903503,879.327515 
	C376.651215,879.553406 379.398956,879.779236 382.569336,880.100220 
	C380.643799,880.920593 378.281555,882.306580 375.949738,882.257141 
	C365.683319,882.039429 355.425629,881.407593 345.039154,880.580383 
	C347.415710,880.091370 349.917877,879.947693 353.203400,879.524658 
	C360.625641,879.272583 367.264587,879.300049 373.903503,879.327515 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M364.087494,805.532410 
	C364.054199,806.165955 363.971771,806.438660 363.489258,806.935608 
	C362.125671,806.369385 361.162201,805.578918 360.116974,804.460938 
	C359.015686,802.423828 357.996124,800.714111 357.003815,798.682251 
	C356.473846,797.780762 355.916626,797.201416 355.284393,796.285645 
	C353.493011,793.615967 351.776642,791.282715 350.070374,788.618713 
	C350.042450,787.848267 350.004425,787.408447 350.297638,786.954956 
	C351.069702,786.928833 351.510529,786.916443 351.951355,786.904114 
	C355.980377,792.993225 360.009369,799.082336 364.087494,805.532410 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M507.256836,879.427063 
	C516.156860,876.595764 525.310791,874.009033 534.831421,871.524170 
	C529.195312,873.718201 523.240662,875.966492 517.169739,877.837524 
	C514.057312,878.796753 510.737030,879.081848 507.256836,879.427063 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M331.047333,776.633057 
	C332.664124,776.453674 334.429230,775.772827 335.867218,776.198059 
	C338.984283,777.119812 341.933960,778.607605 345.279236,779.908691 
	C345.941162,780.524658 346.275970,781.095825 346.985901,782.166382 
	C347.918365,783.786377 348.475647,784.906982 348.657196,786.019043 
	C347.793213,786.080078 347.304932,786.149658 346.614441,785.968994 
	C345.642883,785.352356 344.873566,784.986023 344.221863,784.422974 
	C344.339478,784.226196 344.223053,783.781067 344.172333,783.445068 
	C343.057831,782.455017 341.994080,781.800903 340.635681,780.966675 
	C339.018799,780.400696 337.696503,780.014771 336.222168,779.318054 
	C334.336517,778.426270 332.602966,777.845276 330.884827,777.098267 
	C330.900208,776.932251 331.047333,776.633057 331.047333,776.633057 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M556.876709,593.490234 
	C549.581360,590.269348 542.242676,586.725891 534.739258,582.900513 
	C541.994202,586.134949 549.413879,589.651367 556.876709,593.490234 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M449.327698,885.469788 
	C455.407379,884.801208 461.801331,884.400024 468.653412,884.137817 
	C468.209717,884.796509 467.316254,885.747498 466.404694,885.765137 
	C460.819427,885.873291 455.230011,885.770020 449.327698,885.469788 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M414.225037,884.114258 
	C420.404236,884.151367 426.867371,884.475464 433.680786,885.029297 
	C427.523712,884.973145 421.016357,884.687134 414.225037,884.114258 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M387.948242,606.692749 
	C392.551910,607.927124 397.245453,609.497009 402.159058,611.345825 
	C397.598785,610.092651 392.818451,608.560486 387.948242,606.692749 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M420.952698,617.045776 
	C421.810699,617.589417 422.282532,618.010132 422.845062,618.653198 
	C418.707825,617.509216 414.479889,616.142944 410.090515,614.460815 
	C413.474884,615.070862 417.020691,615.996887 420.952698,617.045776 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M396.998779,817.393188 
	C392.925110,816.645874 388.733185,815.556641 384.286255,814.216064 
	C388.314392,814.993530 392.597473,816.022339 396.998779,817.393188 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M377.089233,602.381653 
	C379.895813,603.117249 382.847809,604.175598 385.997803,605.519287 
	C383.208740,604.771240 380.221680,603.737854 377.089233,602.381653 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M482.277649,883.433594 
	C484.828156,882.644287 487.638306,882.117249 490.745880,881.747925 
	C488.207977,882.502380 485.372620,883.099121 482.277649,883.433594 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M349.032959,786.027588 
	C348.475647,784.906982 347.918365,783.786377 347.236694,782.377197 
	C348.724091,783.457947 350.335876,784.827209 351.949524,786.550293 
	C351.510529,786.916443 351.069702,786.928833 350.084351,786.863708 
	C349.272064,786.599365 349.103119,786.346497 349.032959,786.027588 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M367.115662,598.319946 
	C369.686432,598.990906 372.405640,599.983398 375.289734,601.285889 
	C372.724426,600.611084 369.994263,599.626282 367.115662,598.319946 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M561.959290,860.748901 
	C563.522156,859.435181 565.448486,858.228394 567.727783,857.070251 
	C566.161438,858.364563 564.242126,859.610168 561.959290,860.748901 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M569.072266,856.856934 
	C570.605103,855.591553 572.504761,854.367126 574.756836,853.180725 
	C573.219116,854.445129 571.329102,855.671570 569.072266,856.856934 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M315.933411,571.593994 
	C317.624481,572.215149 319.371857,573.173828 321.217773,574.457092 
	C319.540802,573.831604 317.765289,572.881531 315.933411,571.593994 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M405.019867,819.245239 
	C403.233826,819.233887 401.345734,818.909546 399.224457,818.313293 
	C400.966797,818.338379 402.942291,818.635376 405.019867,819.245239 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M496.262451,881.361816 
	C498.181427,880.613403 500.331879,880.096313 502.760986,879.737549 
	C500.857758,880.461670 498.675842,881.027405 496.262451,881.361816 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M471.242615,884.366821 
	C472.494598,883.898071 473.991364,883.683105 475.765259,883.652405 
	C474.524048,884.097961 473.005737,884.359253 471.242615,884.366821 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M538.868042,870.621216 
	C540.442810,869.669983 542.380371,868.847229 544.687134,868.068604 
	C543.114502,868.991699 541.172607,869.870789 538.868042,870.621216 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M345.986328,588.496216 
	C347.584137,588.841980 349.240967,589.534241 351.047180,590.535034 
	C349.479492,590.176575 347.762421,589.509583 345.986328,588.496216 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M555.942444,863.751709 
	C557.163513,862.749634 558.736145,861.867920 560.663391,861.009094 
	C559.443359,861.978577 557.868713,862.925293 555.942444,863.751709 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M330.963074,580.547729 
	C332.298279,580.841919 333.693665,581.478271 335.203156,582.429749 
	C333.885895,582.126587 332.454559,581.508179 330.963074,580.547729 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M356.932495,593.698608 
	C358.272797,593.907959 359.721008,594.445312 361.297974,595.297241 
	C359.964630,595.083435 358.502502,594.555054 356.932495,593.698608 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M592.868164,617.654785 
	C591.704895,617.194824 590.539062,616.405334 589.327820,615.315613 
	C590.476807,615.785339 591.671265,616.555298 592.868164,617.654785 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M321.972168,575.489746 
	C323.311676,575.781433 324.703888,576.421204 326.232788,577.366943 
	C324.921265,577.061218 323.473083,576.449524 321.972168,575.489746 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M383.844879,813.636719 
	C382.650269,813.659485 381.278564,813.356201 379.724121,812.745850 
	C380.916870,812.729370 382.292328,813.020020 383.844879,813.636719 
z"/>
<path fill="#E16A71" opacity="1.000000" stroke="none" 
	d="
M555.001038,320.667053 
	C555.201294,321.351624 555.173035,321.821777 554.669312,322.600403 
	C553.696045,322.765045 553.198242,322.621277 552.700500,322.477509 
	C552.760620,321.819427 552.820801,321.161316 552.950073,320.177490 
	C553.603577,320.052063 554.188110,320.252350 555.001038,320.667053 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M532.792358,581.606445 
	C531.718506,581.516968 530.518066,581.123352 529.194336,580.435242 
	C530.269226,580.527893 531.467468,580.915100 532.792358,581.606445 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M404.107330,612.385864 
	C405.025970,612.325195 406.109894,612.588623 407.361145,613.156250 
	C406.443176,613.210266 405.357880,612.960083 404.107330,612.385864 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M337.998169,584.463745 
	C338.924469,584.496765 339.911469,584.879333 341.045868,585.560425 
	C340.148468,585.510437 339.103668,585.161804 337.998169,584.463745 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M341.988098,586.513916 
	C342.907288,586.528931 343.899628,586.890747 345.040771,587.547241 
	C344.146851,587.514893 343.104034,587.187744 341.988098,586.513916 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M563.847473,597.486694 
	C562.990417,597.433960 562.115906,597.065857 561.149780,596.416260 
	C561.982117,596.480286 562.906006,596.825745 563.847473,597.486694 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M363.005432,596.479370 
	C363.946991,596.472778 364.963623,596.819092 366.135895,597.465088 
	C365.221191,597.453979 364.150848,597.143127 363.005432,596.479370 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M566.894470,599.387207 
	C566.282349,599.425964 565.636719,599.172485 564.929932,598.649902 
	C565.532837,598.618896 566.196838,598.856873 566.894470,599.387207 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M546.861694,867.713989 
	C547.183838,867.097473 547.833435,866.605652 548.796814,866.188477 
	C548.470093,866.788269 547.829651,867.313477 546.861694,867.713989 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M570.823792,601.637451 
	C570.191345,601.685425 569.530457,601.410156 568.763916,600.875061 
	C569.370544,600.848206 570.083008,601.081177 570.823792,601.637451 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M574.994812,604.483887 
	C574.353210,604.486267 573.659546,604.184937 572.927979,603.588135 
	C573.574219,603.588440 574.258545,603.884338 574.994812,604.483887 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M327.033478,578.374756 
	C327.743042,578.329102 328.502991,578.637268 329.366455,579.257507 
	C328.674591,579.289246 327.879242,579.008972 327.033478,578.374756 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M527.859985,579.382507 
	C527.211060,579.484497 526.506653,579.274719 525.692200,578.785889 
	C526.322937,578.694824 527.063721,578.882812 527.859985,579.382507 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M559.916626,595.294556 
	C559.300720,595.324280 558.650696,595.061829 557.934204,594.516235 
	C558.539368,594.489563 559.210938,594.745972 559.916626,595.294556 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M580.070007,607.985962 
	C579.694824,608.088928 579.240051,607.959167 578.746094,607.567749 
	C579.134705,607.455078 579.562561,607.604248 580.070007,607.985962 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M512.414307,371.993225 
	C512.826843,371.912201 513.278381,372.114960 513.845703,372.537598 
	C513.458801,372.597290 512.956116,372.437134 512.414307,371.993225 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M583.831665,610.614014 
	C583.867859,610.819824 583.438110,610.929688 583.438110,610.929688 
	C583.438110,610.929688 583.083984,610.662964 583.063232,610.443726 
	C583.341919,610.087891 583.592957,610.149109 583.831665,610.614014 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M581.877930,609.313416 
	C581.878967,609.464539 581.549622,609.614563 581.549622,609.614563 
	C581.549622,609.614563 581.267578,609.388062 581.301270,609.210205 
	C581.552551,608.922546 581.733215,608.965881 581.877930,609.313416 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M336.073303,583.336304 
	C336.319366,583.143494 336.672852,583.214783 337.132690,583.555786 
	C336.886261,583.750549 336.533508,583.675476 336.073303,583.336304 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M478.912506,883.795776 
	C479.006134,883.483521 479.319244,883.319031 479.837891,883.351196 
	C479.739594,883.679749 479.435822,883.811646 478.912506,883.795776 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M577.877075,606.572388 
	C577.930969,606.777954 577.481750,606.904236 577.481750,606.904236 
	C577.481750,606.904236 577.112305,606.619690 577.080322,606.390198 
	C577.353699,606.052551 577.612000,606.121216 577.877075,606.572388 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M352.033234,591.447388 
	C352.268280,591.251892 352.606445,591.309509 353.053528,591.625061 
	C352.820404,591.822205 352.478394,591.761353 352.033234,591.447388 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M576.757751,605.753662 
	C576.820190,605.984192 576.351196,606.045532 576.351196,606.045532 
	C576.351196,606.045532 575.944458,605.804260 575.887085,605.576233 
	C576.166321,605.169800 576.454895,605.228088 576.757751,605.753662 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M354.080383,592.402222 
	C354.307007,592.179626 354.667084,592.207825 355.152893,592.494873 
	C354.923706,592.720154 354.568756,592.686523 354.080383,592.402222 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M553.075195,864.894958 
	C553.043884,864.587280 553.283752,864.328491 553.795532,864.131348 
	C553.827148,864.443359 553.586853,864.693665 553.075195,864.894958 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M550.977417,865.798096 
	C550.958557,865.477722 551.218506,865.221313 551.759277,865.037048 
	C551.778809,865.360168 551.517578,865.611084 550.977417,865.798096 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M409.884949,820.133057 
	C410.041626,820.292847 409.657562,820.605225 409.657562,820.605225 
	C409.657562,820.605225 409.173798,820.499939 409.028931,820.298645 
	C409.139069,819.876343 409.420471,819.834961 409.884949,820.133057 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M314.092133,570.240479 
	C314.342804,570.071045 314.705170,570.166260 315.151978,570.546875 
	C314.892242,570.723206 314.548035,570.614136 314.092133,570.240479 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M586.017700,612.214722 
	C586.059265,612.372681 585.695557,612.575195 585.695557,612.575195 
	C585.695557,612.575195 585.350769,612.342590 585.365295,612.135010 
	C585.611450,611.818726 585.810242,611.861816 586.017700,612.214722 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M319.774292,771.895142 
	C319.497681,771.939819 319.176117,771.733887 318.931946,771.228333 
	C319.249359,771.167297 319.489349,771.405884 319.774292,771.895142 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M587.876099,613.639160 
	C587.938782,613.858704 587.450256,613.923279 587.450256,613.923279 
	C587.450256,613.923279 587.053406,613.631348 587.023010,613.387817 
	C587.327271,613.054382 587.600830,613.146179 587.876099,613.639160 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M409.022675,613.978943 
	C409.135651,613.769348 409.479614,613.743042 409.832794,613.920166 
	C409.645935,614.136536 409.449799,614.149353 409.022675,613.978943 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M576.072449,852.862610 
	C575.997681,852.555603 576.214233,852.271484 576.714600,851.982178 
	C576.786865,852.279846 576.575317,852.582703 576.072449,852.862610 
z"/>
<path fill="#FEFDFC" opacity="1.000000" stroke="none" 
	d="
M410.024933,359.537811 
	C417.461334,364.411163 418.877441,372.280670 419.986145,380.139557 
	C424.111542,409.382568 422.831024,438.823730 422.962463,468.209747 
	C423.023254,481.802582 422.969940,495.395966 422.969147,508.989105 
	C422.968506,520.155945 422.969025,531.322754 422.969025,542.770569 
	C421.346313,542.495911 420.400330,542.335754 419.115295,542.294922 
	C418.039398,542.214783 417.302490,542.015259 416.166748,541.482788 
	C415.549774,540.957153 415.322449,540.945801 415.085876,541.115784 
	C393.240631,533.708618 372.377167,524.287720 353.550446,510.780548 
	C315.779724,483.682007 314.096100,439.956726 332.198212,406.263153 
	C339.843506,392.032898 350.960571,380.949829 365.771179,373.012817 
	C366.598206,372.664215 366.821472,372.391357 367.044769,372.118469 
	C367.044769,372.118469 367.345978,371.858490 367.827393,371.877014 
	C368.551514,371.622559 368.794220,371.349609 369.036926,371.076660 
	C369.984375,370.595276 370.931854,370.113953 372.519531,369.697876 
	C373.801971,369.240631 374.444153,368.718079 375.086334,368.195496 
	C375.086334,368.195496 375.421814,367.944061 375.994080,368.006042 
	C378.051300,367.389648 379.536224,366.711243 381.021149,366.032837 
	C381.021149,366.032837 381.375336,365.835938 381.921997,365.942444 
	C383.668518,365.454834 384.868408,364.860748 386.068268,364.266663 
	C386.432037,364.118744 386.795837,363.970856 387.851929,363.915405 
	C391.090851,363.229187 393.637421,362.450500 396.184021,361.671814 
	C396.584991,361.534180 396.985962,361.396515 397.962402,361.482697 
	C402.366913,360.983612 406.195923,360.260712 410.024933,359.537811 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M410.018433,359.228455 
	C406.195923,360.260712 402.366913,360.983612 398.282440,361.475067 
	C400.799561,360.398102 403.572174,359.552582 407.235626,358.435425 
	C398.068420,351.901337 388.823425,347.458374 377.312775,346.671814 
	C375.130676,346.181366 373.562714,346.057251 371.994720,345.933167 
	C365.391907,345.766205 358.787720,345.641083 352.186768,345.418671 
	C347.225464,345.251495 343.881409,342.435516 343.981812,337.594238 
	C344.070038,333.340454 345.246033,329.109222 346.709137,324.898010 
	C347.728851,324.964783 347.980988,325.001587 348.120880,325.396912 
	C347.295898,328.513153 344.109436,331.117157 347.473450,334.486908 
	C347.476135,335.560333 347.493683,336.275970 347.317749,337.272522 
	C346.917480,339.795349 347.332611,341.428101 350.454163,340.822845 
	C351.109894,341.118652 351.494110,341.267731 352.025208,341.776978 
	C358.113220,342.133118 364.054352,342.129120 370.415100,342.133667 
	C372.777130,342.215912 374.719543,342.289612 376.826294,342.670166 
	C378.062317,343.152649 379.134033,343.328247 380.545013,343.601257 
	C381.585876,343.766693 382.287476,343.834686 383.164368,344.175415 
	C383.955200,344.498230 384.570740,344.548309 385.487549,344.692749 
	C386.205872,344.841736 386.622925,344.896362 387.135193,345.171082 
	C387.230377,345.391144 387.646851,345.626190 387.646851,345.626190 
	C387.646851,345.626190 388.114380,345.525269 388.418182,345.610291 
	C389.139557,345.731476 389.557129,345.767670 390.146301,346.090668 
	C390.942657,346.477966 391.567444,346.578461 392.473083,346.918610 
	C398.483704,350.229736 404.213501,353.301208 409.943298,356.372681 
	C409.966156,357.221497 409.989044,358.070282 410.018433,359.228455 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M552.254700,322.458069 
	C553.198242,322.621277 553.696045,322.765045 554.582703,322.943542 
	C553.496643,323.338287 552.022156,324.010864 550.546692,324.012787 
	C510.063782,324.065643 469.580811,324.047394 429.097839,324.053558 
	C426.934998,324.053894 424.772125,324.163361 421.810150,324.275482 
	C420.119293,324.343414 419.227539,324.357880 417.845886,324.264587 
	C410.789459,324.257751 404.222931,324.358612 397.370087,324.398438 
	C396.503021,324.377930 395.922333,324.418488 394.864990,324.340881 
	C381.821289,324.273254 369.254211,324.323822 356.336700,324.352600 
	C355.049896,324.331360 354.113586,324.331909 352.766968,324.241577 
	C351.241486,324.155487 350.126282,324.160339 348.953094,323.817932 
	C348.857727,323.238953 348.820312,323.007263 348.782898,322.775543 
	C368.231018,322.638672 387.679016,322.399841 407.127258,322.385071 
	C455.354431,322.348389 503.581665,322.414062 552.254700,322.458069 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M395.829285,361.541016 
	C393.637421,362.450500 391.090851,363.229187 388.146423,363.889160 
	C390.323883,362.983704 392.899200,362.196991 395.829285,361.541016 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M380.664917,365.935791 
	C379.536224,366.711243 378.051300,367.389648 376.202454,367.983185 
	C377.328583,367.211761 378.818634,366.525238 380.664917,365.935791 
z"/>
<path fill="#E16A71" opacity="1.000000" stroke="none" 
	d="
M372.257263,346.202637 
	C373.562714,346.057251 375.130676,346.181366 376.887177,346.571869 
	C375.557037,346.716217 374.038422,346.594177 372.257263,346.202637 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M385.734985,364.160034 
	C384.868408,364.860748 383.668518,365.454834 382.124542,365.936646 
	C382.987518,365.234039 384.194611,364.643707 385.734985,364.160034 
z"/>
<path fill="#E16A71" opacity="1.000000" stroke="none" 
	d="
M348.453949,322.775391 
	C348.820312,323.007263 348.857727,323.238953 348.898651,324.031982 
	C348.902191,324.593262 348.774811,325.020203 348.639191,325.018921 
	C348.503571,325.017609 348.233154,325.038391 348.233154,325.038391 
	C347.980988,325.001587 347.728851,324.964783 347.107147,324.828735 
	C347.200073,324.078064 347.662537,323.426666 348.453949,322.775391 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M374.780548,368.133087 
	C374.444153,368.718079 373.801971,369.240631 372.835968,369.618164 
	C373.166351,369.005676 373.820557,368.538177 374.780548,368.133087 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M366.766174,372.080414 
	C366.821472,372.391357 366.598206,372.664215 366.096191,372.902649 
	C366.040833,372.592926 366.264191,372.317627 366.766174,372.080414 
z"/>
<path fill="#BFB8BC" opacity="1.000000" stroke="none" 
	d="
M415.109772,541.322632 
	C415.322449,540.945801 415.549774,540.957153 415.820068,541.324524 
	C415.872223,541.499146 415.510590,541.700317 415.510590,541.700317 
	C415.510590,541.700317 415.133667,541.529541 415.109772,541.322632 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M368.756989,371.043182 
	C368.794220,371.349609 368.551514,371.622559 368.022278,371.837036 
	C367.982849,371.522247 368.229950,371.265991 368.756989,371.043182 
z"/>
<path fill="#FCF8F7" opacity="1.000000" stroke="none" 
	d="
M506.055298,657.001526 
	C506.461212,654.343933 506.867126,651.686279 507.658234,648.884888 
	C508.242920,648.814880 508.442444,648.888733 508.967865,649.341003 
	C509.293793,649.719482 509.658478,649.880066 509.658478,649.880066 
	C509.658478,649.880066 510.042725,649.773315 510.042725,649.773315 
	C510.224091,649.806091 510.405426,649.838928 510.932556,650.228760 
	C511.278351,650.585754 511.708557,650.791016 511.708557,650.791016 
	C511.708557,650.791016 512.158813,650.634216 512.158813,650.634216 
	C512.340393,650.703979 512.522034,650.773804 512.974365,651.252808 
	C513.245117,651.662048 513.618042,651.860901 513.618042,651.860901 
	C513.618042,651.860901 514.028503,651.759399 514.028503,651.759399 
	C514.224854,651.804749 514.421265,651.850037 514.935242,652.276855 
	C515.252869,652.658264 515.630920,652.873230 515.630920,652.873230 
	C515.630920,652.873230 516.060303,652.802429 516.060303,652.802429 
	C516.249146,652.854004 516.438049,652.905518 516.933472,653.323486 
	C517.239868,653.689819 517.599976,653.882935 517.599976,653.882935 
	C517.599976,653.882935 518.000305,653.800171 518.000305,653.800171 
	C518.203308,653.863159 518.406372,653.926208 518.917053,654.373840 
	C519.224609,654.758362 519.565979,654.943726 519.565979,654.943726 
	C519.565979,654.943726 519.943909,654.852844 519.943909,654.852844 
	C519.943909,654.852844 520.109131,654.830444 520.227051,655.137329 
	C520.344971,655.444214 520.759155,655.718201 520.759155,655.718201 
	C520.759155,655.718201 521.254333,655.677368 521.254333,655.677368 
	C552.473694,670.357666 570.146423,702.022522 567.551392,737.648132 
	C564.838196,774.895874 544.262329,798.264771 510.305786,812.042358 
	C509.439026,812.232727 509.190552,812.443726 508.942108,812.654724 
	C508.942108,812.654724 508.550293,812.955139 508.069092,812.965088 
	C507.225189,813.049255 507.006470,813.267578 506.931732,813.630127 
	C506.761292,813.710510 506.590820,813.790955 505.977417,813.813660 
	C505.263489,813.759399 505.086212,813.889954 505.002594,814.147583 
	C504.284302,814.416138 503.565979,814.684692 502.158752,814.932190 
	C500.955963,815.217346 500.442139,815.523621 499.928284,815.829834 
	C499.928284,815.829834 499.883423,815.919617 499.596588,815.857910 
	C499.309753,815.796204 498.897552,816.074158 498.897552,816.074158 
	C498.581421,816.197205 498.265289,816.320251 497.416382,816.344666 
	C496.570709,816.459351 496.257812,816.672607 495.944916,816.885864 
	C495.944916,816.885864 495.929443,816.977905 495.548584,816.857178 
	C494.436615,816.721252 493.705475,816.706055 492.983215,816.313721 
	C494.088989,807.299805 503.029633,800.403809 509.008514,804.482056 
	C505.872498,805.699036 503.086365,806.780212 500.283081,807.867981 
	C502.539368,811.688843 508.128876,811.661682 513.189392,808.556458 
	C517.072144,806.173767 521.205322,804.092896 525.445923,802.438721 
	C528.074646,801.413208 529.179565,800.145386 529.045105,797.378906 
	C528.933655,795.087280 529.219543,792.776306 529.329468,790.473938 
	C529.812134,790.458984 530.294800,790.444092 530.777466,790.429138 
	C531.155212,792.170471 531.532898,793.911804 532.177795,796.885071 
	C534.129272,794.876282 536.286926,793.621399 536.850525,791.857300 
	C537.785828,788.929871 538.857544,787.007568 542.130188,786.289368 
	C544.448975,785.780457 546.549866,784.278992 549.064270,783.068298 
	C547.435486,781.328857 546.073181,779.873962 544.520203,778.215393 
	C547.474670,777.509216 550.162170,777.585388 550.577576,776.651245 
	C553.337036,770.445129 557.394714,765.071228 560.912964,759.356506 
	C561.141479,758.985352 560.997620,758.375732 561.001160,757.876831 
	C561.032593,753.442810 560.827393,748.992249 561.143860,744.578979 
	C561.540161,739.052307 562.289124,733.546326 563.033752,728.051392 
	C563.406189,725.303101 564.615356,722.585327 564.541382,719.878479 
	C564.426453,715.676208 564.022400,711.346497 562.796631,707.361145 
	C562.067749,704.991577 559.868225,701.986938 557.730713,701.380737 
	C554.093872,700.349487 553.725708,698.331970 554.052429,695.530945 
	C554.267761,693.685486 554.942749,691.893555 555.411865,690.077698 
	C555.044312,689.697937 554.676758,689.318176 554.309265,688.938416 
	C553.210876,690.018250 551.781189,690.922791 551.094421,692.220459 
	C550.145264,694.014099 549.755798,696.103821 549.126648,698.066772 
	C548.540771,697.947021 547.954895,697.827271 547.369019,697.707581 
	C547.033875,693.756592 546.698730,689.805664 546.363586,685.854736 
	C546.429932,683.625488 545.015015,681.311951 545.151306,679.093811 
	C545.403137,674.995117 543.110962,673.805054 539.957153,673.186768 
	C538.206482,672.843628 536.382996,672.871887 534.627197,672.735413 
	C534.532654,671.385864 534.458008,670.320190 534.371826,669.090149 
	C532.605347,669.367554 531.187012,669.590271 529.692932,669.824890 
	C529.128479,668.108521 528.638428,666.618591 528.188660,665.250916 
	C525.724243,665.032288 523.612854,664.844971 521.451477,664.653259 
	C521.275085,663.710571 520.899963,662.988831 521.078064,662.449829 
	C522.274109,658.829346 520.674011,657.987915 517.413330,656.268066 
	C512.882812,653.878479 509.811188,657.529053 506.055298,657.001526 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M472.028625,821.079163 
	C473.040894,820.735352 474.053192,820.391479 475.835388,819.989014 
	C480.992676,819.198181 485.380035,818.466064 489.897095,817.886841 
	C490.026825,818.039673 490.282196,818.348694 490.282227,818.348755 
	C489.355347,818.699280 488.405457,819.405090 487.505127,819.346619 
	C475.930969,818.595642 471.303040,827.478577 465.238831,834.786255 
	C460.639648,840.328674 455.085907,845.078918 449.334808,849.983276 
	C448.530426,849.639221 448.348114,849.493469 448.309326,849.044556 
	C449.075317,847.842041 449.697876,846.942749 450.573547,845.895569 
	C451.580536,844.906006 452.334442,844.064209 453.342590,842.993103 
	C456.127319,839.515076 458.657745,836.266357 461.428101,832.877930 
	C462.182281,831.985413 462.696503,831.232605 463.540985,830.443054 
	C464.841614,829.944275 465.811951,829.482178 467.119019,828.895508 
	C467.946625,827.768188 468.437531,826.765503 468.969574,825.424194 
	C469.035309,824.411011 469.059906,823.736450 469.082123,823.063171 
	C469.079773,823.064392 469.102020,823.091370 469.396179,823.015808 
	C470.469757,822.319885 471.249207,821.699524 472.028625,821.079163 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M492.974335,816.690918 
	C493.705475,816.706055 494.436615,816.721252 495.526184,816.816223 
	C494.269562,817.380676 492.654572,817.865234 490.660889,818.349243 
	C490.282196,818.348694 490.026825,818.039673 489.993408,817.737244 
	C489.960022,817.434814 490.048218,817.088989 490.418640,817.078979 
	C491.517517,816.942993 492.245911,816.816956 492.974335,816.690918 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M500.238098,815.914917 
	C500.442139,815.523621 500.955963,815.217346 501.777710,814.993896 
	C501.573090,815.384460 501.060516,815.692200 500.238098,815.914917 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M512.061523,650.414062 
	C512.158813,650.634216 511.708557,650.791016 511.708557,650.791016 
	C511.708557,650.791016 511.278351,650.585754 511.166992,650.375183 
	C511.365570,649.959656 511.668427,649.969482 512.061523,650.414062 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M509.192688,812.741333 
	C509.190552,812.443726 509.439026,812.232727 509.940063,812.083862 
	C509.942871,812.373291 509.693085,812.600647 509.192688,812.741333 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M507.181824,813.709778 
	C507.006470,813.267578 507.225189,813.049255 507.828125,813.018799 
	C507.856201,813.304810 507.644043,813.547119 507.181824,813.709778 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M505.161743,814.263184 
	C505.086212,813.889954 505.263489,813.759399 505.695007,813.826782 
	C505.821106,814.217896 505.642883,814.378296 505.161743,814.263184 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M496.220215,816.978271 
	C496.257812,816.672607 496.570709,816.459351 497.160675,816.357788 
	C497.123688,816.669861 496.809601,816.870239 496.220215,816.978271 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M498.996094,816.217285 
	C498.897552,816.074158 499.309753,815.796204 499.556427,815.827881 
	C499.566925,816.026489 499.330750,816.193481 498.996094,816.217285 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M521.176331,655.438049 
	C521.254333,655.677368 520.759155,655.718201 520.759155,655.718201 
	C520.759155,655.718201 520.344971,655.444214 520.268433,655.209717 
	C520.494080,655.049683 520.796204,655.124207 521.176331,655.438049 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M519.900085,654.647766 
	C519.943909,654.852844 519.565979,654.943726 519.565979,654.943726 
	C519.565979,654.943726 519.224609,654.758362 519.163818,654.575806 
	C519.369934,654.170776 519.620972,654.187256 519.900085,654.647766 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M517.949951,653.584106 
	C518.000305,653.800171 517.599976,653.882935 517.599976,653.882935 
	C517.599976,653.882935 517.239868,653.689819 517.167969,653.500183 
	C517.381531,653.085632 517.649353,653.104797 517.949951,653.584106 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M516.005005,652.576599 
	C516.060303,652.802429 515.630920,652.873230 515.630920,652.873230 
	C515.630920,652.873230 515.252869,652.658264 515.179077,652.455566 
	C515.415100,652.041382 515.696594,652.073975 516.005005,652.576599 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M513.969116,651.550903 
	C514.028503,651.759399 513.618042,651.860901 513.618042,651.860901 
	C513.618042,651.860901 513.245117,651.662048 513.175232,651.464478 
	C513.392944,651.085876 513.661072,651.111084 513.969116,651.550903 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M509.977173,649.571838 
	C510.042725,649.773315 509.658478,649.880066 509.658478,649.880066 
	C509.658478,649.880066 509.293793,649.719482 509.219299,649.537476 
	C509.405060,649.127014 509.660675,649.132019 509.977173,649.571838 
z"/>
<path fill="#E7B4B1" opacity="1.000000" stroke="none" 
	d="
M448.984314,850.005737 
	C449.069275,850.506470 448.882660,850.786377 448.449493,851.145386 
	C448.060638,851.054993 447.918365,850.885437 447.716614,850.334839 
	C447.841034,849.763794 448.010590,849.561768 448.165833,849.347778 
	C448.348114,849.493469 448.530426,849.639221 448.984314,850.005737 
z"/>
<path fill="#FCF8F7" opacity="1.000000" stroke="none" 
	d="
M643.787415,209.008118 
	C643.781555,217.492828 643.794250,225.479324 643.753418,233.465561 
	C643.745789,234.953491 643.775635,236.526306 643.308594,237.896484 
	C643.012695,238.764755 641.829224,239.330475 641.043884,240.031921 
	C640.362488,239.233826 639.162354,238.479538 639.090637,237.629974 
	C638.797485,234.157471 638.790833,230.655960 638.784607,227.164948 
	C638.766541,217.015823 638.710938,206.864868 638.910950,196.719391 
	C638.939941,195.247208 640.289062,193.801025 641.027344,192.342773 
	C641.896973,193.740082 643.373535,195.077164 643.519287,196.546280 
	C643.911926,200.504028 643.737061,204.518112 643.787415,209.008118 
z"/>
<path fill="#FCF8F7" opacity="1.000000" stroke="none" 
	d="
M303.029785,193.366898 
	C305.856232,191.270325 307.003479,192.759842 307.160095,195.127426 
	C307.387421,198.564529 307.218384,202.027878 307.218384,205.867218 
	C305.805817,205.956421 304.423523,206.043716 302.821075,206.144913 
	C302.821075,201.787567 302.821075,197.742615 303.029785,193.366898 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M492.983215,816.313721 
	C492.245911,816.816956 491.517517,816.942993 490.231903,816.794373 
	C487.369202,813.977966 485.063721,811.436218 482.758209,808.894470 
	C482.954346,808.349609 483.150513,807.804688 483.905640,807.090820 
	C484.591003,806.260315 484.717407,805.598755 484.843842,804.937195 
	C486.168121,801.864746 487.492401,798.792297 489.249054,795.312378 
	C491.987091,792.520081 494.292755,790.135315 496.750397,787.593445 
	C495.602417,786.954224 494.622101,786.408386 493.641754,785.862549 
	C493.732880,784.829529 493.824005,783.796448 494.332001,782.320679 
	C495.435638,780.115173 496.122375,778.352417 496.809143,776.589600 
	C496.810303,775.937378 496.811493,775.285217 497.229645,774.256165 
	C497.725311,772.236511 497.803955,770.593689 497.882568,768.950867 
	C497.898743,768.515869 497.914917,768.080872 498.371429,767.312195 
	C502.310364,765.482056 501.468964,763.267578 499.814270,760.918579 
	C501.512634,748.903564 503.211029,736.888550 505.286072,724.388794 
	C505.466309,722.936462 505.269836,721.968994 505.073364,721.001465 
	C505.061218,719.931885 505.049042,718.862244 505.446503,717.311890 
	C505.832825,714.857849 505.809509,712.884521 505.786194,710.911194 
	C505.843964,693.252441 505.901703,675.593628 506.007385,657.468201 
	C509.811188,657.529053 512.882812,653.878479 517.413330,656.268066 
	C520.674011,657.987915 522.274109,658.829346 521.078064,662.449829 
	C520.899963,662.988831 521.275085,663.710571 521.451477,664.653259 
	C523.612854,664.844971 525.724243,665.032288 528.188660,665.250916 
	C528.638428,666.618591 529.128479,668.108521 529.692932,669.824890 
	C531.187012,669.590271 532.605347,669.367554 534.371826,669.090149 
	C534.458008,670.320190 534.532654,671.385864 534.627197,672.735413 
	C536.382996,672.871887 538.206482,672.843628 539.957153,673.186768 
	C543.110962,673.805054 545.403137,674.995117 545.151306,679.093811 
	C545.015015,681.311951 546.429932,683.625488 546.363586,685.854736 
	C546.698730,689.805664 547.033875,693.756592 547.369019,697.707581 
	C547.954895,697.827271 548.540771,697.947021 549.126648,698.066772 
	C549.755798,696.103821 550.145264,694.014099 551.094421,692.220459 
	C551.781189,690.922791 553.210876,690.018250 554.309265,688.938416 
	C554.676758,689.318176 555.044312,689.697937 555.411865,690.077698 
	C554.942749,691.893555 554.267761,693.685486 554.052429,695.530945 
	C553.725708,698.331970 554.093872,700.349487 557.730713,701.380737 
	C559.868225,701.986938 562.067749,704.991577 562.796631,707.361145 
	C564.022400,711.346497 564.426453,715.676208 564.541382,719.878479 
	C564.615356,722.585327 563.406189,725.303101 563.033752,728.051392 
	C562.289124,733.546326 561.540161,739.052307 561.143860,744.578979 
	C560.827393,748.992249 561.032593,753.442810 561.001160,757.876831 
	C560.997620,758.375732 561.141479,758.985352 560.912964,759.356506 
	C557.394714,765.071228 553.337036,770.445129 550.577576,776.651245 
	C550.162170,777.585388 547.474670,777.509216 544.520203,778.215393 
	C546.073181,779.873962 547.435486,781.328857 549.064270,783.068298 
	C546.549866,784.278992 544.448975,785.780457 542.130188,786.289368 
	C538.857544,787.007568 537.785828,788.929871 536.850525,791.857300 
	C536.286926,793.621399 534.129272,794.876282 532.177795,796.885071 
	C531.532898,793.911804 531.155212,792.170471 530.777466,790.429138 
	C530.294800,790.444092 529.812134,790.458984 529.329468,790.473938 
	C529.219543,792.776306 528.933655,795.087280 529.045105,797.378906 
	C529.179565,800.145386 528.074646,801.413208 525.445923,802.438721 
	C521.205322,804.092896 517.072144,806.173767 513.189392,808.556458 
	C508.128876,811.661682 502.539368,811.688843 500.283081,807.867981 
	C503.086365,806.780212 505.872498,805.699036 509.008514,804.482056 
	C503.029633,800.403809 494.088989,807.299805 492.983215,816.313721 
M518.996216,712.841919 
	C518.996216,716.023010 518.996216,719.204102 518.996216,722.385193 
	C519.665710,722.442017 520.335266,722.498901 521.004761,722.555786 
	C521.573486,718.616516 522.217834,714.684570 522.602478,710.727417 
	C522.644165,710.297974 521.184875,709.722595 520.421875,709.214905 
	C519.947998,710.131531 519.474121,711.048218 518.996216,712.841919 
M530.549133,788.464783 
	C532.529907,787.504761 536.446472,791.001831 536.826782,785.901917 
	C531.139954,784.253967 530.076355,784.630798 530.549133,788.464783 
M515.538818,666.093323 
	C514.716431,666.444580 513.894043,666.795837 513.071655,667.147095 
	C513.829590,668.045532 514.587463,668.943909 515.345398,669.842346 
	C515.576965,668.762390 515.808472,667.682373 515.538818,666.093323 
M550.510498,700.199097 
	C550.134888,701.194763 549.759277,702.190430 549.383606,703.186096 
	C549.946228,703.343567 550.508911,703.500977 551.071533,703.658386 
	C551.120911,702.565308 551.170349,701.472229 550.510498,700.199097 
M551.526428,770.314514 
	C550.979004,769.975586 550.431641,769.636719 549.884216,769.297791 
	C549.749207,769.572144 549.614197,769.846497 549.479187,770.120850 
	C550.015259,770.325989 550.551392,770.531128 551.526428,770.314514 
z"/>
<path fill="#5A6F81" opacity="1.000000" stroke="none" 
	d="
M535.054443,344.406250 
	C527.368774,349.452362 518.364136,352.746490 513.039062,360.931213 
	C506.558167,370.892273 504.281158,382.237091 504.209229,393.690826 
	C503.641449,484.078400 503.423950,574.468140 503.100952,664.857239 
	C503.050385,679.010071 503.295624,693.171753 502.859589,707.312500 
	C502.600067,715.728577 501.277771,724.112000 499.806030,732.444336 
	C499.018677,730.228882 498.858551,728.079163 498.782166,725.541260 
	C498.818451,724.098694 498.771088,723.044495 499.056641,721.728638 
	C499.575226,716.974792 499.760895,712.482666 500.237335,707.746094 
	C500.603516,705.972290 500.678955,704.442932 500.800629,702.453003 
	C500.877350,697.993225 500.933289,693.994019 500.934570,689.994812 
	C500.964996,596.335815 500.989532,502.676788 501.195801,408.934448 
	C501.239197,408.584564 501.101868,408.318024 500.943604,407.586487 
	C501.167236,401.729706 501.411896,396.337891 501.726440,390.525177 
	C501.837341,388.390289 501.878357,386.676361 502.225067,384.829102 
	C503.583527,379.994965 504.781921,375.321106 505.659241,370.587769 
	C507.592957,360.154572 515.378601,354.288849 523.095398,348.607697 
	C526.564453,346.053741 530.999756,344.812256 535.017212,343.332764 
	C535.043396,343.928925 535.048889,344.167572 535.054443,344.406250 
z"/>
<path fill="#123552" opacity="1.000000" stroke="none" 
	d="
M534.996643,342.975281 
	C530.999756,344.812256 526.564453,346.053741 523.095398,348.607697 
	C515.378601,354.288849 507.592957,360.154572 505.659241,370.587769 
	C504.781921,375.321106 503.583527,379.994965 501.946899,384.540100 
	C498.621155,371.346405 510.430695,351.256897 519.424316,347.841858 
	C519.424316,343.293732 519.424316,338.506836 519.424316,332.988708 
	C518.479126,332.988708 516.381897,333.487854 514.716553,332.853333 
	C512.922852,332.169861 511.533905,330.424194 510.377136,329.027679 
	C517.818909,328.777802 524.851868,328.642151 531.939209,328.870514 
	C533.355774,329.816162 534.714233,330.892670 536.080566,330.902710 
	C549.400513,331.000977 562.721802,330.920990 576.067749,330.903748 
	C576.092773,330.915741 576.107849,330.969208 576.071533,331.384521 
	C575.685974,334.160492 575.336792,336.521210 574.521362,338.921509 
	C567.050354,339.008148 560.045654,339.055176 552.842285,338.831055 
	C551.597107,338.598358 550.550415,338.636749 549.503784,338.675140 
	C549.895386,339.298523 550.286987,339.921906 550.678589,340.545258 
	C549.706848,340.883118 548.735107,341.220978 547.346558,341.189270 
	C545.618713,340.575043 544.307556,340.330353 542.792419,339.818359 
	C540.057800,340.692505 537.527222,341.833923 534.996643,342.975281 
z"/>
<path fill="#5A6F81" opacity="1.000000" stroke="none" 
	d="
M472.038208,820.718018 
	C471.249207,821.699524 470.469757,822.319885 469.327271,822.641968 
	C469.978760,819.912109 470.993286,817.480469 472.320709,814.890625 
	C474.115021,811.835266 475.596466,808.937988 477.130920,805.758911 
	C477.197968,805.095642 477.211975,804.714111 477.549133,804.216858 
	C478.941803,802.410767 480.011353,800.720337 481.153503,798.692078 
	C481.502380,797.244263 481.778687,796.134277 482.364075,794.861816 
	C484.266876,790.922729 486.215179,787.252075 487.380646,783.347778 
	C489.827179,775.151733 491.882385,766.838196 494.059875,758.562805 
	C495.882477,751.636169 497.650482,744.695129 499.442627,737.760498 
	C499.227325,741.846497 499.430267,746.005920 498.728516,750.006592 
	C494.320099,775.139343 486.591278,799.050720 472.038208,820.718018 
z"/>
<path fill="#828C93" opacity="1.000000" stroke="none" 
	d="
M531.884827,328.506500 
	C524.851868,328.642151 517.818909,328.777802 509.973480,328.934326 
	C506.728668,328.974091 504.296265,328.993011 501.475281,328.742249 
	C493.745331,328.532227 486.403961,328.591888 478.634827,328.446228 
	C474.482361,328.160614 470.757660,328.080292 466.726746,327.933868 
	C465.920105,327.293457 465.419678,326.719086 464.919250,326.144714 
	C487.529694,326.091095 510.140076,326.029022 532.750549,325.987152 
	C545.720398,325.963135 558.690430,325.937988 571.659912,326.006287 
	C573.395691,326.015442 575.128601,326.571625 576.703979,327.389771 
	C576.325195,327.980286 576.105347,328.055542 575.430054,328.099274 
	C566.731140,328.064453 558.487122,328.004150 550.244263,328.076721 
	C544.123657,328.130615 538.004578,328.356964 531.884827,328.506500 
z"/>
<path fill="#123552" opacity="1.000000" stroke="none" 
	d="
M499.537537,737.368774 
	C497.650482,744.695129 495.882477,751.636169 494.059875,758.562805 
	C491.882385,766.838196 489.827179,775.151733 487.380646,783.347778 
	C486.215179,787.252075 484.266876,790.922729 482.077759,794.636108 
	C481.483429,793.468079 481.156738,792.231323 481.539246,791.279663 
	C483.890991,785.428040 487.444519,779.848083 488.650330,773.790100 
	C491.371918,760.117188 492.927338,746.213623 495.036530,732.416077 
	C495.427185,729.860840 494.538544,726.422241 498.698456,725.929504 
	C498.858551,728.079163 499.018677,730.228882 499.448090,732.653320 
	C499.689087,734.277771 499.660797,735.627380 499.537537,737.368774 
z"/>
<path fill="#FCF8F7" opacity="1.000000" stroke="none" 
	d="
M482.470581,809.068237 
	C485.063721,811.436218 487.369202,813.977966 489.861450,816.804382 
	C490.048218,817.088989 489.960022,817.434814 489.863708,817.584412 
	C485.380035,818.466064 480.992676,819.198181 476.156311,819.944580 
	C477.865814,816.386475 480.024384,812.814209 482.470581,809.068237 
z"/>
<path fill="#5A6F81" opacity="1.000000" stroke="none" 
	d="
M464.458740,326.114990 
	C465.419678,326.719086 465.920105,327.293457 466.739807,328.236969 
	C467.071655,328.808014 467.084290,329.009857 466.620544,329.155518 
	C457.775024,329.249756 449.405945,329.400177 441.038605,329.199280 
	C441.015411,328.379242 440.990448,327.910522 440.965515,327.441803 
	C442.372772,326.972809 443.772186,326.129303 445.188751,326.099365 
	C451.455841,325.966980 457.727936,326.068420 464.458740,326.114990 
z"/>
<path fill="#3A3A3A" opacity="1.000000" stroke="none" 
	d="
M440.500916,327.483917 
	C440.990448,327.910522 441.015411,328.379242 441.018127,329.553162 
	C440.977997,330.491821 440.960114,330.725281 440.477570,330.953735 
	C434.005737,330.957855 427.998505,330.966980 421.570892,330.968109 
	C419.751892,330.105804 418.353333,329.251526 416.992859,328.119568 
	C417.253723,327.539032 417.476440,327.236084 418.107025,327.217041 
	C419.790314,327.343536 421.065704,327.186157 422.341095,327.028748 
	C428.239502,327.194519 434.137909,327.360291 440.500916,327.483917 
z"/>
<path fill="#5A6F81" opacity="1.000000" stroke="none" 
	d="
M553.040894,339.102203 
	C560.045654,339.055176 567.050354,339.008148 574.532959,339.199799 
	C575.010864,339.623474 575.010803,339.808472 574.792969,340.147217 
	C574.342163,340.761078 574.109009,341.221252 573.875793,341.681427 
	C567.151428,341.584625 560.427002,341.487823 553.327454,340.865540 
	C552.981812,339.927429 553.011353,339.514801 553.040894,339.102203 
z"/>
<path fill="#FCF8F7" opacity="1.000000" stroke="none" 
	d="
M493.380066,786.060913 
	C494.622101,786.408386 495.602417,786.954224 496.750397,787.593445 
	C494.292755,790.135315 491.987091,792.520081 489.345947,794.950684 
	C490.379791,792.084106 491.749084,789.171692 493.380066,786.060913 
z"/>
<path fill="#5A6F81" opacity="1.000000" stroke="none" 
	d="
M575.885498,328.130798 
	C576.105347,328.055542 576.325195,327.980286 576.861328,327.756165 
	C577.729126,327.858765 578.280640,328.110260 578.988647,328.933899 
	C579.545959,330.000732 579.946899,330.495453 580.347900,330.990143 
	C580.321045,333.038391 580.294189,335.086639 579.836548,337.688904 
	C579.130676,338.349945 578.855652,338.456940 578.592712,338.536804 
	C578.604858,338.509674 578.553711,338.505646 578.411804,338.070007 
	C577.549194,335.412659 576.828552,333.190918 576.107849,330.969208 
	C576.107849,330.969208 576.092773,330.915741 576.073486,330.558289 
	C575.997986,329.510803 575.941772,328.820801 575.885498,328.130798 
z"/>
<path fill="#2C4761" opacity="1.000000" stroke="none" 
	d="
M535.017212,343.332794 
	C537.527222,341.833923 540.057800,340.692505 542.582153,340.066833 
	C541.732910,341.408020 540.889893,342.233459 540.046875,343.058899 
	C538.627075,343.458496 537.207214,343.858093 535.420898,344.331970 
	C535.048889,344.167572 535.043396,343.928925 535.017212,343.332794 
z"/>
<path fill="#2C4761" opacity="1.000000" stroke="none" 
	d="
M576.071533,331.384521 
	C576.828552,333.190918 577.549194,335.412659 578.390381,338.109650 
	C578.510864,338.584930 578.605469,338.574615 578.427795,338.922363 
	C578.206482,339.526764 578.162781,339.783417 578.119080,340.040070 
	C577.955811,340.093994 577.792480,340.147949 577.053467,340.154114 
	C575.988647,340.068726 575.499695,340.031128 575.010681,339.993530 
	C575.010803,339.808472 575.010864,339.623474 574.999207,339.160217 
	C575.336792,336.521210 575.685974,334.160492 576.071533,331.384521 
z"/>
<path fill="#5A6F81" opacity="1.000000" stroke="none" 
	d="
M540.385864,343.102051 
	C540.889893,342.233459 541.732910,341.408020 542.786255,340.334106 
	C544.307556,340.330353 545.618713,340.575043 547.000854,341.141785 
	C544.956299,342.024323 542.840576,342.584778 540.385864,343.102051 
z"/>
<path fill="#828C93" opacity="1.000000" stroke="none" 
	d="
M422.289429,326.774719 
	C421.065704,327.186157 419.790314,327.343536 418.145294,326.971680 
	C417.962372,325.752350 418.149109,325.062347 418.335815,324.372314 
	C419.227539,324.357880 420.119293,324.343414 421.333374,324.393890 
	C421.849762,325.146088 422.043762,325.833374 422.289429,326.774719 
z"/>
<path fill="#FCF8F7" opacity="1.000000" stroke="none" 
	d="
M499.554504,761.161499 
	C501.468964,763.267578 502.310364,765.482056 498.424255,766.989868 
	C498.456116,765.135620 498.875427,763.270020 499.554504,761.161499 
z"/>
<path fill="#FCF8F7" opacity="1.000000" stroke="none" 
	d="
M505.553101,711.216492 
	C505.809509,712.884521 505.832825,714.857849 505.506287,716.914612 
	C505.210999,715.172607 505.265533,713.347229 505.553101,711.216492 
z"/>
<path fill="#2C4761" opacity="1.000000" stroke="none" 
	d="
M552.842285,338.831055 
	C553.011353,339.514801 552.981812,339.927429 552.966553,340.650238 
	C552.452942,340.944366 551.925110,340.928284 551.037964,340.728760 
	C550.286987,339.921906 549.895386,339.298523 549.503784,338.675140 
	C550.550415,338.636749 551.597107,338.598358 552.842285,338.831055 
z"/>
<path fill="#FCF8F7" opacity="1.000000" stroke="none" 
	d="
M497.618561,769.215027 
	C497.803955,770.593689 497.725311,772.236511 497.278564,773.933228 
	C497.058472,772.484436 497.206512,770.981812 497.618561,769.215027 
z"/>
<path fill="#FCF8F7" opacity="1.000000" stroke="none" 
	d="
M496.480438,776.733948 
	C496.122375,778.352417 495.435638,780.115173 494.400635,781.937561 
	C494.752197,780.290833 495.451996,778.584595 496.480438,776.733948 
z"/>
<path fill="#FCF8F7" opacity="1.000000" stroke="none" 
	d="
M505.014099,721.372742 
	C505.269836,721.968994 505.466309,722.936462 505.329559,723.949585 
	C504.982513,723.244751 504.968658,722.494385 505.014099,721.372742 
z"/>
<path fill="#828C93" opacity="1.000000" stroke="none" 
	d="
M574.792969,340.147217 
	C575.499695,340.031128 575.988647,340.068726 576.816772,340.256622 
	C576.288147,340.898743 575.420410,341.390594 574.214233,341.781921 
	C574.109009,341.221252 574.342163,340.761078 574.792969,340.147217 
z"/>
<path fill="#828C93" opacity="1.000000" stroke="none" 
	d="
M578.432861,340.073853 
	C578.162781,339.783417 578.206482,339.526764 578.424438,338.939087 
	C578.598816,338.608093 578.580566,338.563965 578.580566,338.563965 
	C578.855652,338.456940 579.130676,338.349945 579.834961,338.128418 
	C579.758301,338.711823 579.252441,339.409729 578.432861,340.073853 
z"/>
<path fill="#828C93" opacity="1.000000" stroke="none" 
	d="
M580.518311,330.786011 
	C579.946899,330.495453 579.545959,330.000732 579.189392,329.162109 
	C579.718811,329.406067 580.203796,329.993958 580.518311,330.786011 
z"/>
<path fill="#FCF8F7" opacity="1.000000" stroke="none" 
	d="
M484.616638,805.116577 
	C484.717407,805.598755 484.591003,806.260315 484.145203,806.919800 
	C484.013672,806.377075 484.201538,805.836548 484.616638,805.116577 
z"/>
<path fill="#081926" opacity="1.000000" stroke="none" 
	d="
M421.991302,330.976135 
	C427.998505,330.966980 434.005737,330.957855 440.797485,331.190979 
	C443.195129,331.954987 444.791229,332.852966 446.424530,332.926666 
	C451.009827,333.133606 455.617859,332.857056 460.200287,333.093445 
	C461.497009,333.160339 462.736633,334.333984 463.585541,335.000153 
	C461.779205,335.001648 460.389801,335.002991 458.544434,335.002686 
	C454.876434,335.001038 451.664398,335.001038 448.316498,335.001038 
	C449.435638,337.978882 450.814819,340.034088 450.901001,342.142151 
	C451.430115,355.083038 451.681824,368.035553 451.980011,380.985229 
	C452.014099,382.464417 452.344360,384.518738 451.545166,385.335083 
	C447.973267,388.983337 449.080963,393.404907 449.031036,397.606415 
	C448.928162,406.266327 449.021881,414.928436 448.985352,423.589417 
	C448.976959,425.574554 449.447479,427.974823 448.531281,429.468811 
	C445.217346,434.872559 446.137482,440.710876 445.994995,446.472870 
	C445.929321,449.127960 445.928345,451.794250 445.670929,454.433319 
	C444.837646,462.976288 443.313568,471.497284 443.143463,480.048431 
	C442.909241,491.820068 444.064636,503.617218 443.894562,515.392883 
	C443.708008,528.305481 442.794769,541.212524 441.992401,554.109131 
	C441.592682,560.534363 440.242493,566.927002 440.190948,573.341370 
	C440.102356,584.368103 441.045990,595.405334 440.890198,606.428711 
	C440.762360,615.476501 439.150299,624.507263 439.091644,633.551453 
	C438.865906,668.361145 439.195801,703.174805 438.893494,737.983337 
	C438.788055,750.121582 438.120880,762.215515 439.899048,774.304871 
	C440.293701,776.987976 440.218109,780.268005 439.002411,782.550659 
	C436.455017,787.333801 435.860870,792.186768 435.918365,797.435913 
	C435.968994,802.052368 435.476685,806.694763 434.907562,811.286438 
	C434.725006,812.759521 433.884888,814.600220 432.736816,815.419617 
	C429.402954,817.798767 428.710724,819.562561 432.736115,821.973145 
	C435.985626,823.919006 435.806854,826.436951 432.819885,828.974915 
	C432.047760,829.631042 431.022614,830.750671 431.116150,831.538391 
	C431.647278,836.011108 432.482513,840.447693 433.195984,844.785339 
	C435.916840,844.521179 437.702179,844.347778 439.487488,844.174438 
	C439.638855,844.583069 439.790192,844.991638 439.941528,845.400269 
	C438.787109,846.476685 437.532654,848.560242 436.495392,848.457214 
	C431.703674,847.981201 432.003815,851.002930 432.025696,854.029541 
	C432.000000,854.004883 432.051605,854.049438 431.705475,854.063843 
	C430.569214,855.052185 429.779114,856.026062 428.780487,857.116333 
	C428.311279,857.430847 428.121643,857.682556 427.595551,857.990356 
	C425.035370,857.968201 422.677399,857.662903 422.834625,861.357239 
	C416.939728,864.112061 411.215607,866.528198 405.519897,869.009705 
	C403.442261,869.914795 401.527740,871.378052 399.373322,871.889038 
	C392.954041,873.411865 386.452667,874.588501 380.050293,875.561707 
	C381.782227,874.291382 383.381317,873.207642 385.123047,872.453369 
	C395.004974,868.174500 396.322968,862.655640 389.303558,854.884216 
	C388.942780,854.484985 389.097809,853.619751 389.304626,852.946899 
	C390.065826,852.627441 390.535065,852.332520 391.312805,852.061035 
	C392.030640,852.134216 392.439972,852.183960 392.980408,852.586304 
	C396.694855,855.176453 399.890411,853.950195 403.286194,851.815308 
	C403.982880,851.742554 404.379669,851.688049 405.095337,851.735474 
	C405.982635,851.565918 406.550964,851.294373 407.497375,851.017212 
	C409.905029,849.648926 411.934601,848.286194 413.975342,846.602600 
	C413.984833,845.853210 413.983185,845.424683 414.186401,844.858032 
	C414.640686,844.493347 414.821655,844.221191 415.309937,843.925232 
	C416.377502,842.937927 417.069305,841.928833 417.812866,840.898987 
	C417.864655,840.878235 417.938782,840.961670 418.304382,840.972229 
	C419.431396,840.289124 420.192841,839.595459 420.954224,838.586548 
	C420.979553,837.851929 421.004974,837.432434 421.388550,837.003296 
	C422.472137,836.279907 423.197571,835.566284 423.888885,834.537048 
	C423.789429,832.755615 423.724121,831.289917 423.705444,829.511597 
	C423.772034,828.778564 423.791962,828.358154 424.104736,827.961304 
	C424.593475,827.988953 424.789337,827.992981 425.165009,828.228821 
	C425.872498,827.927368 426.400146,827.394104 426.925781,826.503662 
	C426.948395,825.432617 426.972961,824.718750 427.295898,823.965942 
	C427.917389,823.522644 428.240570,823.118164 428.563721,822.713684 
	C428.051605,822.473389 427.539520,822.233093 427.008057,821.626465 
	C426.898163,820.779358 426.807617,820.298645 426.752625,819.435120 
	C426.821991,818.027344 426.855865,817.002319 427.128662,815.888916 
	C427.569092,815.533630 427.770630,815.266846 428.344147,814.930237 
	C429.810730,811.210510 431.784760,807.576416 431.851196,803.907837 
	C432.178680,785.825012 432.001068,767.733093 432.000854,749.644348 
	C432.000458,714.681763 432.009369,679.719177 431.976807,644.756592 
	C431.974609,642.402527 432.805664,639.449951 428.983917,638.596191 
	C428.980377,637.133118 428.986847,636.069031 429.382385,634.951660 
	C430.312561,634.542297 431.314545,634.199402 431.325165,633.828125 
	C431.472992,628.655273 431.549744,623.476990 431.419037,618.305664 
	C431.407471,617.847778 429.827484,617.429626 428.970642,616.565552 
	C428.977722,614.427856 428.990082,612.717590 429.336700,610.955566 
	C429.444977,609.932556 429.218994,608.961426 428.982239,607.524109 
	C428.987579,602.041931 429.003601,597.025818 429.433197,591.990234 
	C430.560028,591.073425 431.875427,590.187561 431.892731,589.277039 
	C432.053375,580.825867 432.088196,572.367798 431.862915,563.919739 
	C431.836212,562.918274 430.031708,561.964294 429.034241,560.522522 
	C429.016876,555.040222 429.016571,550.023804 429.360107,545.000000 
	C430.305664,544.628052 430.907440,544.263428 431.509216,543.898804 
	C430.677643,543.595947 429.846069,543.293091 429.006897,542.563354 
	C429.006714,540.428650 429.014130,538.720764 429.315460,536.977234 
	C429.716522,536.284729 429.823669,535.627930 429.937073,534.575623 
	C429.946869,533.125427 429.950378,532.070740 430.246826,530.803833 
	C431.503723,528.327148 434.348907,525.553589 429.008698,524.523865 
	C428.996704,518.706360 429.000214,513.355652 429.411621,507.988647 
	C433.849854,506.846069 431.515930,503.595215 431.810547,501.334808 
	C431.894836,500.688049 429.994141,499.782623 428.992188,498.527527 
	C428.975464,496.230682 428.965881,494.402161 428.965607,492.573639 
	C428.961517,463.649628 428.998871,434.725403 428.850189,405.802155 
	C428.841766,404.158051 427.311707,402.521790 426.489838,400.881866 
	C426.355377,400.165924 426.220917,399.450012 426.397461,398.146240 
	C426.822815,396.036407 426.937073,394.514374 427.326660,392.710876 
	C427.342468,389.622711 427.082947,386.815948 426.839905,383.653656 
	C426.825073,382.587585 426.793732,381.877106 426.846863,380.741638 
	C425.886322,377.216797 424.841309,374.116943 423.788147,370.716980 
	C423.736847,370.019287 423.693695,369.621735 423.724854,368.854187 
	C422.883636,366.980682 421.968140,365.477203 420.999084,363.760101 
	C420.945496,363.546509 420.845367,363.117615 420.874390,362.767334 
	C420.251282,360.948212 419.599152,359.479370 419.331085,358.009766 
	C421.664032,359.313141 423.612976,360.617340 426.520721,362.563141 
	C425.510315,353.412140 424.516052,344.407776 423.499695,335.202972 
	C425.233032,335.058960 427.832123,334.842957 430.431183,334.626984 
	C430.412659,334.085510 430.394135,333.544006 430.375641,333.002502 
	C428.682526,333.002502 426.937042,333.255157 425.313751,332.918488 
	C424.123199,332.671539 423.092987,331.651581 421.991302,330.976135 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M429.016266,545.007324 
	C429.016571,550.023804 429.016876,555.040222 429.007812,560.991943 
	C429.005463,571.954651 429.012543,581.982239 429.019623,592.009766 
	C429.003601,597.025818 428.987579,602.041931 428.969574,607.901245 
	C428.979248,609.498840 428.990845,610.253052 429.002441,611.007324 
	C428.990082,612.717590 428.977722,614.427856 428.814819,616.801819 
	C427.971863,617.670105 427.279419,617.874695 426.586975,618.079285 
	C426.213562,611.147400 425.559967,604.217102 425.519257,597.283264 
	C425.397278,576.515991 425.484711,555.747192 425.545685,534.979126 
	C425.653625,498.220886 425.801788,461.462799 425.934387,424.704620 
	C425.962006,417.051300 425.995178,409.397949 426.257812,401.313232 
	C427.311707,402.521790 428.841766,404.158051 428.850189,405.802155 
	C428.998871,434.725403 428.961517,463.649628 428.965607,492.573639 
	C428.965881,494.402161 428.975464,496.230682 428.977814,498.977844 
	C428.984497,502.599274 428.994080,505.302094 429.003723,508.004883 
	C429.000214,513.355652 428.996704,518.706360 428.991394,524.928711 
	C429.310974,527.538879 429.632446,529.277466 429.953918,531.015991 
	C429.950378,532.070740 429.946869,533.125427 429.699158,534.752808 
	C429.310486,535.887939 429.166016,536.450439 429.021545,537.012878 
	C429.014130,538.720764 429.006714,540.428650 428.986694,542.899414 
	C428.988129,544.110657 429.002197,544.559021 429.016266,545.007324 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M428.993286,635.004883 
	C428.986847,636.069031 428.980377,637.133118 428.971191,639.065308 
	C428.969147,672.052368 428.970184,704.171387 428.970367,736.290466 
	C428.970490,757.259338 428.996674,778.228394 428.921570,799.196960 
	C428.914886,801.064270 430.112915,803.885620 426.471741,804.071533 
	C426.293732,802.276245 425.963074,800.481140 425.960754,798.685669 
	C425.928925,774.090637 426.001556,749.495483 425.929230,724.900696 
	C425.845612,696.468201 425.609375,668.036194 425.526672,639.603638 
	C425.512177,634.628052 425.927765,629.651306 426.739502,624.315796 
	C427.887299,627.639282 428.440308,631.322083 428.993286,635.004883 
z"/>
<path fill="#0A1E30" opacity="1.000000" stroke="none" 
	d="
M426.317261,804.414490 
	C430.112915,803.885620 428.914886,801.064270 428.921570,799.196960 
	C428.996674,778.228394 428.970490,757.259338 428.970367,736.290466 
	C428.970184,704.171387 428.969147,672.052368 428.981171,639.464233 
	C432.805664,639.449951 431.974609,642.402527 431.976807,644.756592 
	C432.009369,679.719177 432.000458,714.681763 432.000854,749.644348 
	C432.001068,767.733093 432.178680,785.825012 431.851196,803.907837 
	C431.784760,807.576416 429.810730,811.210510 428.336639,814.594727 
	C427.638123,813.557556 427.319061,812.786011 427.003174,811.622925 
	C426.680511,809.821350 426.354706,808.411377 426.028931,807.001404 
	C426.073578,806.253418 426.118195,805.505432 426.317261,804.414490 
z"/>
<path fill="#5D666D" opacity="1.000000" stroke="none" 
	d="
M425.710999,807.220581 
	C426.354706,808.411377 426.680511,809.821350 426.750275,811.829346 
	C426.626068,813.610718 426.757904,814.794006 426.889771,815.977356 
	C426.855865,817.002319 426.821991,818.027344 426.437073,819.553223 
	C425.327972,822.682007 424.569916,825.309875 423.811859,827.937683 
	C423.791962,828.358154 423.772034,828.778564 423.406250,829.629761 
	C422.370697,831.692505 421.680939,833.324585 420.944824,835.185608 
	C420.898438,835.414551 420.778381,835.865173 420.451355,835.924866 
	C419.447052,837.000793 418.769745,838.017029 418.036102,839.351929 
	C417.966125,840.101013 417.952454,840.531311 417.938782,840.961670 
	C417.938782,840.961670 417.864655,840.878235 417.537628,841.036987 
	C416.451782,842.098267 415.692963,843.000916 414.934143,843.903503 
	C414.821655,844.221191 414.640686,844.493347 413.906464,844.864441 
	C412.847443,845.649231 412.273224,846.289429 411.534668,847.190002 
	C409.934448,848.334106 408.498535,849.217957 406.753357,850.096741 
	C405.888184,850.605652 405.332336,851.119568 404.776428,851.633545 
	C404.379669,851.688049 403.982880,851.742554 403.017578,851.598816 
	C399.249115,851.678223 396.049225,851.955933 392.849304,852.233765 
	C392.439972,852.183960 392.030640,852.134216 391.105011,851.783325 
	C388.043335,850.338684 385.497986,849.195251 382.955200,848.043579 
	C382.957764,848.035339 382.945404,848.049561 382.842651,847.729492 
	C374.863159,840.243774 371.604065,830.328369 367.206757,821.117249 
	C364.657074,815.776367 362.520966,810.237915 360.198730,804.788391 
	C361.162201,805.578918 362.125671,806.369385 363.352234,807.340332 
	C365.274261,811.250854 366.774963,815.061523 368.624847,818.694458 
	C372.539795,826.382812 375.833862,834.559814 380.842865,841.479248 
	C388.879272,852.580750 398.157990,853.469299 409.011292,844.558044 
	C414.696075,839.890442 418.953949,834.049805 421.020203,826.145874 
	C421.253998,824.953491 421.400513,824.404358 421.546997,823.855286 
	C421.629120,823.457642 421.711243,823.059937 421.762268,822.358643 
	C422.951782,817.183167 424.172394,812.311462 425.710999,807.220581 
z"/>
<path fill="#5D666D" opacity="1.000000" stroke="none" 
	d="
M423.650574,369.224182 
	C423.693695,369.621735 423.736847,370.019287 423.576233,370.963013 
	C424.502441,374.728302 425.632416,377.947449 426.762390,381.166595 
	C426.793732,381.877106 426.825073,382.587585 426.587708,383.946869 
	C426.563141,387.394531 426.807251,390.193451 427.051361,392.992340 
	C426.937073,394.514374 426.822815,396.036407 426.401062,397.777527 
	C425.043335,392.441071 423.845734,386.908508 422.975494,381.324890 
	C421.473846,371.689697 418.887390,362.662506 410.264526,356.408386 
	C404.213501,353.301208 398.483704,350.229736 392.398010,346.660095 
	C391.352966,346.042572 390.663849,345.923218 389.974701,345.803864 
	C389.557129,345.767670 389.139557,345.731476 388.274414,345.459229 
	C387.596771,345.039398 387.334503,344.948700 387.039978,344.951019 
	C386.622925,344.896362 386.205872,344.841736 385.402283,344.445374 
	C384.340179,344.036652 383.664642,343.969696 382.989075,343.902710 
	C382.287476,343.834686 381.585876,343.766693 380.393494,343.300049 
	C378.822479,342.722015 377.742249,342.542664 376.661987,342.363281 
	C374.719543,342.289612 372.777130,342.215912 370.416718,341.738770 
	C370.006897,341.072601 370.015076,340.809906 370.344788,340.321777 
	C373.779144,340.112427 376.891937,340.128479 380.257690,340.403748 
	C382.695343,341.085602 384.879974,341.508270 387.369904,342.098633 
	C388.786377,342.521637 389.897583,342.776978 391.219574,343.332092 
	C394.844574,345.052429 398.243011,346.512604 401.677673,347.881927 
	C404.803406,349.128174 407.968475,350.275696 411.244781,351.461639 
	C411.373352,351.456543 411.500336,351.232697 411.568359,351.576569 
	C412.076202,352.738556 412.516022,353.556641 413.004028,354.698975 
	C415.649933,357.721344 418.247650,360.419464 420.845367,363.117615 
	C420.845367,363.117615 420.945496,363.546509 421.005005,364.116516 
	C421.926544,366.199097 422.788574,367.711639 423.650574,369.224182 
z"/>
<path fill="#0A1E30" opacity="1.000000" stroke="none" 
	d="
M429.382385,634.951660 
	C428.440308,631.322083 427.887299,627.639282 426.971680,623.920898 
	C426.494629,622.213135 426.380157,620.540894 426.426331,618.473999 
	C427.279419,617.874695 427.971863,617.670105 428.820129,617.229248 
	C429.827484,617.429626 431.407471,617.847778 431.419037,618.305664 
	C431.549744,623.476990 431.472992,628.655273 431.325165,633.828125 
	C431.314545,634.199402 430.312561,634.542297 429.382385,634.951660 
z"/>
<path fill="#FC0101" opacity="1.000000" stroke="none" 
	d="
M528.036255,353.015625 
	C532.802185,351.677368 537.469299,349.629211 542.300964,349.109192 
	C550.699219,348.205322 559.206238,348.360901 567.653625,347.843414 
	C572.258972,347.561310 576.825928,346.558563 581.429382,346.382477 
	C583.484741,346.303864 586.198730,346.826233 587.575073,348.153198 
	C603.782776,363.779205 617.236023,381.533447 626.431824,402.136993 
	C634.076660,419.265594 640.043213,436.960999 640.153687,456.105103 
	C640.211548,466.129852 640.365173,466.129120 630.319397,466.128937 
	C611.490662,466.128601 592.661316,466.044800 573.833740,466.192932 
	C569.719360,466.225281 567.723145,465.252106 567.847412,460.640961 
	C568.247742,445.780334 563.943420,432.063873 558.392639,418.351288 
	C554.124573,407.807465 547.856384,398.780334 540.441162,390.816223 
	C532.944641,382.764801 523.584351,376.448700 514.690369,369.082458 
	C516.718628,364.909393 519.678345,360.311676 525.146973,357.678833 
	C526.421143,357.065430 527.045593,355.102448 527.999756,353.408112 
	C528.030457,353.055786 528.011475,352.993622 528.036255,353.015625 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M379.983337,875.900391 
	C386.452667,874.588501 392.954041,873.411865 399.373322,871.889038 
	C401.527740,871.378052 403.442261,869.914795 405.519897,869.009705 
	C411.215607,866.528198 416.939728,864.112061 423.243469,861.351196 
	C424.647400,861.122620 425.460480,861.212952 426.273560,861.303284 
	C424.194153,862.852478 422.275787,864.707581 420.009033,865.900879 
	C405.747803,873.409241 390.333221,876.912048 374.172943,878.995483 
	C367.264587,879.300049 360.625641,879.272583 353.527222,879.284546 
	C347.626831,878.214355 342.174316,877.158752 336.747131,875.985840 
	C313.024323,870.858948 296.384430,856.811829 285.386414,835.141357 
	C285.024231,834.526611 284.948090,834.099243 285.200256,833.344238 
	C285.804626,833.012085 286.080750,833.007568 286.668518,833.042236 
	C288.284393,834.446655 289.588562,835.811951 290.896973,837.543884 
	C294.754608,848.021606 301.632416,855.545532 310.865051,861.446533 
	C311.632202,862.195557 312.303986,862.634705 313.173828,863.111816 
	C313.371948,863.149780 313.775024,863.133057 313.870422,863.439941 
	C314.633057,864.184082 315.300323,864.621399 316.158783,865.320679 
	C319.832886,866.760742 323.315765,867.938660 326.882690,869.428345 
	C327.622528,870.200439 328.278351,870.660828 329.223969,871.399475 
	C334.674744,872.791138 339.835754,873.904602 345.225098,875.294434 
	C346.637878,875.689453 347.822327,875.808167 349.449799,875.959839 
	C352.266296,876.012390 354.639771,876.031860 357.480713,876.071533 
	C365.293243,876.027954 372.638306,875.964172 379.983337,875.900391 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M336.374237,779.628906 
	C337.696503,780.014771 339.018799,780.400696 340.634552,781.290771 
	C341.029480,782.438110 341.130920,783.081299 341.169800,783.801636 
	C341.107269,783.878845 340.977112,784.028870 340.612061,784.009888 
	C339.516632,783.969421 338.786224,783.947937 338.030609,783.941467 
	C338.005402,783.956421 338.053894,783.941589 337.883820,783.616089 
	C333.518372,781.864990 329.400787,779.727722 325.100952,779.257568 
	C322.113312,778.930847 318.875061,780.896240 315.339905,781.840698 
	C313.296173,781.941284 311.661316,782.038818 309.706268,782.130859 
	C308.851868,782.707336 308.317657,783.289307 307.481506,783.876953 
	C306.781525,783.942322 306.383514,784.002014 305.588013,784.048950 
	C304.398926,783.962341 303.607269,783.888367 302.875153,783.453308 
	C304.625000,782.184875 306.255341,781.132874 308.018646,780.401611 
	C311.290161,779.044861 314.639374,777.875427 317.956238,776.627930 
	C322.014282,776.688782 326.072357,776.749695 330.588867,776.721802 
	C331.047333,776.633057 330.900208,776.932251 330.983337,777.428833 
	C332.835724,778.493225 334.604980,779.061035 336.374237,779.628906 
z"/>
<path fill="#828C93" opacity="1.000000" stroke="none" 
	d="
M286.356842,833.003052 
	C286.080750,833.007568 285.804626,833.012085 285.113647,833.029907 
	C283.919556,832.521851 282.593872,832.124268 282.443359,831.460205 
	C280.307556,822.038574 278.705048,812.593933 283.901001,803.363403 
	C284.642242,803.901367 284.829865,804.454529 284.998596,805.416992 
	C285.034882,806.912476 285.090057,807.998657 285.094727,809.403931 
	C285.045380,810.153625 285.046509,810.584167 284.726746,811.228760 
	C282.220276,818.194214 283.001709,824.702820 286.020844,831.365784 
	C286.140961,832.135925 286.248901,832.569458 286.356842,833.003052 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M288.967529,793.936401 
	C291.770721,790.726807 294.573883,787.517273 297.888824,784.401855 
	C298.976013,784.732117 299.551422,784.968262 300.097107,785.524963 
	C299.059601,786.965942 298.051849,788.086304 296.742004,789.288757 
	C295.948730,790.241333 295.457581,791.111877 294.746704,792.092651 
	C294.263916,792.419006 294.090698,792.692139 293.686798,793.055237 
	C292.907776,793.722534 292.449280,794.356812 291.724213,795.206604 
	C291.264160,796.544189 291.070709,797.666199 290.567169,798.886230 
	C289.014587,800.085510 287.772064,801.186890 286.423279,801.934204 
	C287.200470,799.032227 288.083984,796.484314 288.967529,793.936401 
z"/>
<path fill="#828C93" opacity="1.000000" stroke="none" 
	d="
M300.126831,785.204346 
	C299.551422,784.968262 298.976013,784.732117 298.190979,784.266174 
	C302.704651,781.709351 307.377197,779.269043 312.180206,777.120361 
	C313.751129,776.417542 315.661438,776.473267 317.686401,776.405090 
	C314.639374,777.875427 311.290161,779.044861 308.018646,780.401611 
	C306.255341,781.132874 304.625000,782.184875 302.797424,783.625610 
	C302.660187,784.159058 302.711365,784.531799 302.377258,784.566284 
	C301.404358,784.802002 300.765594,785.003174 300.126831,785.204346 
z"/>
<path fill="#5D666D" opacity="1.000000" stroke="none" 
	d="
M288.617767,794.005554 
	C288.083984,796.484314 287.200470,799.032227 286.319519,802.263062 
	C286.277039,803.635132 286.231995,804.324341 285.894531,805.011353 
	C285.407257,805.008667 285.212372,805.008179 285.017456,805.007751 
	C284.829865,804.454529 284.642242,803.901367 284.216980,803.172607 
	C285.408844,800.023010 286.838440,797.048828 288.617767,794.005554 
z"/>
<path fill="#BFB8BC" opacity="1.000000" stroke="none" 
	d="
M421.303619,823.942261 
	C421.400513,824.404358 421.253998,824.953491 420.992554,825.775452 
	C420.938477,825.375305 420.999359,824.702332 421.303619,823.942261 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M428.003082,857.987793 
	C428.121643,857.682556 428.311279,857.430847 429.121582,857.089355 
	C430.464661,855.980530 431.258148,855.014954 432.051605,854.049438 
	C432.051605,854.049438 432.000000,854.004883 432.389954,854.022705 
	C435.526123,852.726501 438.272339,851.412476 441.038574,850.508423 
	C441.140625,851.459778 441.222626,852.001099 441.304688,852.542419 
	C440.714539,853.210510 440.124390,853.878540 439.064697,854.421387 
	C436.651550,855.753967 434.708008,857.211792 432.460754,858.782104 
	C431.739044,859.567078 431.320984,860.239380 430.902893,860.911743 
	C430.902924,860.911743 430.904236,860.930603 430.896667,860.912415 
	C429.872620,860.967468 428.856079,861.040710 427.448181,860.770874 
	C427.372223,859.614502 427.687653,858.801147 428.003082,857.987793 
z"/>
<path fill="#5A6F81" opacity="1.000000" stroke="none" 
	d="
M441.642151,852.509277 
	C441.222626,852.001099 441.140625,851.459778 441.363220,850.428772 
	C443.111115,848.612366 444.554413,847.285706 446.315369,845.856812 
	C447.842102,845.242065 449.051208,844.729431 450.258331,844.520874 
	C450.277740,845.231079 450.299072,845.637268 450.320404,846.043396 
	C449.697876,846.942749 449.075317,847.842041 448.309326,849.044556 
	C448.010590,849.561768 447.841034,849.763794 447.504883,850.079102 
	C446.776581,850.395813 446.200562,850.587158 445.041382,850.908203 
	C444.070465,851.408386 443.682739,851.778992 443.294983,852.149597 
	C442.856537,852.258423 442.418091,852.367249 441.642151,852.509277 
z"/>
<path fill="#5A6F81" opacity="1.000000" stroke="none" 
	d="
M432.764465,858.669556 
	C434.708008,857.211792 436.651550,855.753967 438.810669,854.514648 
	C437.590820,856.112000 436.155365,857.490967 434.050995,858.876587 
	C433.176208,858.812012 432.970337,858.740845 432.764465,858.669556 
z"/>
<path fill="#828C93" opacity="1.000000" stroke="none" 
	d="
M432.460785,858.782166 
	C432.970337,858.740845 433.176208,858.812012 433.702789,858.946777 
	C433.204895,859.663391 432.386322,860.316406 431.235321,860.940552 
	C431.320984,860.239380 431.739044,859.567078 432.460785,858.782166 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M443.566345,852.102783 
	C443.682739,851.778992 444.070465,851.408386 444.759644,851.032837 
	C444.653290,851.370544 444.245514,851.713257 443.566345,852.102783 
z"/>
<path fill="#0A1E30" opacity="1.000000" stroke="none" 
	d="
M427.595551,857.990356 
	C427.687653,858.801147 427.372223,859.614502 427.064209,860.758240 
	C427.071625,861.088623 426.680023,861.226440 426.476807,861.264893 
	C425.460480,861.212952 424.647400,861.122620 423.425476,861.038452 
	C422.677399,857.662903 425.035370,857.968201 427.595551,857.990356 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M360.116974,804.460938 
	C362.520966,810.237915 364.657074,815.776367 367.206757,821.117249 
	C371.604065,830.328369 374.863159,840.243774 382.479248,847.730225 
	C381.492157,847.991577 380.765686,847.932129 379.895264,847.596802 
	C378.879974,846.816833 378.008606,846.312805 377.074554,845.465576 
	C371.674530,834.215149 366.420197,823.266052 360.949921,812.425842 
	C359.283722,809.124023 357.022644,806.122437 354.994873,802.665527 
	C354.612610,801.569641 354.269470,800.791565 353.930450,799.690247 
	C353.302216,798.573120 352.669830,797.779236 352.004150,796.672058 
	C351.378082,795.867920 350.785278,795.377075 350.125458,794.536377 
	C349.347473,792.479736 348.636505,790.772827 348.281311,789.042297 
	C349.111511,788.995544 349.585876,788.972473 350.060272,788.949402 
	C351.776642,791.282715 353.493011,793.615967 355.193176,796.634766 
	C355.776886,797.881592 356.376740,798.442993 356.976593,799.004395 
	C357.996124,800.714111 359.015686,802.423828 360.116974,804.460938 
z"/>
<path fill="#5D666D" opacity="1.000000" stroke="none" 
	d="
M350.070374,788.618713 
	C349.585876,788.972473 349.111511,788.995544 348.133362,788.763550 
	C347.358612,787.745422 347.087646,786.982300 346.816681,786.219238 
	C347.304932,786.149658 347.793213,786.080078 348.657227,786.019043 
	C349.103119,786.346497 349.272064,786.599365 349.753113,786.877441 
	C350.004425,787.408447 350.042450,787.848267 350.070374,788.618713 
z"/>
<path fill="#5D666D" opacity="1.000000" stroke="none" 
	d="
M357.003815,798.682251 
	C356.376740,798.442993 355.776886,797.881592 355.268188,796.971130 
	C355.916626,797.201416 356.473846,797.780762 357.003815,798.682251 
z"/>
<path fill="#0A0D0F" opacity="1.000000" stroke="none" 
	d="
M346.614441,785.968994 
	C347.087646,786.982300 347.358612,787.745422 347.777527,788.787231 
	C348.636505,790.772827 349.347473,792.479736 349.746460,794.544006 
	C347.669739,792.057922 345.905060,789.214478 344.151489,785.932739 
	C344.143158,785.202881 344.123688,784.911255 344.104248,784.619690 
	C344.873566,784.986023 345.642883,785.352356 346.614441,785.968994 
z"/>
<path fill="#5D666D" opacity="1.000000" stroke="none" 
	d="
M336.222168,779.318115 
	C334.604980,779.061035 332.835724,778.493225 330.967957,777.594849 
	C332.602966,777.845276 334.336517,778.426270 336.222168,779.318115 
z"/>
<path fill="#5D666D" opacity="1.000000" stroke="none" 
	d="
M341.232361,783.724487 
	C341.130920,783.081299 341.029480,782.438110 340.929199,781.470825 
	C341.994080,781.800903 343.057831,782.455017 343.797852,783.516663 
	C342.726868,783.857666 341.979614,783.791077 341.232361,783.724487 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M341.169800,783.801636 
	C341.979614,783.791077 342.726868,783.857666 343.848572,783.852661 
	C344.223053,783.781067 344.339478,784.226196 344.221863,784.422974 
	C344.123688,784.911255 344.143158,785.202881 343.819031,785.810730 
	C343.015015,786.065674 342.554565,786.004456 341.968079,785.695374 
	C341.553741,784.974609 341.265442,784.501770 340.977112,784.028870 
	C340.977112,784.028870 341.107269,783.878845 341.169800,783.801636 
z"/>
<path fill="#828C93" opacity="1.000000" stroke="none" 
	d="
M348.120880,325.396912 
	C348.233154,325.038391 348.503571,325.017609 349.030212,325.044739 
	C350.763641,324.825409 351.970428,324.578949 353.177246,324.332458 
	C354.113586,324.331909 355.049896,324.331360 356.438721,324.647522 
	C356.938446,325.799652 356.985687,326.635071 356.663574,327.660583 
	C354.420532,328.210693 352.546875,328.570648 350.409607,328.909760 
	C349.773926,329.226837 349.401917,329.564758 348.858337,330.240906 
	C348.287292,331.762451 347.887817,332.945770 347.488342,334.129089 
	C344.109436,331.117157 347.295898,328.513153 348.120880,325.396912 
z"/>
<path fill="#828C93" opacity="1.000000" stroke="none" 
	d="
M370.023224,340.547180 
	C370.015076,340.809906 370.006897,341.072601 369.997131,341.730225 
	C364.054352,342.129120 358.113220,342.133118 352.371033,341.451996 
	C352.798584,340.549194 353.027222,340.331543 353.680664,340.273987 
	C359.411377,340.471802 364.717316,340.509491 370.023224,340.547180 
z"/>
<path fill="#3A3A3A" opacity="1.000000" stroke="none" 
	d="
M353.255859,340.113861 
	C353.027222,340.331543 352.798584,340.549194 352.224121,341.091827 
	C351.494110,341.267731 351.109894,341.118652 350.458435,340.681030 
	C350.191193,340.392456 350.074432,340.130768 350.009094,339.898346 
	C349.943726,339.665924 349.741302,339.227539 349.720276,338.864258 
	C348.969910,337.997864 348.240570,337.494720 347.511230,336.991577 
	C347.493683,336.275970 347.476135,335.560333 347.473450,334.486908 
	C347.887817,332.945770 348.287292,331.762451 349.178406,330.251465 
	C350.096893,329.907410 350.523712,329.890991 350.971619,330.315735 
	C351.747070,333.875885 352.501465,336.994873 353.255859,340.113861 
z"/>
<path fill="#5D666D" opacity="1.000000" stroke="none" 
	d="
M350.074432,340.130768 
	C350.074432,340.130768 350.191193,340.392456 350.186890,340.534302 
	C347.332611,341.428101 346.917480,339.795349 347.317749,337.272522 
	C348.240570,337.494720 348.969910,337.997864 349.590424,338.966125 
	C349.679199,339.664398 349.876831,339.897583 350.074432,340.130768 
z"/>
<path fill="#828C93" opacity="1.000000" stroke="none" 
	d="
M376.826294,342.670166 
	C377.742249,342.542664 378.822479,342.722015 380.054260,343.202637 
	C379.134033,343.328247 378.062317,343.152649 376.826294,342.670166 
z"/>
<path fill="#828C93" opacity="1.000000" stroke="none" 
	d="
M383.164368,344.175415 
	C383.664642,343.969696 384.340179,344.036652 385.101013,344.351013 
	C384.570740,344.548309 383.955200,344.498230 383.164368,344.175415 
z"/>
<path fill="#828C93" opacity="1.000000" stroke="none" 
	d="
M390.146301,346.090668 
	C390.663849,345.923218 391.352966,346.042572 392.117188,346.420441 
	C391.567444,346.578461 390.942657,346.477966 390.146301,346.090668 
z"/>
<path fill="#828C93" opacity="1.000000" stroke="none" 
	d="
M387.135193,345.171082 
	C387.334503,344.948700 387.596771,345.039398 387.970612,345.374207 
	C388.114380,345.525269 387.646851,345.626190 387.646851,345.626190 
	C387.646851,345.626190 387.230377,345.391144 387.135193,345.171082 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M357.032928,327.470490 
	C356.985687,326.635071 356.938446,325.799652 356.789185,324.669312 
	C369.254211,324.323822 381.821289,324.273254 394.751099,324.619690 
	C395.066162,325.830780 395.018494,326.644867 394.514191,327.565308 
	C381.716034,327.604614 369.374481,327.537567 357.032928,327.470490 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M417.845886,324.264587 
	C418.149109,325.062347 417.962372,325.752350 417.737396,326.687744 
	C417.476440,327.236084 417.253723,327.539032 416.594299,328.066040 
	C410.184296,327.823730 404.211029,327.357361 398.204407,326.470947 
	C397.999542,325.520447 397.827972,324.989960 397.656433,324.459503 
	C404.222931,324.358612 410.789459,324.257751 417.845886,324.264587 
z"/>
<path fill="#828C93" opacity="1.000000" stroke="none" 
	d="
M397.370087,324.398438 
	C397.827972,324.989960 397.999542,325.520447 398.133789,326.792786 
	C397.054565,327.509460 396.012695,327.484192 394.970795,327.458923 
	C395.018494,326.644867 395.066162,325.830780 395.227722,324.737915 
	C395.922333,324.418488 396.503021,324.377930 397.370087,324.398438 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M352.766968,324.241577 
	C351.970428,324.578949 350.763641,324.825409 349.165833,325.046021 
	C348.774811,325.020203 348.902191,324.593262 348.956635,324.379211 
	C350.126282,324.160339 351.241486,324.155487 352.766968,324.241577 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M450.260284,844.216797 
	C449.051208,844.729431 447.842102,845.242065 446.309326,845.512329 
	C446.673523,843.877930 447.361328,842.485901 448.310242,840.963745 
	C449.070465,840.251343 449.569611,839.669006 450.338928,838.936768 
	C451.416565,837.866516 452.224030,836.946045 453.311523,835.894165 
	C454.419586,834.858765 455.247559,833.954651 456.339325,832.854248 
	C457.742706,831.110046 458.882324,829.562256 460.015442,828.008484 
	C460.008942,828.002502 460.015930,828.022522 460.325256,827.867432 
	C464.054291,823.453064 467.474030,819.193665 471.172485,814.960693 
	C471.636749,815.007629 471.822296,815.028259 472.007812,815.048828 
	C470.993286,817.480469 469.978760,819.912109 469.033112,822.717529 
	C469.102020,823.091370 469.079773,823.064392 468.710693,823.010742 
	C466.631348,825.464661 464.921021,827.972168 463.210724,830.479736 
	C462.696503,831.232605 462.182281,831.985413 461.061157,832.855957 
	C457.373810,836.411865 454.293304,839.850220 451.013855,843.397949 
	C450.567078,843.694580 450.382233,843.931091 450.260284,844.216797 
z"/>
<path fill="#5A6F81" opacity="1.000000" stroke="none" 
	d="
M451.212830,843.288574 
	C454.293304,839.850220 457.373810,836.411865 460.821228,832.995605 
	C458.657745,836.266357 456.127319,839.515076 453.028381,842.998169 
	C452.044220,843.251221 451.628510,843.269897 451.212830,843.288574 
z"/>
<path fill="#5A6F81" opacity="1.000000" stroke="none" 
	d="
M463.540985,830.443054 
	C464.921021,827.972168 466.631348,825.464661 468.713074,823.009521 
	C469.059906,823.736450 469.035309,824.411011 468.631805,825.540283 
	C467.762695,827.003479 467.272522,828.011780 466.782288,829.020081 
	C465.811951,829.482178 464.841614,829.944275 463.540985,830.443054 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M451.013855,843.397949 
	C451.628510,843.269897 452.044220,843.251221 452.774109,843.227478 
	C452.334442,844.064209 451.580536,844.906006 450.573547,845.895569 
	C450.299072,845.637268 450.277740,845.231079 450.258331,844.520874 
	C450.382233,843.931091 450.567078,843.694580 451.013855,843.397949 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M467.119019,828.895508 
	C467.272522,828.011780 467.762695,827.003479 468.590668,825.878967 
	C468.437531,826.765503 467.946625,827.768188 467.119019,828.895508 
z"/>
<path fill="#FCF8F7" opacity="1.000000" stroke="none" 
	d="
M518.998230,712.403442 
	C519.474121,711.048218 519.947998,710.131531 520.421875,709.214905 
	C521.184875,709.722595 522.644165,710.297974 522.602478,710.727417 
	C522.217834,714.684570 521.573486,718.616516 521.004761,722.555786 
	C520.335266,722.498901 519.665710,722.442017 518.996216,722.385193 
	C518.996216,719.204102 518.996216,716.023010 518.998230,712.403442 
z"/>
<path fill="#FCF8F7" opacity="1.000000" stroke="none" 
	d="
M530.235718,788.333740 
	C530.076355,784.630798 531.139954,784.253967 536.826782,785.901917 
	C536.446472,791.001831 532.529907,787.504761 530.235718,788.333740 
z"/>
<path fill="#FCF8F7" opacity="1.000000" stroke="none" 
	d="
M515.789429,666.347900 
	C515.808472,667.682373 515.576965,668.762390 515.345398,669.842346 
	C514.587463,668.943909 513.829590,668.045532 513.071655,667.147095 
	C513.894043,666.795837 514.716431,666.444580 515.789429,666.347900 
z"/>
<path fill="#FCF8F7" opacity="1.000000" stroke="none" 
	d="
M550.865112,700.289124 
	C551.170349,701.472229 551.120911,702.565308 551.071533,703.658386 
	C550.508911,703.500977 549.946228,703.343567 549.383606,703.186096 
	C549.759277,702.190430 550.134888,701.194763 550.865112,700.289124 
z"/>
<path fill="#FCF8F7" opacity="1.000000" stroke="none" 
	d="
M551.306946,770.525391 
	C550.551392,770.531128 550.015259,770.325989 549.479187,770.120850 
	C549.614197,769.846497 549.749207,769.572144 549.884216,769.297791 
	C550.431641,769.636719 550.979004,769.975586 551.306946,770.525391 
z"/>
<path fill="#052642" opacity="1.000000" stroke="none" 
	d="
M498.782166,725.541260 
	C494.538544,726.422241 495.427185,729.860840 495.036530,732.416077 
	C492.927338,746.213623 491.371918,760.117188 488.650330,773.790100 
	C487.444519,779.848083 483.890991,785.428040 481.539246,791.279663 
	C481.156738,792.231323 481.483429,793.468079 481.768616,794.798706 
	C481.778687,796.134277 481.502380,797.244263 480.783295,798.713135 
	C479.302338,800.825623 478.264160,802.579102 477.226013,804.332581 
	C477.211975,804.714111 477.197968,805.095642 476.783966,805.743652 
	C475.588928,806.339111 474.793854,806.668030 473.647705,806.960083 
	C472.122681,805.588928 470.948669,804.254700 470.732178,804.008667 
	C472.773132,800.558899 475.834595,797.269775 475.186768,796.174438 
	C470.854980,788.850403 473.095581,780.798462 472.342438,773.255005 
	C471.312225,762.936462 472.657623,752.379089 473.022736,741.925476 
	C473.056885,740.948425 473.167633,739.889587 473.572754,739.023010 
	C477.729523,730.131592 477.054230,720.600037 476.742371,711.292542 
	C476.289398,697.772888 474.773743,684.291504 474.126099,670.773926 
	C473.958160,667.268677 475.741760,663.711060 475.929626,660.140625 
	C476.764618,644.273010 477.340332,628.391785 478.033264,612.516541 
	C478.068970,611.698181 478.409119,610.894653 478.459808,610.075073 
	C478.950195,602.146790 479.549408,594.221558 479.835327,586.285339 
	C480.096100,579.046326 479.976227,571.794067 480.120575,564.549622 
	C480.135254,563.814819 480.666229,562.951111 481.207916,562.387939 
	C486.162415,557.236938 483.904266,551.245483 483.180511,545.476746 
	C482.586975,540.746460 480.943420,536.008667 481.175110,531.344177 
	C481.606964,522.649719 483.005859,513.998474 484.152008,505.349121 
	C484.340637,503.925659 485.351227,502.625183 485.873047,501.227203 
	C486.270081,500.163513 486.775818,499.028809 486.750488,497.936920 
	C486.569824,490.142242 486.173798,482.352142 486.024689,474.557220 
	C485.862244,466.064148 485.754272,457.565399 485.873627,449.072693 
	C486.021881,438.524445 486.492432,427.981049 486.696167,417.433105 
	C486.806458,411.721680 486.562317,406.003906 486.643372,400.291321 
	C486.712769,395.399872 486.861450,390.498138 487.285248,385.627960 
	C487.491791,383.254974 488.935883,380.937347 488.908325,378.605408 
	C488.788818,368.481079 488.294586,358.361755 488.019165,348.238342 
	C487.905731,344.069305 488.000366,339.894623 488.000366,335.271515 
	C490.513153,335.094238 493.496033,334.883789 496.865692,334.646057 
	C495.593231,333.087372 494.791687,332.105560 493.714355,330.785858 
	C496.908356,330.090607 499.386139,329.551270 501.863892,329.011932 
	C504.296265,328.993011 506.728668,328.974091 509.564697,329.048584 
	C511.533905,330.424194 512.922852,332.169861 514.716553,332.853333 
	C516.381897,333.487854 518.479126,332.988708 519.424316,332.988708 
	C519.424316,338.506836 519.424316,343.293732 519.424316,347.841858 
	C510.430695,351.256897 498.621155,371.346405 501.641235,384.673401 
	C501.878357,386.676361 501.837341,388.390289 501.404846,390.628845 
	C500.012451,392.923676 498.244934,394.648804 498.137787,396.471558 
	C497.538971,406.654785 497.155212,416.859711 497.050354,427.060822 
	C496.886841,442.967804 497.005249,458.877655 497.004944,474.786285 
	C497.003540,543.000671 497.002899,611.215088 497.000854,679.429504 
	C497.000458,692.089844 496.936188,704.750854 497.068176,717.409790 
	C497.084167,718.942200 498.146362,720.463684 498.723694,721.990234 
	C498.771088,723.044495 498.818451,724.098694 498.782166,725.541260 
z"/>
<path fill="#123552" opacity="1.000000" stroke="none" 
	d="
M499.056641,721.728638 
	C498.146362,720.463684 497.084167,718.942200 497.068176,717.409790 
	C496.936188,704.750854 497.000458,692.089844 497.000854,679.429504 
	C497.002899,611.215088 497.003540,543.000671 497.004944,474.786285 
	C497.005249,458.877655 496.886841,442.967804 497.050354,427.060822 
	C497.155212,416.859711 497.538971,406.654785 498.137787,396.471558 
	C498.244934,394.648804 500.012451,392.923676 501.334930,391.049774 
	C501.411896,396.337891 501.167236,401.729706 500.951263,407.828094 
	C500.979919,408.534698 501.015137,409.017761 501.015137,409.017761 
	C500.989532,502.676788 500.964996,596.335815 500.934570,689.994812 
	C500.933289,693.994019 500.877350,697.993225 500.476624,702.553711 
	C500.053101,704.740173 499.999817,706.365295 499.946533,707.990479 
	C499.760895,712.482666 499.575226,716.974792 499.056641,721.728638 
z"/>
<path fill="#2C4761" opacity="1.000000" stroke="none" 
	d="
M500.237305,707.746094 
	C499.999817,706.365295 500.053101,704.740173 500.430420,703.014282 
	C500.678955,704.442932 500.603516,705.972290 500.237305,707.746094 
z"/>
<path fill="#2C4761" opacity="1.000000" stroke="none" 
	d="
M501.195801,408.934448 
	C501.015137,409.017761 500.979919,408.534698 500.972229,408.293091 
	C501.101868,408.318024 501.239197,408.584564 501.195801,408.934448 
z"/>
<path fill="#2C4761" opacity="1.000000" stroke="none" 
	d="
M575.430115,328.099274 
	C575.941772,328.820801 575.997986,329.510803 576.048462,330.546295 
	C562.721802,330.920990 549.400513,331.000977 536.080566,330.902710 
	C534.714233,330.892670 533.355774,329.816162 531.939209,328.870514 
	C538.004578,328.356964 544.123657,328.130615 550.244263,328.076721 
	C558.487122,328.004150 566.731140,328.064453 575.430115,328.099274 
z"/>
<path fill="#123552" opacity="1.000000" stroke="none" 
	d="
M473.998779,806.996948 
	C474.793854,806.668030 475.588928,806.339111 476.730957,806.025391 
	C475.596466,808.937988 474.115021,811.835266 472.320679,814.890625 
	C471.822296,815.028259 471.636749,815.007629 471.206787,814.579346 
	C471.974518,811.780151 472.986664,809.388550 473.998779,806.996948 
z"/>
<path fill="#123552" opacity="1.000000" stroke="none" 
	d="
M477.549103,804.216858 
	C478.264160,802.579102 479.302338,800.825623 480.710724,799.051025 
	C480.011353,800.720337 478.941803,802.410767 477.549103,804.216858 
z"/>
<path fill="#05223A" opacity="1.000000" stroke="none" 
	d="
M473.647736,806.960083 
	C472.986664,809.388550 471.974518,811.780151 470.928040,814.552979 
	C467.474030,819.193665 464.054291,823.453064 460.319305,827.446289 
	C460.082092,826.021179 460.297058,824.852417 460.214722,823.705017 
	C459.839996,818.482788 463.518738,813.221619 459.389801,807.874329 
	C458.177338,806.304138 457.558868,801.425476 461.761169,801.229309 
	C467.625885,800.955566 468.065186,797.553711 468.004822,793.219849 
	C467.974670,791.054321 468.378204,788.806946 467.934631,786.736023 
	C465.979858,777.609924 466.952545,768.622925 467.719025,759.417358 
	C468.594879,748.897766 467.219391,738.201843 467.057251,727.579041 
	C466.994476,723.464783 467.959869,719.323669 467.777649,715.225037 
	C467.469666,708.297485 466.117157,701.394836 466.106018,694.480286 
	C466.083527,680.528320 466.727997,666.576477 466.975677,652.622375 
	C467.111664,644.960632 466.934418,637.293518 467.049652,629.631104 
	C467.075989,627.882507 467.687378,626.133911 468.099426,624.401978 
	C468.699707,621.878723 469.893799,619.375000 469.915161,616.855103 
	C470.072205,598.362549 469.984406,579.867981 470.024597,561.374207 
	C470.028534,559.570496 470.265961,557.747803 470.585724,555.968201 
	C471.328491,551.834717 472.550018,547.753113 472.904907,543.593079 
	C473.314087,538.796265 472.917480,533.935913 473.024170,529.106567 
	C473.122894,524.637573 473.224274,520.152161 473.686798,515.711731 
	C474.263977,510.171143 475.795288,504.677155 475.870880,499.148773 
	C476.002350,489.534485 475.093170,479.910431 475.046692,470.288422 
	C474.915436,443.131348 475.031921,415.973145 474.959625,388.815643 
	C474.950867,385.521545 475.903839,383.669922 479.498627,383.993073 
	C482.433746,384.256958 484.066864,382.825348 483.751831,379.934479 
	C482.948273,372.561768 481.990204,365.203552 480.917206,357.865356 
	C480.767853,356.843872 479.825623,355.569244 478.900421,355.095856 
	C475.405212,353.307526 473.863342,349.674286 475.206116,345.895935 
	C476.791962,341.433411 479.705872,344.625824 482.655029,344.980469 
	C482.314362,341.929840 485.557098,337.622925 478.705048,338.729065 
	C477.653595,338.898804 476.310333,337.260895 473.890381,335.654907 
	C477.827667,335.119873 480.159241,334.803070 482.490814,334.486237 
	C482.432648,333.990814 482.374481,333.495392 482.316315,332.999969 
	C479.877563,332.999969 477.438782,332.999969 474.891754,332.739319 
	C474.514526,331.982819 474.245544,331.486938 474.293640,330.852356 
	C476.094666,330.026306 477.578613,329.338928 479.062592,328.651550 
	C486.403961,328.591888 493.745331,328.532227 501.475281,328.742249 
	C499.386139,329.551270 496.908356,330.090607 493.714355,330.785858 
	C494.791687,332.105560 495.593231,333.087372 496.865692,334.646057 
	C493.496033,334.883789 490.513153,335.094238 488.000366,335.271515 
	C488.000366,339.894623 487.905731,344.069305 488.019165,348.238342 
	C488.294586,358.361755 488.788818,368.481079 488.908325,378.605408 
	C488.935883,380.937347 487.491791,383.254974 487.285248,385.627960 
	C486.861450,390.498138 486.712769,395.399872 486.643372,400.291321 
	C486.562317,406.003906 486.806458,411.721680 486.696167,417.433105 
	C486.492432,427.981049 486.021881,438.524445 485.873627,449.072693 
	C485.754272,457.565399 485.862244,466.064148 486.024689,474.557220 
	C486.173798,482.352142 486.569824,490.142242 486.750488,497.936920 
	C486.775818,499.028809 486.270081,500.163513 485.873047,501.227203 
	C485.351227,502.625183 484.340637,503.925659 484.152008,505.349121 
	C483.005859,513.998474 481.606964,522.649719 481.175110,531.344177 
	C480.943420,536.008667 482.586975,540.746460 483.180511,545.476746 
	C483.904266,551.245483 486.162415,557.236938 481.207916,562.387939 
	C480.666229,562.951111 480.135254,563.814819 480.120575,564.549622 
	C479.976227,571.794067 480.096100,579.046326 479.835327,586.285339 
	C479.549408,594.221558 478.950195,602.146790 478.459808,610.075073 
	C478.409119,610.894653 478.068970,611.698181 478.033264,612.516541 
	C477.340332,628.391785 476.764618,644.273010 475.929626,660.140625 
	C475.741760,663.711060 473.958160,667.268677 474.126099,670.773926 
	C474.773743,684.291504 476.289398,697.772888 476.742371,711.292542 
	C477.054230,720.600037 477.729523,730.131592 473.572754,739.023010 
	C473.167633,739.889587 473.056885,740.948425 473.022736,741.925476 
	C472.657623,752.379089 471.312225,762.936462 472.342438,773.255005 
	C473.095581,780.798462 470.854980,788.850403 475.186768,796.174438 
	C475.834595,797.269775 472.773132,800.558899 470.732178,804.008667 
	C470.948669,804.254700 472.122681,805.588928 473.647736,806.960083 
z"/>
<path fill="#3A3A3A" opacity="1.000000" stroke="none" 
	d="
M478.634827,328.446228 
	C477.578613,329.338928 476.094666,330.026306 473.906097,330.843384 
	C471.166656,330.385956 469.131775,329.798828 467.096924,329.211731 
	C467.084290,329.009857 467.071655,328.808014 467.046021,328.303040 
	C470.757660,328.080292 474.482361,328.160614 478.634827,328.446228 
z"/>
<path fill="#0A1E30" opacity="1.000000" stroke="none" 
	d="
M466.620544,329.155518 
	C469.131775,329.798828 471.166656,330.385956 473.589050,330.982056 
	C474.245544,331.486938 474.514526,331.982819 474.583374,332.969849 
	C470.922943,333.974030 467.462708,334.487000 464.002441,335.000000 
	C462.736633,334.333984 461.497009,333.160339 460.200287,333.093445 
	C455.617859,332.857056 451.009827,333.133606 446.424530,332.926666 
	C444.791229,332.852966 443.195129,331.954987 441.262115,331.196045 
	C440.960114,330.725281 440.977997,330.491821 441.016357,329.904480 
	C449.405945,329.400177 457.775024,329.249756 466.620544,329.155518 
z"/>
<path fill="#0A0D0F" opacity="1.000000" stroke="none" 
	d="
M394.514191,327.565308 
	C396.012695,327.484192 397.054565,327.509460 398.167114,327.212830 
	C404.211029,327.357361 410.184296,327.823730 416.556152,328.343689 
	C418.353333,329.251526 419.751892,330.105804 421.570892,330.968109 
	C423.092987,331.651581 424.123199,332.671539 425.313751,332.918488 
	C426.937042,333.255157 428.682526,333.002502 430.375641,333.002502 
	C430.394135,333.544006 430.412659,334.085510 430.431183,334.626984 
	C427.832123,334.842957 425.233032,335.058960 423.499695,335.202972 
	C424.516052,344.407776 425.510315,353.412140 426.520721,362.563141 
	C423.612976,360.617340 421.664032,359.313141 419.045166,357.864746 
	C416.917786,356.145782 415.460388,354.571045 413.946838,352.639160 
	C413.093903,351.932190 412.297119,351.582428 411.500336,351.232697 
	C411.500336,351.232697 411.373352,351.456543 411.118591,351.090332 
	C405.278564,347.828491 399.728302,344.860199 394.063629,342.129181 
	C393.355133,341.787628 392.041840,342.700592 391.008789,343.032318 
	C389.897583,342.776978 388.786377,342.521637 387.301880,341.749512 
	C384.620605,340.869965 382.312683,340.507233 380.004730,340.144531 
	C376.891937,340.128479 373.779144,340.112427 370.344788,340.321777 
	C364.717316,340.509491 359.411377,340.471802 353.680664,340.273987 
	C352.501465,336.994873 351.747070,333.875885 350.960907,330.063354 
	C350.843842,329.223419 350.758545,329.077026 350.673218,328.930603 
	C352.546875,328.570648 354.420532,328.210693 356.663574,327.660583 
	C369.374481,327.537567 381.716034,327.604614 394.514191,327.565308 
z"/>
<path fill="#B9B4BB" opacity="1.000000" stroke="none" 
	d="
M578.592712,338.536804 
	C578.580566,338.563965 578.598816,338.608093 578.602173,338.591370 
	C578.605469,338.574615 578.510864,338.584930 578.532288,338.545288 
	C578.553711,338.505646 578.604858,338.509674 578.592712,338.536804 
z"/>
<path fill="#061F35" opacity="1.000000" stroke="none" 
	d="
M463.585541,335.000153 
	C467.462708,334.487000 470.922943,333.974030 474.691589,333.230530 
	C477.438782,332.999969 479.877563,332.999969 482.316315,332.999969 
	C482.374481,333.495392 482.432648,333.990814 482.490814,334.486237 
	C480.159241,334.803070 477.827667,335.119873 473.890381,335.654907 
	C476.310333,337.260895 477.653595,338.898804 478.705048,338.729065 
	C485.557098,337.622925 482.314362,341.929840 482.655029,344.980469 
	C479.705872,344.625824 476.791962,341.433411 475.206116,345.895935 
	C473.863342,349.674286 475.405212,353.307526 478.900421,355.095856 
	C479.825623,355.569244 480.767853,356.843872 480.917206,357.865356 
	C481.990204,365.203552 482.948273,372.561768 483.751831,379.934479 
	C484.066864,382.825348 482.433746,384.256958 479.498627,383.993073 
	C475.903839,383.669922 474.950867,385.521545 474.959625,388.815643 
	C475.031921,415.973145 474.915436,443.131348 475.046692,470.288422 
	C475.093170,479.910431 476.002350,489.534485 475.870880,499.148773 
	C475.795288,504.677155 474.263977,510.171143 473.686798,515.711731 
	C473.224274,520.152161 473.122894,524.637573 473.024170,529.106567 
	C472.917480,533.935913 473.314087,538.796265 472.904907,543.593079 
	C472.550018,547.753113 471.328491,551.834717 470.585724,555.968201 
	C470.265961,557.747803 470.028534,559.570496 470.024597,561.374207 
	C469.984406,579.867981 470.072205,598.362549 469.915161,616.855103 
	C469.893799,619.375000 468.699707,621.878723 468.099426,624.401978 
	C467.687378,626.133911 467.075989,627.882507 467.049652,629.631104 
	C466.934418,637.293518 467.111664,644.960632 466.975677,652.622375 
	C466.727997,666.576477 466.083527,680.528320 466.106018,694.480286 
	C466.117157,701.394836 467.469666,708.297485 467.777649,715.225037 
	C467.959869,719.323669 466.994476,723.464783 467.057251,727.579041 
	C467.219391,738.201843 468.594879,748.897766 467.719025,759.417358 
	C466.952545,768.622925 465.979858,777.609924 467.934631,786.736023 
	C468.378204,788.806946 467.974670,791.054321 468.004822,793.219849 
	C468.065186,797.553711 467.625885,800.955566 461.761169,801.229309 
	C457.558868,801.425476 458.177338,806.304138 459.389801,807.874329 
	C463.518738,813.221619 459.839996,818.482788 460.214722,823.705017 
	C460.297058,824.852417 460.082092,826.021179 460.009979,827.601318 
	C460.015930,828.022522 460.008942,828.002502 459.634216,828.008911 
	C456.489319,828.613159 453.406769,829.013550 456.075562,833.050598 
	C455.247559,833.954651 454.419586,834.858765 452.947021,835.885864 
	C449.942322,835.862244 448.462799,836.354736 450.068787,839.086609 
	C449.569611,839.669006 449.070465,840.251343 448.102570,840.681396 
	C445.104462,838.090759 441.202026,836.043396 440.369965,833.121582 
	C439.144287,828.817932 439.515289,823.795410 440.249634,819.247498 
	C442.406342,805.890625 440.180359,792.380310 443.514771,778.850647 
	C446.233276,767.820007 444.815979,755.735168 444.970398,744.114258 
	C445.388214,712.672485 445.742554,681.229370 445.972504,649.785767 
	C446.119293,629.715698 446.000000,609.643799 446.000000,589.572693 
	C446.000000,582.076111 445.984344,574.579407 446.012848,567.082947 
	C446.018829,565.511047 446.187561,563.939758 446.327637,561.590759 
	C452.712616,564.268372 447.928284,570.451355 451.640930,574.067078 
	C452.123505,565.927063 452.509979,558.530823 453.032745,551.144226 
	C453.182190,549.032166 453.650970,546.931396 454.109375,544.855469 
	C454.664093,542.343567 455.764618,539.890015 455.931610,537.365112 
	C456.718353,525.472168 457.439056,513.567505 457.718719,501.656158 
	C457.762207,499.804901 455.462891,497.852509 454.134918,496.028351 
	C452.831635,494.238129 450.128418,492.501190 450.195770,490.827637 
	C450.912201,473.023346 452.055542,455.236176 453.101715,437.445465 
	C453.130005,436.964447 453.438904,436.506439 453.526001,436.021362 
	C454.361908,431.366150 455.206238,426.712097 455.985931,422.047333 
	C456.284821,420.259186 456.513214,418.449463 456.618591,416.640533 
	C456.987091,410.316315 459.813507,404.402039 458.266602,397.629120 
	C457.191376,392.921417 458.792755,387.648895 458.849121,382.624847 
	C458.925415,375.818268 459.532562,368.852539 458.344269,362.236694 
	C456.681580,352.979767 455.857697,344.020020 459.000366,335.004333 
	C460.389801,335.002991 461.779205,335.001648 463.585541,335.000153 
M449.220642,595.074036 
	C448.815979,598.335205 448.210938,601.591248 448.127411,604.860657 
	C448.101990,605.855469 449.514221,606.887085 450.268066,607.901855 
	C451.168884,606.913391 452.685120,606.015930 452.847443,604.918274 
	C453.230865,602.325684 453.267120,599.586304 452.821289,597.014221 
	C452.646545,596.006226 450.807098,595.286804 449.220642,595.074036 
z"/>
<path fill="#0A1E30" opacity="1.000000" stroke="none" 
	d="
M458.544434,335.002686 
	C455.857697,344.020020 456.681580,352.979767 458.344269,362.236694 
	C459.532562,368.852539 458.925415,375.818268 458.849121,382.624847 
	C458.792755,387.648895 457.191376,392.921417 458.266602,397.629120 
	C459.813507,404.402039 456.987091,410.316315 456.618591,416.640533 
	C456.513214,418.449463 456.284821,420.259186 455.985931,422.047333 
	C455.206238,426.712097 454.361908,431.366150 453.526001,436.021362 
	C453.438904,436.506439 453.130005,436.964447 453.101715,437.445465 
	C452.055542,455.236176 450.912201,473.023346 450.195770,490.827637 
	C450.128418,492.501190 452.831635,494.238129 454.134918,496.028351 
	C455.462891,497.852509 457.762207,499.804901 457.718719,501.656158 
	C457.439056,513.567505 456.718353,525.472168 455.931610,537.365112 
	C455.764618,539.890015 454.664093,542.343567 454.109375,544.855469 
	C453.650970,546.931396 453.182190,549.032166 453.032745,551.144226 
	C452.509979,558.530823 452.123505,565.927063 451.640930,574.067078 
	C447.928284,570.451355 452.712616,564.268372 446.327637,561.590759 
	C446.187561,563.939758 446.018829,565.511047 446.012848,567.082947 
	C445.984344,574.579407 446.000000,582.076111 446.000000,589.572693 
	C446.000000,609.643799 446.119293,629.715698 445.972504,649.785767 
	C445.742554,681.229370 445.388214,712.672485 444.970398,744.114258 
	C444.815979,755.735168 446.233276,767.820007 443.514771,778.850647 
	C440.180359,792.380310 442.406342,805.890625 440.249634,819.247498 
	C439.515289,823.795410 439.144287,828.817932 440.369965,833.121582 
	C441.202026,836.043396 445.104462,838.090759 447.841492,840.811462 
	C447.361328,842.485901 446.673523,843.877930 445.991699,845.614502 
	C444.554413,847.285706 443.111115,848.612366 441.343201,850.018799 
	C438.272339,851.412476 435.526123,852.726501 432.415649,854.047363 
	C432.003815,851.002930 431.703674,847.981201 436.495392,848.457214 
	C437.532654,848.560242 438.787109,846.476685 439.941528,845.400269 
	C439.790192,844.991638 439.638855,844.583069 439.487488,844.174438 
	C437.702179,844.347778 435.916840,844.521179 433.195984,844.785339 
	C432.482513,840.447693 431.647278,836.011108 431.116150,831.538391 
	C431.022614,830.750671 432.047760,829.631042 432.819885,828.974915 
	C435.806854,826.436951 435.985626,823.919006 432.736115,821.973145 
	C428.710724,819.562561 429.402954,817.798767 432.736816,815.419617 
	C433.884888,814.600220 434.725006,812.759521 434.907562,811.286438 
	C435.476685,806.694763 435.968994,802.052368 435.918365,797.435913 
	C435.860870,792.186768 436.455017,787.333801 439.002411,782.550659 
	C440.218109,780.268005 440.293701,776.987976 439.899048,774.304871 
	C438.120880,762.215515 438.788055,750.121582 438.893494,737.983337 
	C439.195801,703.174805 438.865906,668.361145 439.091644,633.551453 
	C439.150299,624.507263 440.762360,615.476501 440.890198,606.428711 
	C441.045990,595.405334 440.102356,584.368103 440.190948,573.341370 
	C440.242493,566.927002 441.592682,560.534363 441.992401,554.109131 
	C442.794769,541.212524 443.708008,528.305481 443.894562,515.392883 
	C444.064636,503.617218 442.909241,491.820068 443.143463,480.048431 
	C443.313568,471.497284 444.837646,462.976288 445.670929,454.433319 
	C445.928345,451.794250 445.929321,449.127960 445.994995,446.472870 
	C446.137482,440.710876 445.217346,434.872559 448.531281,429.468811 
	C449.447479,427.974823 448.976959,425.574554 448.985352,423.589417 
	C449.021881,414.928436 448.928162,406.266327 449.031036,397.606415 
	C449.080963,393.404907 447.973267,388.983337 451.545166,385.335083 
	C452.344360,384.518738 452.014099,382.464417 451.980011,380.985229 
	C451.681824,368.035553 451.430115,355.083038 450.901001,342.142151 
	C450.814819,340.034088 449.435638,337.978882 448.316498,335.001038 
	C451.664398,335.001038 454.876434,335.001038 458.544434,335.002686 
z"/>
<path fill="#0A0D0F" opacity="1.000000" stroke="none" 
	d="
M355.033997,802.983337 
	C357.022644,806.122437 359.283722,809.124023 360.949921,812.425842 
	C366.420197,823.266052 371.674530,834.215149 377.053040,845.812439 
	C378.075897,846.959229 379.057556,847.415955 380.039215,847.872681 
	C380.765686,847.932129 381.492157,847.991577 382.582031,848.050293 
	C382.945404,848.049561 382.957764,848.035339 382.986938,848.391357 
	C385.014954,850.155396 387.013824,851.563477 389.012695,852.971497 
	C389.097809,853.619751 388.942780,854.484985 389.303558,854.884216 
	C396.322968,862.655640 395.004974,868.174500 385.123047,872.453369 
	C383.381317,873.207642 381.782227,874.291382 380.050293,875.561646 
	C372.638306,875.964172 365.293243,876.027954 357.475098,875.597290 
	C357.667969,861.940430 358.309326,848.776611 359.014587,835.616333 
	C359.235016,831.503601 359.729919,827.404175 359.894257,823.290466 
	C359.964752,821.525635 359.857574,819.605530 359.228760,817.990540 
	C357.722626,814.122375 355.720428,810.446228 354.239166,806.570068 
	C353.874237,805.615173 354.736115,804.191528 355.033997,802.983337 
z"/>
<path fill="#0A1E30" opacity="1.000000" stroke="none" 
	d="
M429.433197,591.990234 
	C429.012543,581.982239 429.005463,571.954651 429.024841,561.457764 
	C430.031708,561.964294 431.836212,562.918274 431.862915,563.919739 
	C432.088196,572.367798 432.053375,580.825867 431.892731,589.277039 
	C431.875427,590.187561 430.560028,591.073425 429.433197,591.990234 
z"/>
<path fill="#0A1E30" opacity="1.000000" stroke="none" 
	d="
M429.411621,507.988647 
	C428.994080,505.302094 428.984497,502.599274 428.989258,499.446167 
	C429.994141,499.782623 431.894836,500.688049 431.810547,501.334808 
	C431.515930,503.595215 433.849854,506.846069 429.411621,507.988647 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M414.002960,352.996338 
	C415.460388,354.571045 416.917786,356.145782 418.661133,357.865540 
	C419.599152,359.479370 420.251282,360.948212 420.874390,362.767334 
	C418.247650,360.419464 415.649933,357.721344 413.095032,354.409027 
	C413.426208,353.528656 413.714569,353.262482 414.002960,352.996338 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M426.846863,380.741638 
	C425.632416,377.947449 424.502441,374.728302 423.584381,371.263123 
	C424.841309,374.116943 425.886322,377.216797 426.846863,380.741638 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M424.104736,827.961304 
	C424.569916,825.309875 425.327972,822.682007 426.401550,819.936035 
	C426.807617,820.298645 426.898163,820.779358 426.986816,821.961426 
	C426.989197,823.110168 426.993378,823.557556 426.997559,824.004883 
	C426.972961,824.718750 426.948395,825.432617 426.634216,826.558838 
	C425.891479,827.313049 425.438324,827.655029 424.985168,827.996948 
	C424.789337,827.992981 424.593475,827.988953 424.104736,827.961304 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M389.304626,852.946899 
	C387.013824,851.563477 385.014954,850.155396 382.984344,848.399536 
	C385.497986,849.195251 388.043335,850.338684 390.796509,851.759888 
	C390.535065,852.332520 390.065826,852.627441 389.304626,852.946899 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M392.980408,852.586304 
	C396.049225,851.955933 399.249115,851.678223 402.717651,851.617004 
	C399.890411,853.950195 396.694855,855.176453 392.980408,852.586304 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M427.326660,392.710876 
	C426.807251,390.193451 426.563141,387.394531 426.571228,384.302429 
	C427.082947,386.815948 427.342468,389.622711 427.326660,392.710876 
z"/>
<path fill="#0A1E30" opacity="1.000000" stroke="none" 
	d="
M430.246826,530.803833 
	C429.632446,529.277466 429.310974,527.538879 429.006836,525.395508 
	C434.348907,525.553589 431.503723,528.327148 430.246826,530.803833 
z"/>
<path fill="#0A1E30" opacity="1.000000" stroke="none" 
	d="
M407.062622,850.101746 
	C408.498535,849.217957 409.934448,848.334106 411.914795,847.211304 
	C412.960907,846.956055 413.462524,846.939758 413.964172,846.923462 
	C411.934601,848.286194 409.905029,849.648926 407.475067,850.787231 
	C407.074677,850.562927 407.062622,850.101746 407.062622,850.101746 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M420.991211,834.956665 
	C421.680939,833.324585 422.370697,831.692505 423.359619,829.942261 
	C423.724121,831.289917 423.789429,832.755615 423.524841,834.574951 
	C422.460358,834.937866 421.725769,834.947266 420.991211,834.956665 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M423.724854,368.854187 
	C422.788574,367.711639 421.926544,366.199097 421.058594,364.330139 
	C421.968140,365.477203 422.883636,366.980682 423.724854,368.854187 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M418.092438,839.033325 
	C418.769745,838.017029 419.447052,837.000793 420.532837,836.208435 
	C420.970978,836.625854 421.000671,836.819458 421.030396,837.013000 
	C421.004974,837.432434 420.979553,837.851929 420.596832,838.614197 
	C419.523804,838.982422 418.808136,839.007874 418.092438,839.033325 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M427.128662,815.888916 
	C426.757904,814.794006 426.626068,813.610718 426.747131,812.220947 
	C427.319061,812.786011 427.638123,813.557556 427.964691,814.664551 
	C427.770630,815.266846 427.569092,815.533630 427.128662,815.888916 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M415.309937,843.925232 
	C415.692963,843.000916 416.451782,842.098267 417.485840,841.057739 
	C417.069305,841.928833 416.377502,842.937927 415.309937,843.925232 
z"/>
<path fill="#0A1E30" opacity="1.000000" stroke="none" 
	d="
M431.705475,854.063843 
	C431.258148,855.014954 430.464661,855.980530 429.330109,856.973022 
	C429.779114,856.026062 430.569214,855.052185 431.705475,854.063843 
z"/>
<path fill="#0A1E30" opacity="1.000000" stroke="none" 
	d="
M421.388550,837.003296 
	C421.000671,836.819458 420.970978,836.625854 420.859863,836.148743 
	C420.778381,835.865173 420.898438,835.414551 420.944824,835.185608 
	C421.725769,834.947266 422.460358,834.937866 423.558960,834.890625 
	C423.197571,835.566284 422.472137,836.279907 421.388550,837.003296 
z"/>
<path fill="#0A1E30" opacity="1.000000" stroke="none" 
	d="
M429.360107,545.000000 
	C429.002197,544.559021 428.988129,544.110657 428.994293,543.326294 
	C429.846069,543.293091 430.677643,543.595947 431.509216,543.898804 
	C430.907440,544.263428 430.305664,544.628052 429.360107,545.000000 
z"/>
<path fill="#0A1E30" opacity="1.000000" stroke="none" 
	d="
M418.036102,839.351990 
	C418.808136,839.007874 419.523804,838.982422 420.596924,838.929443 
	C420.192841,839.595459 419.431396,840.289124 418.304382,840.972229 
	C417.952454,840.531311 417.966125,840.101013 418.036102,839.351990 
z"/>
<path fill="#0A1E30" opacity="1.000000" stroke="none" 
	d="
M429.336700,610.955566 
	C428.990845,610.253052 428.979248,609.498840 428.980286,608.367432 
	C429.218994,608.961426 429.444977,609.932556 429.336700,610.955566 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M413.975342,846.602600 
	C413.462524,846.939758 412.960907,846.956055 412.079132,846.951050 
	C412.273224,846.289429 412.847443,845.649231 413.701599,845.002563 
	C413.983185,845.424683 413.984833,845.853210 413.975342,846.602600 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M406.753357,850.096741 
	C407.062622,850.101746 407.074677,850.562927 407.096985,850.792847 
	C406.550964,851.294373 405.982635,851.565918 405.095367,851.735474 
	C405.332336,851.119568 405.888184,850.605652 406.753357,850.096741 
z"/>
<path fill="#0A1E30" opacity="1.000000" stroke="none" 
	d="
M425.165009,828.228821 
	C425.438324,827.655029 425.891479,827.313049 426.636230,826.916016 
	C426.400146,827.394104 425.872498,827.927368 425.165009,828.228821 
z"/>
<path fill="#0A1E30" opacity="1.000000" stroke="none" 
	d="
M429.315460,536.977234 
	C429.166016,536.450439 429.310486,535.887939 429.692871,535.148315 
	C429.823669,535.627930 429.716522,536.284729 429.315460,536.977234 
z"/>
<path fill="#0A1E30" opacity="1.000000" stroke="none" 
	d="
M427.295898,823.965942 
	C426.993378,823.557556 426.989197,823.110168 427.006226,822.327820 
	C427.539520,822.233093 428.051605,822.473389 428.563721,822.713684 
	C428.240570,823.118164 427.917389,823.522644 427.295898,823.965942 
z"/>
<path fill="#081926" opacity="1.000000" stroke="none" 
	d="
M391.219574,343.332031 
	C392.041840,342.700592 393.355133,341.787628 394.063629,342.129181 
	C399.728302,344.860199 405.278564,347.828491 410.990051,351.095398 
	C407.968475,350.275696 404.803406,349.128174 401.677673,347.881927 
	C398.243011,346.512604 394.844574,345.052429 391.219574,343.332031 
z"/>
<path fill="#3A3A3A" opacity="1.000000" stroke="none" 
	d="
M380.257690,340.403717 
	C382.312683,340.507233 384.620605,340.869965 386.996582,341.581848 
	C384.879974,341.508270 382.695343,341.085602 380.257690,340.403717 
z"/>
<path fill="#081926" opacity="1.000000" stroke="none" 
	d="
M413.946838,352.639160 
	C413.714569,353.262482 413.426208,353.528656 413.046844,354.084778 
	C412.516022,353.556641 412.076202,352.738556 411.568359,351.576569 
	C412.297119,351.582428 413.093903,351.932190 413.946838,352.639160 
z"/>
<path fill="#080501" opacity="1.000000" stroke="none" 
	d="
M354.994873,802.665527 
	C354.736115,804.191528 353.874237,805.615173 354.239166,806.570068 
	C355.720428,810.446228 357.722626,814.122375 359.228760,817.990540 
	C359.857574,819.605530 359.964752,821.525635 359.894257,823.290466 
	C359.729919,827.404175 359.235016,831.503601 359.014587,835.616333 
	C358.309326,848.776611 357.667969,861.940430 357.007629,875.577026 
	C354.639771,876.031860 352.266296,876.012390 349.388824,875.626038 
	C347.588806,875.178772 346.292786,875.098389 344.996765,875.018066 
	C339.835754,873.904602 334.674744,872.791138 329.207092,871.049500 
	C328.199860,869.986389 327.499268,869.551514 326.798676,869.116638 
	C323.315765,867.938660 319.832886,866.760742 316.127167,864.976807 
	C315.194580,863.958252 314.484802,863.545654 313.775024,863.133057 
	C313.775024,863.133057 313.371948,863.149780 313.139038,862.765991 
	C312.193970,861.966980 311.481812,861.551819 310.769653,861.136658 
	C301.632416,855.545532 294.754608,848.021606 290.932556,837.166931 
	C289.724579,834.624146 288.485168,832.824951 287.162598,830.610474 
	C287.066620,828.789062 287.053772,827.382812 287.323608,825.698975 
	C287.681915,819.871094 287.757568,814.320862 287.842316,808.434692 
	C287.844940,807.420654 287.838440,806.742493 288.130737,805.858765 
	C289.245422,803.364868 290.061310,801.076538 290.877228,798.788269 
	C291.070709,797.666199 291.264160,796.544189 292.045959,795.172241 
	C293.091980,794.288940 293.549652,793.655579 294.007324,793.022217 
	C294.090698,792.692139 294.263916,792.419006 295.085693,792.057007 
	C296.110992,791.009705 296.577545,790.108154 297.044128,789.206665 
	C298.051849,788.086304 299.059601,786.965942 300.097107,785.524963 
	C300.765594,785.003174 301.404358,784.802002 302.541931,784.865845 
	C304.022308,784.774414 305.003876,784.418030 305.985474,784.061646 
	C306.383514,784.002014 306.781525,783.942322 307.819824,783.893677 
	C308.982208,783.315308 309.504333,782.725830 310.026428,782.136353 
	C311.661316,782.038818 313.296173,781.941284 315.793915,781.895996 
	C322.666962,780.921631 328.562286,780.597290 334.182526,783.789795 
	C335.179962,784.356323 336.748871,783.916626 338.053894,783.941650 
	C338.053894,783.941589 338.005402,783.956421 338.071838,784.313538 
	C339.456879,785.094849 340.775482,785.519043 342.094116,785.943237 
	C342.554565,786.004456 343.015015,786.065674 343.807922,786.249023 
	C345.905060,789.214478 347.669739,792.057922 349.813477,794.893799 
	C350.785278,795.377075 351.378082,795.867920 351.753754,796.852051 
	C352.333191,798.234741 353.129761,799.124146 353.926331,800.013489 
	C354.269470,800.791565 354.612610,801.569641 354.994873,802.665527 
z"/>
<path fill="#0A0D0F" opacity="1.000000" stroke="none" 
	d="
M287.245758,831.025757 
	C288.485168,832.824951 289.724579,834.624146 290.928345,836.800293 
	C289.588562,835.811951 288.284393,834.446655 286.668518,833.042236 
	C286.248901,832.569458 286.140961,832.135925 286.330109,831.385376 
	C286.833374,831.054138 287.039551,831.039978 287.245758,831.025757 
z"/>
<path fill="#0A0D0F" opacity="1.000000" stroke="none" 
	d="
M345.225098,875.294434 
	C346.292786,875.098389 347.588806,875.178772 348.945801,875.593018 
	C347.822327,875.808167 346.637878,875.689453 345.225098,875.294434 
z"/>
<path fill="#0A0D0F" opacity="1.000000" stroke="none" 
	d="
M313.870422,863.439941 
	C314.484802,863.545654 315.194580,863.958252 315.935974,864.714722 
	C315.300323,864.621399 314.633057,864.184082 313.870422,863.439941 
z"/>
<path fill="#0A0D0F" opacity="1.000000" stroke="none" 
	d="
M310.865051,861.446533 
	C311.481812,861.551819 312.193970,861.966980 312.940918,862.728027 
	C312.303986,862.634705 311.632202,862.195557 310.865051,861.446533 
z"/>
<path fill="#0A0D0F" opacity="1.000000" stroke="none" 
	d="
M326.882690,869.428345 
	C327.499268,869.551514 328.199860,869.986389 328.917328,870.771240 
	C328.278351,870.660828 327.622528,870.200439 326.882690,869.428345 
z"/>
<path fill="#0A0D0F" opacity="1.000000" stroke="none" 
	d="
M337.883820,783.616150 
	C336.748871,783.916626 335.179962,784.356323 334.182526,783.789795 
	C328.562286,780.597290 322.666962,780.921631 316.202789,781.892944 
	C318.875061,780.896240 322.113312,778.930847 325.100952,779.257568 
	C329.400787,779.727722 333.518372,781.864990 337.883820,783.616150 
z"/>
<path fill="#0A0D0F" opacity="1.000000" stroke="none" 
	d="
M341.968079,785.695312 
	C340.775482,785.519043 339.456879,785.094849 338.097046,784.298584 
	C338.786224,783.947937 339.516632,783.969421 340.612061,784.009888 
	C341.265442,784.501770 341.553741,784.974609 341.968079,785.695312 
z"/>
<path fill="#0A0D0F" opacity="1.000000" stroke="none" 
	d="
M305.588013,784.048950 
	C305.003876,784.418030 304.022308,784.774414 302.876038,784.831299 
	C302.711365,784.531799 302.660187,784.159058 302.737915,783.986755 
	C303.607269,783.888367 304.398926,783.962341 305.588013,784.048950 
z"/>
<path fill="#0A0D0F" opacity="1.000000" stroke="none" 
	d="
M309.706238,782.130859 
	C309.504333,782.725830 308.982208,783.315308 308.121765,783.888062 
	C308.317657,783.289307 308.851868,782.707336 309.706238,782.130859 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M287.162598,830.610474 
	C287.039551,831.039978 286.833374,831.054138 286.317932,831.048828 
	C283.001709,824.702820 282.220276,818.194214 285.037689,811.504395 
	C286.126648,816.369446 286.583801,821.173035 287.040955,825.976624 
	C287.053772,827.382812 287.066620,828.789062 287.162598,830.610474 
z"/>
<path fill="#0A0D0F" opacity="1.000000" stroke="none" 
	d="
M287.323608,825.698975 
	C286.583801,821.173035 286.126648,816.369446 285.358582,811.290283 
	C285.046509,810.584167 285.045380,810.153625 285.433655,809.401611 
	C286.493073,808.976990 287.163147,808.873840 287.833191,808.770630 
	C287.757568,814.320862 287.681915,819.871094 287.323608,825.698975 
z"/>
<path fill="#3C3C3C" opacity="1.000000" stroke="none" 
	d="
M287.842316,808.434692 
	C287.163147,808.873840 286.493073,808.976990 285.484131,809.082520 
	C285.090057,807.998657 285.034882,806.912476 284.998596,805.416992 
	C285.212372,805.008179 285.407257,805.008667 286.116547,805.113647 
	C287.031250,805.500244 287.431580,805.782349 287.831940,806.064392 
	C287.838440,806.742493 287.844940,807.420654 287.842316,808.434692 
z"/>
<path fill="#0A0D0F" opacity="1.000000" stroke="none" 
	d="
M288.130737,805.858765 
	C287.431580,805.782349 287.031250,805.500244 286.408936,805.115845 
	C286.231995,804.324341 286.277039,803.635132 286.425842,802.617065 
	C287.772064,801.186890 289.014587,800.085510 290.567139,798.886230 
	C290.061310,801.076538 289.245422,803.364868 288.130737,805.858765 
z"/>
<path fill="#0A0D0F" opacity="1.000000" stroke="none" 
	d="
M296.741974,789.288757 
	C296.577545,790.108154 296.110992,791.009705 295.305450,791.946777 
	C295.457581,791.111877 295.948730,790.241333 296.741974,789.288757 
z"/>
<path fill="#0A0D0F" opacity="1.000000" stroke="none" 
	d="
M293.686768,793.055237 
	C293.549652,793.655579 293.091980,794.288940 292.312561,794.956665 
	C292.449280,794.356812 292.907776,793.722534 293.686768,793.055237 
z"/>
<path fill="#0A0D0F" opacity="1.000000" stroke="none" 
	d="
M353.930481,799.690247 
	C353.129761,799.124146 352.333191,798.234741 351.787048,797.165405 
	C352.669830,797.779236 353.302216,798.573120 353.930481,799.690247 
z"/>
<path fill="#081926" opacity="1.000000" stroke="none" 
	d="
M379.895264,847.596802 
	C379.057556,847.415955 378.075897,846.959229 377.115723,846.155579 
	C378.008606,846.312805 378.879974,846.816833 379.895264,847.596802 
z"/>
<path fill="#2C4761" opacity="1.000000" stroke="none" 
	d="
M350.409607,328.909760 
	C350.758545,329.077026 350.843842,329.223419 350.939819,329.622192 
	C350.523712,329.890991 350.096893,329.907410 349.349976,329.913269 
	C349.401917,329.564758 349.773926,329.226837 350.409607,328.909760 
z"/>
<path fill="#828C93" opacity="1.000000" stroke="none" 
	d="
M350.009094,339.898346 
	C349.876831,339.897583 349.679199,339.664398 349.611450,339.329407 
	C349.741302,339.227539 349.943726,339.665924 350.009094,339.898346 
z"/>
<path fill="#05223A" opacity="1.000000" stroke="none" 
	d="
M456.339325,832.854248 
	C453.406769,829.013550 456.489319,828.613159 459.640686,828.014893 
	C458.882324,829.562256 457.742706,831.110046 456.339325,832.854248 
z"/>
<path fill="#0A1E30" opacity="1.000000" stroke="none" 
	d="
M450.338928,838.936768 
	C448.462799,836.354736 449.942322,835.862244 452.666992,836.017212 
	C452.224030,836.946045 451.416565,837.866516 450.338928,838.936768 
z"/>
<path fill="#0A1E30" opacity="1.000000" stroke="none" 
	d="
M449.473206,594.755005 
	C450.807098,595.286804 452.646545,596.006226 452.821289,597.014221 
	C453.267120,599.586304 453.230865,602.325684 452.847443,604.918274 
	C452.685120,606.015930 451.168884,606.913391 450.268066,607.901855 
	C449.514221,606.887085 448.101990,605.855469 448.127411,604.860657 
	C448.210938,601.591248 448.815979,598.335205 449.473206,594.755005 
z"/>


        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}